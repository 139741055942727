import {
	Autocomplete,
	FormControl,
	InputLabel,
	MenuItem,
	Select,
	TextField,
} from "@mui/material"
import CircularProgress from "@mui/material/CircularProgress"
import FormHelperText from "@mui/material/FormHelperText"
import Grid2 from "@mui/material/Unstable_Grid2"
import React from "react"
import { Controller } from "react-hook-form"
// Date Imports
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs"
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider"
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker"
// Inputs con Grid
import { useMemo } from "react"
import dayjs from "dayjs"
/**
 * @deprecated
 * @param {*} param0
 * @returns
 */
export const InputSelectGrid = ({
	id = "",
	value = "",
	setValue = () => {},
	label = "",
	data = [],
	dataLabel = "label",
	dataID = "id",
	error = false,
	all = false,
	disabled = false,
	onChange = (event, obj) => {
		setValue(event.target.value)
	},
	propsG,
	propsF,
	propsI,
	...props
}) => {
	if (!Array.isArray(data)) {
		data = []
	}

	return (
		<Grid2 {...propsG}>
			<FormControl
				fullWidth
				{...propsF}
			>
				<InputLabel
					id={`label${id}`}
					{...propsI}
				>
					{label}
				</InputLabel>
				<Select
					labelId={`label${id}`}
					id={`select${label}`}
					value={value}
					label={label}
					disabled={disabled}
					onChange={onChange}
					{...props}
				>
					{all && (
						<MenuItem
							key={"default"}
							value={0}
						>
							{all}
						</MenuItem>
					)}
					{data.map((option, index) => (
						<MenuItem
							key={label + index}
							value={option[dataID]}
						>
							{option[dataLabel]}
						</MenuItem>
					))}
				</Select>
			</FormControl>
		</Grid2>
	)
}
/**
 * Componente Input Con Grid2
 *
 * @param {Object} props - Las propiedades del componente.
 * @param {object} [props.propsGrid] - props para Grid2 de material
 * @param {string} [props.id="inputGrid2"] - Identificador de Input Funcional Cuando se usa UseForm
 * @param {string} [props.label="inputGrid2"] - La etiqueta del campo de entrada.
 * @param {string} [props.defaultValue=""] - Valor Predetermina.
 * @param {string} [props.type="text"] - El tipo de entrada.
 * @param {Function} [props.setOption=() => {}] - UseState De cambio de valor.
 * @param {Function} [props.onChange=(event) => { setOption(event.target.value) }] - Operación a realizar cuando cambia el valor del campo de entrada.
 * @param {string} [props.error] - Mensaje de error asociado al campo de entrada.
 * @param {string} [props.autoComplete="off"] - Atributo de autocompleted para el campo de entrada.
 * @returns {JSX.Element} Componente Input.
 */
export function InputGrid2({
	id = "inputGrid2",
	label = "InputGrid2",
	defaultValue = "",
	type = "text",
	autoComplete = "off",
	setOption = () => {},
	onChange = (event) => {
		setOption(event.target.value)
	},
	propsGrid,
	...props
}) {
	return (
		<Grid2 {...propsGrid}>
			<TextField
				id={id}
				label={label}
				type={type}
				defaultValue={defaultValue}
				autoComplete={autoComplete}
				onChange={onChange}
				fullWidth
				{...props}
			/>
		</Grid2>
	)
}
/**
 * Componente InputSelectGrid2
 *
 * Este componente es un campo de selección desplegable que muestra los datos en un Grid.
 *
 * @component
 * @example
 * // Ejemplo de uso:
 * <InputSelectGrid2
 *   id="input-select"
 *   label="Seleccionar opción"
 *   data={[{ id: 1, label: "Opción 1" }, { id: 2, label: "Opción 2" }]}
 *   setValue={setValue}
 *   GridProps={{ xs: 12, sm: 6 }}
 *   FormProps={{ fullWidth: true }}
 * />
 *
 * @param {Object} props - Propiedades del componente Select.
 * @param {string} [props.id] - ID del campo de selección.
 * @param {string} [props.label] - Etiqueta del campo de selección.
 * @param {Array} props.data - Datos para las opciones del campo de selección.
 * @param {string} [props.dataID="id"] - Nombre de la propiedad que contiene el ID en los datos.
 * @param {string} [props.dataLabel="label"] - Nombre de la propiedad que contiene la etiqueta en los datos.
 * @param {boolean} [props.all=false] - Indica si se debe incluir una opción para seleccionar "todos".
 * @param {function} [props.setValue=()=>{}] - Función para establecer el valor seleccionado.
 * @param {function} [props.onChange=(event, obj)=>{}] - si es editada no se necesita setValue .
 * @param {Object} [props.GridProps] - Propiedades adicionales para el componente Grid.
 * @param {Object} [props.FormProps={ fullWidth: true }] - Propiedades para el componente Form.
 * @param {Object} [props.InputLabelProps] - Propiedades adicionales para el componente InputLabel.
 * @returns {ReactNode} - El componente InputSelectGrid2.
 */
export const InputSelectGrid2 = ({
	id = "",
	label = "",
	data = [],
	dataID = "id",
	dataLabel = "label",
	all = false,
	setValue = () => {},
	onChange = (event, obj) => {
		setValue(event.target.value)
	},
	GridProps,
	FormProps = { fullWidth: true },
	InputLabelProps,
	...props
}) => {
	if (!Array.isArray(data)) {
		data = []
	}

	return (
		<Grid2 {...GridProps}>
			<FormControl {...FormProps}>
				<InputLabel
					id={id}
					{...InputLabelProps}
				>
					{label}
				</InputLabel>
				<Select
					id={id}
					label={label}
					labelId={`label${id}`}
					onChange={onChange}
					{...props}
				>
					{all && (
						<MenuItem
							key={"default"}
							value={0}
						>
							{all}
						</MenuItem>
					)}
					{data.map((option, index) => (
						<MenuItem
							key={label + index}
							value={option[dataID]}
						>
							{option[dataLabel]}
						</MenuItem>
					))}
				</Select>
			</FormControl>
		</Grid2>
	)
}
/**
 * Componente de Autocompletar en un grid.
 * @param {object} props - Propiedades del componente.
 * @param {string} [props.label="AutoComplete"] - Etiqueta del campo de entrada.
 * @param {boolean} [props.values] - Indica el valor .
 * @param {boolean} [props.valuesIsId=true] - Indica si el valor se va a buscar por ID.

 * @param {string} [props.optionLabel="label"] - Nombre de la propiedad a mostrar como etiqueta en las opciones.
 * @param {function} [props.option=[]] - Función para establecer la opción seleccionada.
 * @param {function} [props.setOption=()=>{}] - Función para establecer la opción seleccionada.
 * @param {function} [props.onChange=(event, newValue)=>{}] - Función de devolución de llamada que se ejecuta cuando cambia la selección.

 * @param {Grid2} [props.gridProps] - Propiedades adicionales para el componente Grid.
 * @param {Object} [props.textFieldProps] - Propiedades adicionales para el componente TextField.
 * @returns {ReactNode} - El componente InputAutocompleteGrid2. 
 * @type {Autocomplete}
*/
export const InputAutocompleteGrid2 = ({
	label = "",
	optionLabel = "label",
	valuesIsId = true,
	setOption = () => {},
	onChange = (event, newValue) => {
		if (newValue === null) setOption(null)
		else setOption(newValue["id"])
	},
	gridProps,
	textFieldProps,
	...props
}) => {
	if (valuesIsId && !!props?.value)
		if (props?.options) {
			let value = props.options.filter((option) => option["id"] === props.value)
			if (value) props.value = value[0]
		}

	return (
		<Grid2 {...gridProps}>
			<Autocomplete
				disablePortal
				onChange={onChange}
				getOptionLabel={(option) => option[optionLabel]}
				{...props}
				renderInput={(params) => {
					return (
						<TextField
							label={label}
							{...textFieldProps}
							{...params}
						/>
					)
				}}
			/>
		</Grid2>
	)
}

export function AutocompleteMultipleGrid({
	label = "AutocompleteMultipleGrid",
	options = [],
	optionLabel = "label",
	defaultValue,
	propsG,
	setValue = () => {},
}) {
	return (
		<Grid2 {...propsG}>
			<Autocomplete
				multiple
				id="tags-outlined"
				options={options}
				getOptionLabel={(option) => option[optionLabel]}
				defaultValue={defaultValue}
				filterSelectedOptions
				onChange={(event, newValue) => {
					setValue(newValue)
				}}
				renderInput={(params) => (
					<TextField
						{...params}
						label={label}
						placeholder={label}
					/>
				)}
			/>
		</Grid2>
	)
}

// Inputs utilizando React UseForm
export const InputSelectFormGrid = ({
	id,
	defaultValue = "",
	label,
	data = [],
	dataID = "id",
	dataLabel = "label",
	error = {},
	rules = {
		required: { value: true, message: "Se necesita dato" },
	},
	control,
	propsG,
	onChange = () => {},
}) => {
	return (
		<Grid2 {...propsG}>
			<Controller
				name={id}
				control={control}
				defaultValue={defaultValue}
				rules={rules}
				render={({ field }) => (
					<React.Fragment>
						<FormControl
							fullWidth
							error={!!error[id]}
						>
							<InputLabel id={`${id}-label`}>{label}</InputLabel>
							<Select
								labelId={`${id}-label`}
								id={id}
								label={label}
								{...field}
								onChange={(e) => {
									field.onChange(e)
									onChange(e)
								}}
							>
								{data.map((option) => (
									<MenuItem
										key={option[dataID]}
										value={option[dataID]}
									>
										{option[dataLabel]}
									</MenuItem>
								))}
							</Select>
							<FormHelperText>{!!error[id] ? error[id]["message"] : ""}</FormHelperText>
						</FormControl>
					</React.Fragment>
				)}
			/>
		</Grid2>
	)
}
export const InputSelectArrayFormGrid = ({
	id,
	defaultValue = "",
	label,
	data = [],
	error = {},
	rules = {
		required: { value: true, message: "Se necesita dato" },
	},
	control,
	propsG,
	onChange = () => {},
}) => {
	return (
		<Grid2 {...propsG}>
			<Controller
				name={id}
				control={control}
				defaultValue={defaultValue}
				rules={rules}
				render={({ field }) => (
					<React.Fragment>
						<FormControl
							fullWidth
							error={!!error[id]}
						>
							<InputLabel id={`${id}-label`}>{label}</InputLabel>
							<Select
								labelId={`${id}-label`}
								id={id}
								label={label}
								{...field}
								onChange={(e) => {
									field.onChange(e)
									onChange(e)
								}}
							>
								{data.map((option) => (
									<MenuItem
										key={option}
										value={option}
									>
										{option}
									</MenuItem>
								))}
							</Select>
							<FormHelperText>{!!error[id] ? error[id]["message"] : ""}</FormHelperText>
						</FormControl>
					</React.Fragment>
				)}
			/>
		</Grid2>
	)
}

/**
 * Componente Input Con Grid2
 *
 * @param {Object} props - Las propiedades del componente.
 * @param {string} [props.id="inputGrid2"] - Identificador de Input Funcional Cuando se usa UseForm
 * @param {string} [props.label="inputGrid2"] - La etiqueta del campo de entrada.
 * @param {string} [props.defaultValue=""] - Valor Predetermina.
 * @param {string} [props.type="text"] - El tipo de entrada.
 * @param {number} props.InputProps - Modificar los accesorios que se aplican ejemplo inputProps: {min: min,max: max}
 * @param {number} [props.xs=6] - El tamaño del componente en dispositivos extra pequeños (xs).
 * @param {number} [props.md=4] - El tamaño del componente en dispositivos medianos (md).
 * @param {number} [props.xl=3] - El tamaño del componente en dispositivos extra grandes (xl).
 * @param {Function} [props.setOption=() => {}] - UseState De cambio de valor.
 * @param {Function} [props.operation=(event) => { setOption(event.target.value) }] - Operación a realizar cuando cambia el valor del campo de entrada.
 * @param {string} [props.error] - Mensaje de error asociado al campo de entrada.
 * @param {string} [props.autoComplete="off"] - Atributo de autocompleted para el campo de entrada.
 * @returns {JSX.Element} Componente Input.
 */
export function InputFormGrid2({
	id = "inputGrid2",
	label = "InputGrid2",
	autoComplete = "off",
	registerParams = { required: true },
	register = () => {},
	error = {},
	propsG,
	...props
}) {
	return (
		<Grid2 {...propsG}>
			<TextField
				id={id}
				label={label}
				autoComplete={autoComplete}
				fullWidth
				error={!!error[id]}
				helperText={!!error[id] ? error[id]["message"] : ""}
				{...register(id, registerParams)}
				{...props}
			/>
		</Grid2>
	)
}

export function InputAutocompleteAsync({
	label,
	loading,
	options,
	getOptionDisabled,
	optionsLabel = label,
	value = "",
	setValue = () => {},
	...props
}) {
	return (
		<Autocomplete
			id="asynchronous"
			isOptionEqualToValue={(option, valor) => {
				return option.id === valor.id
			}}
			value={value}
			getOptionLabel={(option) => option[optionsLabel]}
			options={options}
			loading={loading}
			onChange={(event, value) => {
				setValue(value)
			}}
			{...{ getOptionDisabled }}
			{...props}
			renderInput={(params) => (
				<TextField
					{...params}
					label={label}
					InputProps={{
						...params.InputProps,
						endAdornment: (
							<React.Fragment>
								{loading ? (
									<CircularProgress
										color="inherit"
										size={20}
									/>
								) : null}
								{params.InputProps.endAdornment}
							</React.Fragment>
						),
					}}
				/>
			)}
		/>
	)
}

/**
 * @type {Autocomplete}
 */

export const InputAutocomplete = ({
	label = "",
	optionValue = null,
	optionLabel = "label",
	valuesIsId = true,
	setOption = () => {},
	onChange = (event, newValue) => {
		if (newValue) setOption(newValue[optionValue] || newValue)
		else setOption(null)
	},
	gridProps,
	textFieldProps,
	options,
	value,
	...props
}) => {
	const selectedValues = useMemo(() => {
		if (value == null) return null

		const selected = options.filter((select) => {
			if (optionValue && value) return select[optionValue] === value
			return select === value
		})

		if (props?.multiple) return selected
		else return selected[0] ?? null
	}, [optionValue, options, props?.multiple, value])

	return (
		<Autocomplete
			id="InputAutocomplete"
			disablePortal
			value={selectedValues}
			onChange={onChange}
			options={options}
			getOptionLabel={(option) => option[optionLabel]}
			{...props}
			renderInput={(params) => {
				return (
					<TextField
						{...params}
						label={label}
					/>
				)
			}}
		/>
	)
}

/**
 * Input para fechas con formato específico.
 * @param { object} param - Props para el componente.
 * @property {dayjs} param.value - Valor de la fecha.
 * @param {() => void} [param.setValue=() => {}] - Función para actualizar el valor de la fecha.
 * @param {string} param.label - Etiqueta del campo de fecha.
 * @returns {JSX.Element} - Componente JSX para la entrada de fecha en un formulario.
 */
export const InputDateAndTime = ({ value, setValue = () => {}, ...props }) => {
	return (
		<LocalizationProvider
			dateAdapter={AdapterDayjs}
			timezone="America/Mexico_City"
		>
			<DateTimePicker
				renderInput={(props) => <TextField {...props} />}
				value={value}
				fullWidth
				onChange={(newValue) => setValue(newValue)}
				views={["day", "month", "year", "hours", "minutes"]}
				Format="DD/MM/YYYY hh:mm a"
				slotProps={{
					textField: {
						helperText: "DD/MM/YYYY hh:mm",
					},
				}}
				{...props}
			/>
		</LocalizationProvider>
	)
}
