import AnimationTable from "#common/AnimationTabla"
import ArrowBackIcon from "@mui/icons-material/ArrowBack"
import { Box, Button, Paper, Stack } from "@mui/material"
import { ChangeTitle } from "Components/menu/funciones"
import { useNavigate, useParams } from "react-router-dom"

const TablaDetalle = ({ title = "", nameParam = "", ruta = "", tabla, Detalle }) => {
	ChangeTitle(title)
	const params = useParams()
	const param = params[nameParam]
	const navigate = useNavigate()
	return (
		<Box
			display="flex"
			width="100%"
			flexDirection={"column"}
		>
			{/* <Paper
				sx={{
					display: "flex",
					flexDirection: "column",
					padding: 1,
					width: "100%",
				}}
			> */}
			<AnimationTable activar={!param}>{tabla}</AnimationTable>
			<AnimationTable activar={param}>
				<Stack
					spacing={0}
					padding={2}
				>
					<div>
						<Button
							variant="text"
							size="small"
							onClick={() => navigate(ruta)}
							startIcon={<ArrowBackIcon />}
						>
							Regresar
						</Button>
					</div>
					<Detalle {...{ [nameParam]: param }} />
				</Stack>
			</AnimationTable>
			{/* </Paper> */}
		</Box>
	)
}

export default TablaDetalle
