const TOAST_CONFIG={
    position:"bottom-right",
				autoClose:5000,
				hideProgressBar:false,
				newestOnTop:false,
				closeOnClick:true,
				rtl:false,
				pauseOnFocusLoss:false,
				draggable:true,
				pauseOnHover:true,
				theme:"dark",
}
export default TOAST_CONFIG