import { MasterDialog } from "#common/Dialog/Dialog"
import { useApiSend } from "#hooks/useApiSend"
import { usePerfilesPuestoReloadAll } from "#perfilesPuesto/redux/slice"
import { perfilesIdPut } from "#perfilesPuesto/Services/api"
import WorkOffIcon from "@mui/icons-material/WorkOff"
import { Tooltip, Typography } from "@mui/material"
import { IconButton } from "@mui/material/"
import Zoom from "@mui/material/Zoom"
import { Fragment, useState } from "react"
import WorkIcon from "@mui/icons-material/Work"
import useHasPermission from "#hooks/useHasPermission"
import PERMISOS from "#constants/perfiles"
import RemoveModeratorIcon from "@mui/icons-material/RemoveModerator"
import GppGoodIcon from "@mui/icons-material/GppGood"
export const ButtonDisable = ({ perfilID, habilitado }) => {
	const { hasPermissionV2 } = useHasPermission()
	const [open, setOpen] = useState(false)
	const [loading, setLoading] = useState(false)
	const [cancel, setCancel] = useState(() => {})
	const reload = usePerfilesPuestoReloadAll()
	const isRh = hasPermissionV2(PERMISOS.RH_ALL)
	const success = () => {
		reload()
		setOpen(false)
	}
	const end = () => {
		setLoading(false)
	}
	const { fetch } = useApiSend(perfilesIdPut, { success, end })

	const confirm = () => {
		setLoading(true)
		const fn = fetch(perfilID, { habilitado: !habilitado })
		setCancel(() => fn)
	}
	if (!isRh) return null
	return (
		<Fragment>
			<Tooltip
				key={"Deshabilitar"}
				title={habilitado ? "Deshabilitar" : "Habilitar"}
				TransitionComponent={Zoom}
			>
				<IconButton
					color={habilitado ? "error" : "success"}
					size="large"
					onClick={() => setOpen(true)}
				>
					{habilitado ? (
						<RemoveModeratorIcon fontSize="inherit" />
					) : (
						<GppGoodIcon fontSize="inherit" />
					)}
				</IconButton>
			</Tooltip>
			<MasterDialog
				{...{ open, setOpen }}
				maxWidth={"sm"}
				fullWidth={true}
				title={"Confirmación"}
				buttons={[
					{
						label: "Cancelar",
						onClick: () => {
							if (typeof myFunc === "function") cancel()
							setOpen(false)
						},
					},
					{ label: "Aceptar", onClick: () => confirm(), disabled: loading },
				]}
			>
				<Typography
					sx={{ whiteSpace: "pre-line" }}
					align="center"
				>
					¿Deseas {habilitado ? "Deshabilitar" : "Habilitar"} este perfil?
					<br />
					{habilitado && "Ya no se podrán generar vacantes basados en este perfil."}
				</Typography>
			</MasterDialog>
		</Fragment>
	)
}
