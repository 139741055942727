import Grid from "@mui/material/Unstable_Grid2/Grid2"
import { useEffect, useMemo, useState } from "react"
import { useSelector } from "react-redux"
import { getApiCursos } from "../../../Services/Api_cursos"
import { SkeletonSquareGrid } from "../../Common/Skeleton"
import { TextGrid } from "../../Common/Texto"
import TarjetaCurso from "../TarjetaCurso"
import { InputSelect } from "#common/componentes/Inputs"
import InputSelectAreas from "#common/input/InputSelectAreas"
import { UseGetAreas } from "Hooks/areas/getAreas"

export default function VistaPlanAnual() {
	const [areasOption, setAreasOption] = useState(0)
	const [yearOption, setYearOption] = useState(new Date().getFullYear())
	const { areas, loading } = UseGetAreas(true, { manager: true })
	const selectYears = useMemo(() => {
		const years = []
		for (let index = 2023; index <= new Date().getFullYear() + 1; index++) {
			years.push({ año: index })
		}
		return years
	}, [])

	useEffect(() => {
		if (areas.length) setAreasOption(areas[0].id)
	}, [areas])

	if (loading) return <SkeletonSquareGrid height={"80vh"} />

	if (!areas.length)
		return (
			<Grid
				container
				spacing={2}
			>
				<TextGrid
					allSize={12}
					label="No Tienes departamentos a tu cargo"
				/>
			</Grid>
		)

	return (
		<Grid
			container
			spacing={2}
		>
			<Grid xs={12}>
				<div>
					<Grid
						container
						spacing={1}
						justifyContent={"center"}
					>
						<Grid
							xs={12}
							md={3}
						>
							<InputSelectAreas
								value={areasOption}
								setValue={setAreasOption}
								data={areas}
								disabled={areas.length <= 1}
							/>
						</Grid>
						<Grid
							xs={12}
							md={2}
						>
							<InputSelect
								data={selectYears}
								dataLabel="año"
								dataID="año"
								sizeAll={4}
								label="Año"
								option={yearOption}
								setOption={setYearOption}
							/>
						</Grid>
					</Grid>
				</div>
			</Grid>

			<CourseCard
				area={areasOption}
				year={yearOption}
			/>
		</Grid>
	)
}

const CourseCard = ({ area, year }) => {
	const token = useSelector((state) => state.user.token)
	const [cursos, setCursos] = useState([])
	const [loading, setLoading] = useState(true)
	useEffect(() => {
		setLoading(true)
		setCursos([])
		getApiCursos(
			"planAnual",
			token,
			(data) => {
				setCursos(data)
				setLoading(false)
			},
			{
				area,
				year,
			},
		)
	}, [token, area, year])

	if (loading)
		return (
			<SkeletonSquareGrid
				quantity={12}
				propsG={{ xs: 12, md: 4 }}
			/>
		)
	if (cursos?.cursos_departamento.length === 0)
		return <TextGrid label="No Hay Plan Anual" />

	return cursos["cursos_departamento"].map((curso, index) => {
		return (
			<Grid
				xs={12}
				md={4}
				key={`cursosA${curso?.id}`}
			>
				<TarjetaCurso
					db_cursoArea={curso}
					db_cursos={cursos["cursos"][index]}
					filtro={"planAnual"}
				/>
			</Grid>
		)
	})
}
