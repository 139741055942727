import Box from "@mui/material/Box"
import Grid2 from "@mui/material/Unstable_Grid2"
import { useEffect, useState } from "react"
import FormDetails from "./components/FormDetails"
import { FormularioSolicitud } from "./components/FormularioSolicitud"
import { toast } from "react-toastify"
import {
	MINIMUM_PAYMENT_MX,
	MINIMUM_PAYMENT_GT,
} from "Components/promotions/utils/constants"

export function FormularioNewVacante({
	data,
	setData = () => {},
	promotion = false,
	list = {
		sucursales: [],
		colaboradores: [],
		perfiles: [],
	},
}) {
	const [vacante, setVacante] = useState({ bono_trimestral: 0, garantia: 0 })
	const [vacante_details, setVacante_details] = useState({})

	useEffect(() => {
		if (promotion)
			setVacante({
				jefe_directo_id: data.authorization,
				perfil_puesto_id: data.perfil_puesto_anterior,
				sucursal_id: data.sucursal_id,
				colaborador_id: data.colaborador,
				tipo_vacante: "Promoción",
				bono_trimestral: 0,
				garantia: 0,
			})
	}, [
		data?.authorization,
		data?.colaborador,
		data?.perfil_puesto_anterior,
		data?.sucursal_id,
		promotion,
	])

	useEffect(() => {
		setData((data) => ({ ...data, vacante, vacante_details }))
	}, [setData, vacante, vacante_details])

	return (
		<Box>
			<Grid2
				container
				spacing={2}
			>
				<Grid2 xs={12}>
					<FormularioSolicitud {...{ vacante, setVacante, promotion, list }} />
				</Grid2>
				<Grid2 xs={12}>
					<FormDetails
						perfilId={vacante.perfil_puesto_id}
						{...{ vacante_details, setVacante_details }}
					/>
				</Grid2>
			</Grid2>
		</Box>
	)
}

export function validation(data) {
	const vacante = data?.vacante || {}
	const fields = [
		{ field: "sucursal_id", name: "sucursal" },
		{ field: "perfil_puesto_id", name: "perfil puesto" },
		{ field: "jefe_directo_id", name: "jefe directo" },
		{ field: "tipo_vacante", name: "tipo de vacante" },
		{ field: "sueldo_mensual", name: "sueldo mensual" },
		{ field: "bono_trimestral", name: "bono trimestral" },
		{ field: "garantia", name: "garantía" },
		{ field: "duracion", name: "duración" },
	]

	if (["Reemplazo", "Promoción"].includes(vacante?.tipo_vacante)) {
		fields.splice(4, 0, { field: "colaborador_id", name: "¿Por quién?" })
	}

	for (const index in fields) {
		const field = fields[index].field
		const name = fields[index].name
		const value = vacante[field]
		if (value === undefined || value === null || value === "" || value < 0) {
			toast.error(`se necesita el campo ${name}.`)
			return false
		}
	}
	if (vacante?.sucursal_id === 35 || vacante?.sucursal_id === 69) {
		if (vacante?.sueldo_mensual < MINIMUM_PAYMENT_GT) {
			toast.error(`El sueldo mínimo es de Q${MINIMUM_PAYMENT_GT}`)
			return false
		}
	} else if (vacante?.sueldo_mensual < MINIMUM_PAYMENT_MX) {
		toast.error(`El sueldo mínimo es de $${MINIMUM_PAYMENT_MX}`)
		return false
	}

	const vacante_details = data?.vacante_details || {}
	const listFields = [
		{ list: "list_educacion", name: "Educación y Formación" },
		{ list: "list_experiencia", name: "Experiencia" },
		{ list: "list_adicionales", name: "Adicionales" },
		{ list: "list_idiomas", name: "Idiomas" },
		{ list: "list_software", name: "Software" },
		{ list: "list_training", name: "Capacitación" },
		{ list: "list_responsabilidades", name: "Responsabilidades" },
	]
	for (const index in listFields) {
		const field = listFields[index].list
		const name = listFields[index].name
		const value = vacante_details[field]
		if (Array.isArray(value) && value.length === 0) {
			toast.error(`se necesita el campo ${name}.`)
			return false
		}
	}

	return true
}
