import { initAxiosRH, loadAbort } from "#api/axios.service"
const axios=initAxiosRH()
const DOCUMENTS="documents"

export const documentsGet = (params) => {
    const controller= loadAbort()
    const call= axios.get(DOCUMENTS,{params,signal:controller.signal})
    return {call,controller}
}
export const documentsPost = (data) => {
    const controller= loadAbort()
    const call= axios.post(DOCUMENTS,data,{signal:controller.signal})
    return {call,controller}
}

export const documentGet = (documentsId) => {
    const controller= loadAbort()
    const call= axios.get(`${DOCUMENTS}/${documentsId}`,{signal:controller.signal})
    return {call,controller}
}
export const documentPut = (documentsId,data) => {
    const controller= loadAbort()
    const call= axios.put(`${DOCUMENTS}/${documentsId}`,data,{signal:controller.signal})
    return {call,controller}
}
export const documentDelete = (documentsId) => {
    const controller= loadAbort()
    const call= axios.delete(`${DOCUMENTS}/${documentsId}`,{signal:controller.signal})
    return {call,controller}
}
