import { DataItem } from "#common/text/DataItem"
import { Box, Paper, Skeleton, Stack, Typography } from "@mui/material"
import Grid2 from "@mui/material/Unstable_Grid2"
import { useEffect, useMemo } from "react"
import { usePromotionId } from "./hooks/getPromotions"
import PromotionalChanges from "./promotionalChanges/PromotionalChanges"
import { PromotionButtons } from "./PromotionButtons"
import { usePromotionsContext } from "./PromotionContext"
import { STATUS } from "./utils/constants"

export default function PromotionDetails({ promotionId }) {
	const { setPromotion, actualizar } = usePromotionsContext()
	const { promotion = {}, loading } = usePromotionId(promotionId, actualizar)

	useEffect(() => {
		setPromotion(promotion)
	}, [promotion, setPromotion])

	const { status_id } = promotion || {}

	const itemsHead = useMemo(() => {
		const style = { variant: "h6" }
		return [
			{
				label: "Solicitante",
				value: "requestor",
				xs: 12,
				md: 6,
				lg: 4,
				...style,
			},
			{
				label: "Status",
				value: "status",
				xs: 12,
				md: 6,
				lg: 2,
				...style,
			},
			{
				label: "Fecha Solicitud",
				value: "request_date",
				xs: 12,
				md: 6,
				lg: 3,
				...style,
			},
			{
				label: "Fecha de aplicación",
				value: "apply_date",
				xs: 12,
				md: 6,
				lg: 3,
				...style,
			},
		]
	}, [])

	const headPromotion = useMemo(() => {
		return itemsHead.map((item) => {
			return (
				<Grid2
					{...item}
					key={item.label}
				>
					<DataItem
						{...item}
						value={promotion[item.value]}
					/>
				</Grid2>
			)
		})
	}, [itemsHead, promotion])

	if (loading)
		return (
			<Skeleton
				variant="rectangular"
				width={"100%"}
				height={"80vh"}
			/>
		)
	return (
		<Box
			component={Paper}
			elevation={6}
			width={"100%"}
		>
			<Stack
				padding={3}
				paddingTop={1}
			>
				<Stack
					direction={"row"}
					justifyContent={"space-between"}
				>
					<Typography
						variant="h5"
						sx={{ fontWeight: "bold" }}
					>
						Promoción #{promotion.id}
					</Typography>
					<PromotionButtons
						promotion_id={promotion.id || 0}
						statusId={status_id}
					/>
				</Stack>
				<Grid2
					container
					spacing={3}
					justifyContent={"space-between"}
					paddingTop={1}
				>
					{headPromotion}

					<Grid2 xs={12}>
						<PromotionalChanges
							promotion_id={promotion.id || 0}
							disable={status_id !== STATUS.Aprobada_RH}
						/>
					</Grid2>
				</Grid2>
			</Stack>
		</Box>
	)
}
