import Grid from "@mui/material/Unstable_Grid2"
import { UseGetAreas } from "Hooks/areas/getAreas"
import React, { useEffect, useState } from "react"
import { useSelector } from "react-redux" //TOKEN
import { getApiCursos } from "../../Services/Api_cursos"
import { AutocompleteMultipleGrid, InputSelectGrid2 } from "../Common/Inputs2"
import { SkeletonSquareGrid } from "../Common/Skeleton"
import CursosTablaColores from "./componentes/CursosTablaColores"
import Graph from "./componentes/Estadisticas/Graficas"
import TableStatistics from "./componentes/Estadisticas/TablaEstadisticas"

export default function Statistics() {
	const token = useSelector((state) => state.user.token)
	const [db_sucursal, set_dbSucursal] = useState([])
	const [db_cursosColaboradores, set_DBCursosColaboradores] = useState([])
	const { areas } = UseGetAreas(true)
	const [datosG, set_DatosG] = useState([]) //Datos gráfica

	const [filtro, setFiltro] = useState({ sucursal: [], departamento: [] }) //filtro sucursal
	const [skeleton, setSkeleton] = useState(false) //filtro sucursal
	const [yearOption, setYearOption] = useState(new Date().getFullYear()) //filtro sucursal

	useEffect(() => {
		getApiCursos("sucursal", token, set_dbSucursal).then((response) => {
			set_dbSucursal(response?.data?.data ?? [])
		})
	}, [token])

	function arrayFilter(datos) {
		return datos.map((dato) => dato["id"])
	}
	useEffect(() => {
		setSkeleton(false)
		let sucursales = arrayFilter(filtro["sucursal"])
		let departamento = arrayFilter(filtro["departamento"])
		getApiCursos("statistics", token, set_DatosG, {
			departamento: departamento,
			sucursal: sucursales,
			year: yearOption,
			status: "",
		})
		getApiCursos(
			"statistics",
			token,
			set_DBCursosColaboradores,
			{
				departamento: departamento,
				sucursal: sucursales,
				year: yearOption,
			},
			setSkeleton,
		)
	}, [token, filtro, yearOption])

	const yearSelect = Array.from(
		{
			length: new Date().getFullYear() + 1 - 2023 + 1,
		},
		(_, index) => ({
			año: 2023 + index,
			label: 2023 + index,
		}),
	)

	return (
		<div>
			<Grid
				container
				spacing={2}
				justifyContent={"center"}
			>
				<CursosTablaColores all />
				<InputSelectGrid2
					label="Año"
					value={yearOption}
					data={yearSelect}
					dataID="label"
					GridProps={{ xs: 2 }}
					setValue={setYearOption}
				/>

				<AutocompleteMultipleGrid
					label="Departamento"
					options={areas}
					optionLabel="description"
					propsG={{ xs: 5 }}
					defaultValue={filtro["departamento"]}
					setValue={(d) => {
						setFiltro({ ...filtro, departamento: d })
					}}
				/>
				<AutocompleteMultipleGrid
					label="Sucursal"
					options={db_sucursal}
					optionLabel="nombre"
					propsG={{ xs: 5 }}
					defaultValue={filtro["sucursal"]}
					setValue={(d) => {
						setFiltro({ ...filtro, sucursal: d })
					}}
				/>

				{skeleton ? (
					<React.Fragment>
						<TableStatistics
							db_data={db_cursosColaboradores}
							departamento={arrayFilter(filtro["departamento"])}
							sucursal={arrayFilter(filtro["sucursal"])}
							year={yearOption}
							size={12}
						/>
						<Graph
							size={6}
							datos={datosG}
							XD={"status"}
							YD={(d) => d.cursos}
							labelX={"status general"}
							labelY={`Cursos ${datosG.length ? datosG[0].total : 10}`}
							labelBarra={({ datum }) => {
								return `${datum._y}`
							}}
						/>
						<Graph
							size={6}
							datos={db_cursosColaboradores}
							XD={"sucursal"}
							YD={(d) => {
								return d.porcentaje
							}}
							labelX={"Avance  porcentual "}
							labelY={"Porcentaje "}
							labelBarra={({ datum }) => {
								return `${datum._y.toFixed(2)}%`
							}}
						/>
					</React.Fragment>
				) : (
					<SkeletonSquareGrid
						propsG={{ xs: 12, md: 12 }}
						height={"55vh"}
						propsGC={{ container: false, xs: 12 }}
					/>
				)}
			</Grid>
		</div>
	)
}
