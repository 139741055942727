import { configureStore } from '@reduxjs/toolkit'
import userReducer from './userSlice'
import pageReducer from './pageSlice'
import colabReducer from './colabSlice'
import notificationSlice from './notificationSlice'
import actualizarComponete from './actualizar'
import ColaboradorSlice from 'Components/Colaboradores/redux/slice'
import PerfilesPuestoSlice from "#perfilesPuesto/redux/slice"
import VacanteSlice from '#vacantes/redux/slice'


export default configureStore({
  reducer: {
    user: userReducer,
    page: pageReducer,
    colaborador: colabReducer,
    notification: notificationSlice,
    actualizar: actualizarComponete,
    colaboradorN:ColaboradorSlice,
    perfilesPuesto:PerfilesPuestoSlice,
    vacante:VacanteSlice.reducer
    
  },
})