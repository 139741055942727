import { useEffect, useState } from "react"
//API
//componentes
//material ui
import { api_rh_get, apiGetRH } from "#api/API_RH"

export function UseColaboradoresPromotions(id, activar, all) {
	const [colaboradores, setColaboradores] = useState([])
	const [load, setLoad] = useState(false)

	useEffect(() => {
		const success = (response) => {
			if (all) setColaboradores(response?.colaboradores)
			else setColaboradores(response)
			setLoad(false)
		}

		const controller = new AbortController()
		setLoad(true)

		if (!activar) return
		if (!all) {
			api_rh_get(`colaboradores/${id}/my_team`, {
				success,
				params: { small_data: true, extra: ["promotion_in_progress"] },

				signal: controller.signal,
			})
		} else
			api_rh_get(`colaboradores`, {
				success,
				params: { small_data: true, fields: "promotion_in_progress" },
				signal: controller.signal,
			})

		return () => {
			controller.abort()
		}
	}, [activar, all, id])
	return { colaboradores, load }
}
export function UseColaborador(id, params) {
	const [colaboradores, setColaboradores] = useState([])
	const [loading, setLoading] = useState(false)
	const success = (response) => {
		setColaboradores(response)
		setLoading(false)
	}
	useEffect(() => {
		const controller = new AbortController()
		setLoading(true)
		setColaboradores([])
		api_rh_get(`colaboradores/${id}`, {
			success,
			params: { small_data: true, ...params },
			signal: controller.signal,
		})

		return () => {
			controller.abort()
		}
	}, [id, params])
	return { colaboradores, loading }
}

export function DataPerfiles(id, autoFetch = false) {
	const [perfiles, setPerfiles] = useState([])
	const [loadPerfiles, setLoadPerfiles] = useState(false)

	useEffect(() => {
		const controller = new AbortController()
		setLoadPerfiles(true)
		const success = (response) => {
			setPerfiles(response)
			setLoadPerfiles(false)
		}
		if (autoFetch && id)
			apiGetRH(`perfiles_puesto/${id}/reporta_a`, {
				signal: controller.signal,
				success,
				failMessage: "error con el perfil de puesto",
			})

		return () => {
			controller.abort()
		}
	}, [autoFetch, id])
	return { perfiles, loadPerfiles }
}
