import { initialGestorRH } from "#api/API_RH"
import { MODULES } from "#constants/modules"
import { googleLogout } from "@react-oauth/google"
import { createSlice } from "@reduxjs/toolkit"
import { useCallback, useMemo } from "react"
import { useDispatch } from "react-redux"
import { useSelector } from "react-redux"

function getData() {
	const data = localStorage.getItem("userData")
	if (data == null) return { nombre: "", token: null, profiles: [] }
	return JSON.parse(data)
}

const initialState = {
	nombre: "",
	token:null,
	colaborador:null,	
	sociedadId:null,
	sociedad:null,
	profiles:[],
	moduleAccess:[]
}
export const userSlice = createSlice({
	name: "user",
	initialState:getData(),
	reducers: {
		login: (state, action) => {
			let session = action.payload

			state.nombre = session.colaborador.nombre
			state.token = session.token
			state.profiles = session.perfiles
			state.colaborador = session.colaborador
			state.sociedadId = session.sociedad.id
			state.sociedad = session.sociedad
			state.moduleAccess = session.modulos
			
			localStorage.setItem("userData", JSON.stringify(state))
			localStorage.setItem("token", session.token)
			localStorage.setItem("moneda", session.colaborador.moneda)
			localStorage.setItem("sociedad_id", session?.colaborador?.sociedad_id)
			localStorage.setItem("version", process.env.REACT_APP_VERSION)
			const currentDate = new Date()
			localStorage.setItem("fechaLogin", currentDate.toString())
			initialGestorRH()
			window.location.reload(true)
		},
		changeProfiles: (state, action) => {
			let data = action.payload
			state.profiles = data
		},
		logout: (state) => {
			state = initialState

			localStorage.removeItem("userData")
			localStorage.removeItem("fechaLogin")
			localStorage.removeItem("token")
			localStorage.removeItem("sociedad_id")
		},
	},
})

export const { login, logout } = userSlice.actions

export default userSlice.reducer

/**
 * @typedef {Object} ReduxUser
 * @property {string} nombre - Nombre del usuario.
 * @property {string} token - Token de acceso.
 * @property {string[]} profiles - Perfiles del usuario.
 * @property {string[]} moduleAccess - Acceso a módulos del usuario.
 */

/**
 * Obtiene el estado del usuario desde Redux.
 *
 * @returns {ReduxUser} Estado del usuario en Redux.
 */
export const useReduxUser = () => {
	return useSelector((state) => state.user)
}

export const useHasModuleAccess = (module) => {
	const modules = useSelector((state) => state.user.moduleAccess)
	return modules.includes(MODULES.ALL) || modules.includes(module)
}
export const useLogout = () => {
	const dispatch = useDispatch();
	const logoutFn=useCallback(()=>{
	dispatch(logout())
	googleLogout();
	window.location.reload(true);
},[dispatch])
	return {logoutFn}
}
