import { COLOR_PRIMARY } from "#constants/Styles"
import SearchOffIcon from "@mui/icons-material/SearchOff"
import { Stack, Typography } from "@mui/material/"

const SearchError = ({ error, alternative = "" }) => {
	let text = error?.response?.data?.message
	if (!text) text = `Error: ${alternative}`
	return (
		<Stack
			justifyContent="center"
			alignItems="center" // Centra horizontalmente
			// height="100vh" // Ocupar toda la altura de la pantalla
		>
			<Typography
				textAlign="center"
				sx={{ fontSize: 24, fontWeight: "bold", color: COLOR_PRIMARY }}
				mb={2}
			>
				{text}
			</Typography>
			<SearchOffIcon sx={{ fontSize: 100, color: COLOR_PRIMARY }} />
		</Stack>
	)
}

export default SearchError
