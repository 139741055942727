import { useCallback, useEffect, useMemo, useState } from "react"
// common
import CircularSkeleton from "#common/skeleton/CircularSkeleton"
//componentes
import { UseBajasContabilidad } from "../../UseBajas"
import { UseDialogBajaContext } from "./DialogBajaContext"
// material
// import { bajasContabilidadPut } from "#api/Api/baja"
import { MasterDialog } from "#common/Dialog/Dialog"
import { formatMoney } from "#common/Funciones"
import InputNumber from "#common/input/InputNumber"
import InputText from "#common/input/InputText"
import { TextCampoValor } from "#common/text/CampoValor"
import PERMISOS from "#constants/perfiles"
import useHasPermission from "#hooks/useHasPermission"
import { Button, InputAdornment, Typography } from "@mui/material"
import Box from "@mui/material/Box"
import Stack from "@mui/material/Stack"
import Grid2 from "@mui/material/Unstable_Grid2/Grid2"
import { toast } from "react-toastify"
import { useApiSend } from "#hooks/useApiSend"
import { bajaContabilidadPut } from "Components/bajas/services/api"
import ButtonIcon from "#common/buttons/ButtonIcon"
import DeleteIcon from "@mui/icons-material/Delete"

const GRID_CONTAINER = { container: true, spacing: 3, width: "100%" }
const GRID = { xs: 12, md: 4, xl: 3 }

export default function ContabilidadBajas({ hidden = false }) {
	const { hasPermissionV2 } = useHasPermission()
	const { reloadContabilidad, startReloadContabilidad } = UseDialogBajaContext()

	const { debts, loading } = UseBajasContabilidad(hidden, reloadContabilidad)
	const [moreAdeudos, setMoreAdeudos] = useState({})
	const isRH = hasPermissionV2(PERMISOS.RH_ALL)
	const isJefeNominas = hasPermissionV2(PERMISOS.JEFE_NOMINAS)
	const {
		colaborador = {},
		adeudos = {},
		adeudos_finales = false,
		adeudos_exists,
	} = debts
	if (loading) return <CircularSkeleton />

	return (
		<Box
			sx={{
				alignItems: "center",
			}}
		>
			<NotTools herramientas={debts} />
			<Stack spacing={3}>
				<DataColaborador colaborador={colaborador} />

				<Adeudos
					adeudos={adeudos_finales || { ...adeudos, ...moreAdeudos }}
					disabled={!!adeudos_finales}
					adeudos_exists={adeudos_exists}
				/>
				{!adeudos_exists && (
					<Typography textAlign={"center"}>
						Se debe completar los adeudos para continuar
					</Typography>
				)}

				<AddItemsContabilidad {...{ adeudos_exists, adeudos_finales }} />
			</Stack>
		</Box>
	)
}

const NotTools = ({ herramientas }) => {
	if (herramientas.length !== 0) return null

	return <Typography textAlign="center">No hay adeudos para esta solicitud</Typography>
}

const DataColaborador = ({ colaborador }) => {
	const fields = [
		{ label: "Departamento", name: "centro_costos" },
		{ label: "Sucursal", name: "sucursal" },
		{ label: "Fecha Alta", name: "fecha_alta" },
		{ label: "Fecha Baja", name: "fecha_baja" },
	]

	const data = fields.map((item) => {
		return (
			<Grid2
				key={item.label}
				{...GRID}
			>
				<TextCampoValor
					field={item.label}
					value={colaborador[item.name]}
				/>
			</Grid2>
		)
	})

	return (
		<Box sx={{ width: "100%" }}>
			<Grid2 {...GRID_CONTAINER}>{data}</Grid2>
		</Box>
	)
}
const Adeudos = ({ adeudos, disabled, adeudos_exists }) => {
	const { baja, setButtonsDialog, startReloadContabilidad, setDisabledContabilidad } =
		UseDialogBajaContext()
	const { hasPermissionV2 } = useHasPermission()
	const [open, setOpen] = useState(false)
	const [adeudosItems, setAdeudosItems] = useState(adeudos)
	const isRH = hasPermissionV2(PERMISOS.RH_ALL)

	const editItem = (value, item) =>
		setAdeudosItems((items) => ({ ...items, [item]: value }))

	useEffect(() => {
		setAdeudosItems(adeudos)
		//eslint-disable-next-line react-hooks/exhaustive-deps
	}, [JSON.stringify(adeudos)])

	useEffect(() => {
		if (isRH && !disabled && adeudos_exists)
			setButtonsDialog([
				{
					label: "Confirmar",
					onClick: () => setOpen(true),
					disabled: open || !isRH,
				},
			])
	}, [adeudos.adeudos_exists, adeudos_exists, disabled, isRH, open, setButtonsDialog])

	const success = () => {
		startReloadContabilidad()
	}
	const end = () => {
		setDisabledContabilidad(false)
	}
	const { fetch } = useApiSend(bajaContabilidadPut, { success, end })
	const handleDelete = useCallback(
		(name) => {
			setDisabledContabilidad(true)
			fetch(baja.id, { delete: true, name })
		},
		[baja.id, fetch, setDisabledContabilidad],
	)
	const items = useMemo(() => {
		return disabled ? (
			<AdeudosItemsText adeudos={adeudosItems} />
		) : (
			<AdeudosItemsEdit
				adeudos={adeudosItems}
				editItem={editItem}
				fetch={handleDelete}
			/>
		)
	}, [adeudosItems, disabled, handleDelete])

	return (
		<Box>
			<Grid2 {...GRID_CONTAINER}>{items}</Grid2>
			<DialogConfirm
				adeudos={adeudosItems}
				{...{ open, setOpen }}
			/>
		</Box>
	)
}
/**
 * Componente para editar ítems de adeudos.
 *
 * @param {Object} props - Propiedades del componente.
 * @param {Array<{ value: number, delete: boolean }>} props.adeudos - Lista de adeudos con valores y estado de eliminación.
 * @param {Function} props.editItem - Función para editar un ítem de adeudo.
 * @returns {JSX.Element} Elemento JSX del componente.
 */
const AdeudosItemsEdit = ({ adeudos, editItem, fetch }) => {
	const { hasPermissionV2 } = useHasPermission()
	const { disabledContabilidad } = UseDialogBajaContext()
	const isRH = hasPermissionV2(PERMISOS.RH_ALL)
	const keys = Object.keys(adeudos)
	const order = keys.sort((a, b) => adeudos[a].delete - adeudos[b].delete)
	return order.map((key) => {
		return (
			<Grid2
				key={key}
				{...GRID}
			>
				<Stack direction="row">
					<InputNumber
						fullWidth
						label={key}
						value={adeudos[key]?.value}
						disabled={!isRH || disabledContabilidad}
						setValue={(value) => editItem(value, key)}
						startAdornment={
							adeudos[key]?.value || adeudos[key]?.value === 0 ? (
								<InputAdornment position="start">$</InputAdornment>
							) : null
						}
					/>
					<ButtonIcon
						title="Eliminar"
						icon={<DeleteIcon />}
						hidden={!adeudos[key]?.delete || !isRH}
						onClick={() => fetch(key)}
						disabled={disabledContabilidad}
					/>
				</Stack>
			</Grid2>
		)
	})
}
const AdeudosItemsText = ({ adeudos }) => {
	return Object.keys(adeudos).map((item, index) => (
		<Grid2
			key={item + index}
			{...GRID}
		>
			<TextCampoValor
				field={item}
				value={formatMoney(adeudos[item])}
			/>
		</Grid2>
	))
}

const AddItemsContabilidad = ({ adeudos_finales, adeudos_exists }) => {
	const { hasPermissionV2 } = useHasPermission()
	const { baja, disabledContabilidad, setDisabledContabilidad } = UseDialogBajaContext()
	const canSee = hasPermissionV2(PERMISOS.RH_ALL, PERMISOS.JEFE_NOMINAS)
	const { startReloadContabilidad } = UseDialogBajaContext()
	const [nameItem, setNameItem] = useState("")
	const [valueItem, setValueItem] = useState("")
	const success = () => {
		startReloadContabilidad()
		setNameItem("")
		setValueItem("")
	}
	const end = () => {
		setDisabledContabilidad(false)
	}
	const { fetch } = useApiSend(bajaContabilidadPut, { success, end })

	const handleAddItem = useCallback(() => {
		if (!nameItem || !valueItem) {
			toast.error("Debe ingresar nombre y cantidad adeudos", {
				toastId: "errorAddItem",
			})
			return
		}

		setDisabledContabilidad(true)
		fetch(baja.id, {
			add: true,
			name: nameItem,
			value: valueItem,
		})
	}, [nameItem, valueItem, setDisabledContabilidad, fetch, baja.id])

	if (!canSee || adeudos_finales || !adeudos_exists) return null

	return (
		<Box>
			<Stack
				width={"100%"}
				direction={"row"}
				spacing={3}
				alignItems={"flex-start"}
			>
				<InputText
					label="Nombre Adeudo"
					value={nameItem}
					disabled={disabledContabilidad}
					setValue={(value) => setNameItem(capitalizeEachWord(value))}
				/>
				<InputNumber
					disabled={disabledContabilidad}
					label="Cantidad Adeudos"
					value={valueItem}
					setValue={setValueItem}
				/>
				<Button
					disabled={disabledContabilidad}
					onClick={handleAddItem}
					variant="contained"
				>
					Agregar
				</Button>
			</Stack>
		</Box>
	)
}
function capitalizeEachWord(text) {
	if (!text) return "" // Verifica si el texto está vacío o es undefined
	return text
		.split(" ") // Divide el texto en un array de palabras
		.map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()) // Capitaliza la primera letra de cada palabra
		.join(" ") // Une las palabras nuevamente en una cadena
}

const DialogConfirm = ({ adeudos, open, setOpen }) => {
	const { baja, setButtonsDialog, reload } = UseDialogBajaContext()
	const [disabledButton, setDisabledButton] = useState(false)
	const { startReloadContabilidad } = UseDialogBajaContext()

	const success = useMemo(
		() => () => {
			setButtonsDialog(null)
			reload()
			startReloadContabilidad()
		},
		[reload, setButtonsDialog, startReloadContabilidad],
	)
	const end = () => setDisabledButton(false)
	const { fetch } = useApiSend(bajaContabilidadPut, { success, end })
	const sendData = useCallback(() => {
		setDisabledButton(true)
		let items = {}
		for (const [key, value] of Object.entries(adeudos)) {
			items[key] = value?.value
		}
		fetch(baja.id, items)
	}, [adeudos, baja.id, fetch])

	const items = useMemo(() => {
		let items = []
		for (const [key, value] of Object.entries(adeudos)) {
			items.push(
				<TextCampoValor
					field={key}
					value={formatMoney(value?.value)}
				/>,
			)
		}
		return items
	}, [adeudos])

	return (
		<MasterDialog
			{...{
				title: "Confirmación ",
				open,
				setOpen,
				buttons: [{ label: "Enviar", onClick: sendData, disabled: disabledButton }],
			}}
		>
			<Stack>{items}</Stack>
		</MasterDialog>
	)
}
