import PERMISOS from "#constants/perfiles"
import { useDocumentsContext } from "#documents-society/hooks/context"
import { documentsGet } from "#documents-society/services/Api"
import { useApi } from "#hooks/useApi"
import useHasPermission from "#hooks/useHasPermission"
import EditIcon from "@mui/icons-material/Edit"
import ExpandLess from "@mui/icons-material/ExpandLess"
import ExpandMore from "@mui/icons-material/ExpandMore"
import NoteAddIcon from "@mui/icons-material/NoteAdd"
import SnippetFolderIcon from "@mui/icons-material/SnippetFolder"
import { Button, Collapse, ListItemButton, Skeleton, Stack } from "@mui/material"
import Box from "@mui/material/Box"
import Divider from "@mui/material/Divider"
import IconButton from "@mui/material/IconButton"
import List from "@mui/material/List"
import ListItem from "@mui/material/ListItem"
import ListItemIcon from "@mui/material/ListItemIcon"
import ListItemText from "@mui/material/ListItemText"
import { cloneElement, Fragment, useMemo, useState } from "react"

const DocumentsList = () => {
	const { reload, setOpenDialog } = useDocumentsContext()
	const { data, loading } = useApi(documentsGet, { autoFetch: true, reload })
	const [open, setOpen] = useState("")
	const { hasPermissionV2 } = useHasPermission()
	const isRh = hasPermissionV2(PERMISOS.RH_ALL)
	/** @type {Array<{ id: number, name: string, category:string }>} */
	const documents = useMemo(() => data?.documents ?? [], [data?.documents])

	const categoryGroup = useMemo(() => {
		const group = []
		documents.forEach((element) => {
			if (!group.includes(element.category))
				if (!element.category) group.unshift(element.category)
				else group.push(element.category)
		})
		group.sort((a, b) => {
			if (a === null) return -1 // Mueve null al final
			if (b === null) return 1
			return a.localeCompare(b)
		})
		return group
	}, [documents])

	const groupItems = useMemo(
		() =>
			categoryGroup.map((group, index) => {
				const items = documents.filter((items) => items.category === group)
				const itemsComponent = (
					<ItemsList
						items={items}
						key={`group.${group}${index}`}
					/>
				)
				if (group === null) return itemsComponent
				const handleClick = () =>
					setOpen((old) => {
						if (old === group) return ""
						return group
					})
				return (
					<Fragment key={`group.${group}${index}`}>
						<ListItemButton onClick={handleClick}>
							<ListItemIcon>
								<SnippetFolderIcon />
							</ListItemIcon>
							<ListItemText primary={group} />
							{open ? <ExpandLess /> : <ExpandMore />}
						</ListItemButton>
						<Collapse
							in={open === group}
							timeout="auto"
							unmountOnExit
						>
							{itemsComponent}
						</Collapse>
					</Fragment>
				)
			}),
		[categoryGroup, documents, open],
	)

	if (loading) return <SkeletonList />
	return (
		<Box>
			<Stack
				direction="column"
				spacing={2}
			>
				<List
					sx={{ bgcolor: "background.paper" }}
					dense={false}
				>
					{groupItems}
				</List>
				{isRh && (
					<Button
						startIcon={<NoteAddIcon />}
						onClick={() => setOpenDialog(true)}
					>
						Agregar
					</Button>
				)}
			</Stack>
		</Box>
	)
}
export default DocumentsList

const SkeletonList = () => {
	function generate(element) {
		return [0, 1, 2, 3, 4, 5].map((value) =>
			cloneElement(element, {
				key: value,
			}),
		)
	}

	return (
		<Stack spacing={1}>
			{generate(
				<Skeleton
					variant="rectangular"
					width={320}
					height={35}
				/>,
			)}
		</Stack>
	)
}

const ItemsList = ({ items }) => {
	const { setOpenDialog, setDocumentSelect, setDocumentEdit } = useDocumentsContext()
	const { hasPermissionV2 } = useHasPermission()
	const isRh = hasPermissionV2(PERMISOS.RH_ALL)
	const handleToggle = (value) => () => {
		setDocumentSelect(value)
	}
	return items.map((document, index) => (
		<Fragment key={document.id}>
			<ListItem
				sx={{ width: "100%" }}
				dense
				secondaryAction={
					isRh && (
						<IconButton
							onClick={() => {
								setDocumentEdit(document)
								setOpenDialog(true)
							}}
							edge="end"
							aria-label="delete"
						>
							<EditIcon />
						</IconButton>
					)
				}
				disablePadding
			>
				<ListItemButton
					role={undefined}
					sx={{ width: "100%" }}
					onClick={handleToggle(document)}
					dense
				>
					<ListItemText
						primary={document.name}
						sx={{
							whiteSpace: "nowrap",
							overflow: "hidden",
							textOverflow: "ellipsis",
							maxWidth: "100%",
						}}
					/>
				</ListItemButton>
			</ListItem>
			{index < items.length - 1 && <Divider />}
		</Fragment>
	))
}
