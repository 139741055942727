import { initAxiosRH, loadAbort } from "#api/axios.service"
const axios=initAxiosRH()
const BAJAS="bajas"
const BAJAS_ID=(id)=>`${BAJAS}/${id}`
const BAJAS_CONTABILIDAD=(id)=>`${BAJAS_ID(id)}/contabilidad`


export const bajaContabilidadPut = (bajaId,data) => {
    const controller= loadAbort()
    const call= axios.put(BAJAS_CONTABILIDAD(bajaId),data,{signal:controller.signal})
    return {call,controller}
}
