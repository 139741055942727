import TablaDetalle from "#common/tablaDetalle/TablaDetalle"
import { useNavigate } from "react-router-dom"
import Detalle from "./Detalle"
import Table from "./Table"

const PerfilesPuesto = () => {
	let navigate = useNavigate()
	const handleClick = (vacante) => {
		const id = vacante?.id
		if (!id) return
		navigate(`/perfiles/${id}`)
	}
	return (
		<>
			<TablaDetalle
				tabla={<Table onClick={handleClick} />}
				Detalle={Detalle}
				nameParam="perfilPuestoId"
				ruta="/perfiles"
				title="Perfiles de Puesto"
			/>
		</>
	)
}

export default PerfilesPuesto
