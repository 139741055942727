import { useMemo, createContext, useContext, useState } from "react"
// Crear el contexto
const SolicitudesContext = createContext()

export function SolicitudesProvider({ children, colaboradorID = "", sucursal = null }) {
	const [actualizar, setActualizar] = useState(true)
	const actualizarA = () => setActualizar(!actualizar)
	return (
		<SolicitudesContext.Provider
			value={{
				colaboradorID,
				sucursal,
				actualizar,
				setActualizar,
				actualizarA,
			}}
		>
			{children}
		</SolicitudesContext.Provider>
	)
}

// Hook personalizado para usar el contexto
export function useSolicitudesContext() {
	return useContext(SolicitudesContext)
}

const DetalleColaboradorContext = createContext()

export function DetalleColaboradorProvider({ children, reload, colaborador, isRH }) {
	const value = useMemo(
		() => ({ reload, colaborador, isRH }),
		[reload, colaborador, isRH],
	)

	return (
		<DetalleColaboradorContext.Provider value={value}>
			{children}
		</DetalleColaboradorContext.Provider>
	)
}

// Hook personalizado para usar el contexto
export function UseDetalleColaboradorContext() {
	return useContext(DetalleColaboradorContext)
}

export function UseColaboradorContext() {
	return useContext(DetalleColaboradorContext)
}
