export const MODULES={
    "ALL":"All",
    "COLABORADOR":"colaboradores",
    "PERFILES_PUESTO":"perfiles_puesto",
    "VACANTES":"vacantes",


    "COLABORADOR_HERRAMIENTAS":"colaborador_herramientas",
    "PROMOTIONS":"promotions",
    "DEFAULT":"default",

    "UNIFORMES":"uniformes",
    "VACACIONES":"vacaciones",
    "CAJA_AHORRO":"caja_ahorro",
    "FONDO_FIJO":"fondo_fijo",
    "PAGOS":"pagos",
    "DOCUMENTOS":"documentos",
    "BAJAS":"bajas",
    "CARGOS":"cargos",
    "SOLICITUDES":"solicitudes",
    "CUENTAS":"cuentas",
    "FINANCIERAS":"financieras",
    "CONTABILIDAD":"contabilidad",
    "MANTENIMIENTO":"mantenimiento",
    "JEFE_NOMINAS":"jefe_nominas",
    "CURSOS":"cursos",
    "PRESTAMOS":"prestamos",
}