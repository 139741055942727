import PERMISOS from "#constants/perfiles"
import { useSelector } from "react-redux"

/**
 * Hook para validar los permisos del colaborador que inició sesión.
 *
 * @param {...string} permisos - Lista de permisos a verificar. Puede pasarse uno o varios permisos como argumentos separados por coma.
 * @param {boolean} [strict=false] - (Opcional) Si es `true`, solo se permitirán los permisos especificados.
 *                                    Si es `false`, también se permitirá el permiso de administrador (`PERFILES.ADMIN`).
 * @returns {boolean} `true` si el colaborador cumple con alguno de los permisos, `false` en caso contrario.
 *
 * @example
 * // Ejemplo: Permitir solo si el usuario tiene el rol de "colaborador" o "admin" (validación flexible)
 * const tienePermiso = useHasPermission('colaborador', 'admin');
 * console.log(tienePermiso); // true o false
 *
 * @example
 * // Ejemplo: Permitir solo si el usuario tiene exactamente los permisos "colaborador" excluyendo "admin"  (validación estricta)
 * const tienePermiso = useHasPermission('colaborador', true);
 * console.log(tienePermiso); // true o false
 *
 * @example
 * // Ejemplo: Verificación con un solo permiso
 * const tienePermiso = useHasPermission('colaborador');
 * console.log(tienePermiso); // true o false
 *
 * @example
 * // Ejemplo: Sin permisos (solo permitirá si el usuario es administrador)
 * const tienePermiso = useHasPermission();
 * console.log(tienePermiso); // true o false
 */
export default function HasPermission(...permisos) {
	const perfiles = useSelector((state) => state.user.profiles)
	let strict = false

	if (typeof permisos[permisos.length - 1] === "boolean") {
		strict = permisos.pop()
	}

	if (!strict) permisos.push(PERMISOS.ADMIN)

	return perfiles.some((perfil) => permisos.includes(perfil))
}
