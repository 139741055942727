import { formatMoney } from "#common/Funciones"
import { Skeleton } from "@mui/material"
import Box from "@mui/material/Box"
import { ChangeTitle } from "Components/menu/funciones"
import MUIDataTable from "mui-datatables"
import { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { apiGetSolicitudesPagos } from "../../Services/Api_Pagos"
import EnviarPago from "./EnviarPago"

export function Solicitudes(actualizar, colaborador = null) {
	const token = useSelector((state) => state.user.token)
	const [solicitudes, setSolicitudes] = useState([])
	const [loading, setLoading] = useState(true)
	function loadingF() {
		setLoading(false)
	}
	useEffect(() => {
		setLoading(true)
		apiGetSolicitudesPagos({
			token,
			setDB: setSolicitudes,
			loading: loadingF,
			params: { colaborador },
		})
	}, [colaborador, token, actualizar])

	return { solicitudes, loading }
}

export default function Pagos() {
	ChangeTitle("Pagos")
	const [openPagos, setOpenPagos] = useState([])
	const [actualizar, setActualizar] = useState([])
	const actualizarNow = () => setActualizar(!actualizar)
	const { solicitudes, loading } = Solicitudes(actualizar)
	const columns = [
		{
			name: "id",
			label: "ID",
			options: {
				filter: false,
				sort: true,
				display: false,
			},
		},
		{
			name: "colaborador_id",
			label: "colaborador_id",
			options: {
				filter: false,
				sort: true,
				display: false,
			},
		},
		{
			name: "nombre_colaborador",
			label: "Colaborador",
			options: {
				filter: false,
				sort: true,
				display: true,
			},
		},

		{
			name: "type_pay",
			label: "Tipo de pago",
			options: {
				filter: false,
				sort: true,
				display: true,
			},
		},
		{
			name: "pay",
			label: "Cantidad",
			options: {
				customBodyRender: (value, tableMeta) => {
					const solicitud = solicitudes[tableMeta.rowIndex]
					return formatMoney(value, solicitud?.moneda)
				},
				filter: true,
				sort: true,
			},
		},
	]
	const options = {
		textLabels: {
			body: {
				noMatch: "No hay Pagos", // Cambia este mensaje
			},
		},
		filterType: "checkbox",
		search: false,
		filter: false,
		download: false,
		print: false,
		viewColumns: false,
		selectableRows: "none",
		onRowClick: (rowData, rowMeta) => {
			let solicitud = solicitudes[rowMeta.rowIndex]

			setOpenPagos({
				open: true,
				solicitud,
				colaborador: rowData[1],
			})
		},
	}
	if (loading)
		return (
			<Skeleton
				variant="rectangular"
				width="100%"
				height={"80vh"}
			/>
		)

	return (
		<Box>
			<MUIDataTable
				data={solicitudes}
				columns={columns}
				options={options}
			/>
			<EnviarPago
				open={openPagos.open}
				setOpen={setOpenPagos}
				solicitud={openPagos.solicitud}
				actualizar={actualizarNow}
			/>
		</Box>
	)
}
