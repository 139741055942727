import { useApi } from "#hooks/useApi"
import { Skeleton } from "@mui/material"
import { useDocumentsContext } from "../hooks/context"
import { documentGet } from "../services/Api"

import { SpecialZoomLevel, Viewer, Worker } from "@react-pdf-viewer/core"

const DocumentViewPDF = () => {
	const { documentSelect } = useDocumentsContext()
	const { data, loading } = useApi(documentGet, {
		autoFetch: !!documentSelect?.id,
		params: documentSelect?.id,
	})
	if (!documentSelect) return null
	if (loading) return <SkeletonPDF />
	return (
		<Worker workerUrl="https://unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.min.js">
			<Viewer
				defaultScale={SpecialZoomLevel.PageWidth}
				fileUrl={data?.path_sign}
				renderLoader={(percentages) => (
					<div style={{ width: "100%" }}>
						<SkeletonPDF />
					</div>
				)}
			/>
		</Worker>
	)
}

export default DocumentViewPDF

const SkeletonPDF = () => {
	return (
		<div style={{ flex: 3.2, height: "87vh", display: "flex" }}>
			<Skeleton
				variant="rectangular"
				width="100%"
				height="100%"
				style={{ flex: 1 }}
			/>
		</div>
	)
}
