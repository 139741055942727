import { initAxiosRH, loadAbort } from "#api/axios.service"
const axios=initAxiosRH()
const VACANTES="vacantes"

export const vacantesGet = (params) => {
    const controller= loadAbort()
    const call= axios.get(VACANTES,{params,signal:controller.signal})
    return {call,controller}
}

export const vacanteGet = (vacanteId) => {
    const controller= loadAbort()
    const call= axios.get(`${VACANTES}/${vacanteId}`,{signal:controller.signal})
    return {call,controller}
}
