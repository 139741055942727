import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux" //Token

//componentes
import ListAreas from "./PlanAnualComponents/ListAreas"
import { PlanAnualContextProvider } from "./PlanAnualComponents/PlanAnualContext"
import VistaPlanAnual from "./PlanAnualComponents/VistaPlanAnual"
import TablaCursos from "./TablaCursos"

//Material Ui
import Box from "@mui/material/Box"
import Tab from "@mui/material/Tab"
import Tabs from "@mui/material/Tabs"
import { ChangeTitle } from "Components/menu/funciones"
import PropTypes from "prop-types"

function TabPanel(props) {
	const { children, value, index, ...other } = props

	return (
		<div
			role="tabpanel"
			hidden={value !== index}
			id={`simple-tabpanel-${index}`}
			aria-labelledby={`simple-tab-${index}`}
			{...other}
		>
			{value === index && <Box sx={{ p: 3 }}>{children}</Box>}
		</div>
	)
}

TabPanel.propTypes = {
	children: PropTypes.node,
	index: PropTypes.number.isRequired,
	value: PropTypes.number.isRequired,
}

function a11yProps(index) {
	return {
		id: `simple-tab-${index}`,
		"aria-controls": `simple-tabpanel-${index}`,
	}
}

export default function Cursos() {
	const token = useSelector((state) => state.user.token)
	const [value, setValue] = useState(0)
	const dispatch = useDispatch()
	const handleChange = (event, newValue) => {
		setValue(newValue)
	}

	ChangeTitle("Plan anual departamento")
	useEffect(() => {
		//-------------------------------------------------------------------------------------------------------------------------------------
	}, [dispatch, token])

	return (
		<Box sx={{ width: "100%" }}>
			<Box sx={{ borderBottom: 1, borderColor: "divider" }}>
				<Tabs
					value={value}
					onChange={handleChange}
					aria-label="basic tabs example"
				>
					<Tab
						label="Plan Anual"
						{...a11yProps(0)}
					/>
					<Tab
						label="Crear Plan Anual"
						{...a11yProps(1)}
					/>
					<Tab
						label="catalogo de cursos"
						{...a11yProps(2)}
					/>
				</Tabs>
			</Box>
			<TabPanel
				value={value}
				index={0}
			>
				<VistaPlanAnual />
			</TabPanel>
			<TabPanel
				value={value}
				index={1}
			>
				<PlanAnualContextProvider>
					<ListAreas />
				</PlanAnualContextProvider>
			</TabPanel>
			<TabPanel
				value={value}
				index={2}
			>
				<TablaCursos />
			</TabPanel>
		</Box>
	)
}
