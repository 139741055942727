import { useDispatch } from "react-redux"
import VacanteSlice from "./slice"
import { useCallback } from "react"

export const { DetalleReload, ReloadAll, ReloadTable, setVacante, setIsRh } =
	VacanteSlice.actions

export const useReloadPerfilesPuesto = () => {
	const dispatch = useDispatch()
	const fn = useCallback(() => {
		dispatch(ReloadTable())
	}, [dispatch])
	return fn
}

export const useVacanteReloadAll = () => {
	const dispatch = useDispatch()
	const fn = useCallback(() => {
		dispatch(ReloadAll())
	}, [dispatch])
	return fn
}
export const useReloadDetalle = () => {
	const dispatch = useDispatch()
	const fn = useCallback(() => {
		dispatch(DetalleReload())
	}, [dispatch])
	return fn
}
export const useSetVacante = () => {
	const dispatch = useDispatch()
	const fn = useCallback(
		(value) => {
			dispatch(setVacante(value))
		},
		[dispatch],
	)
	return fn
}
export const useSetRh = () => {
	const dispatch = useDispatch()
	const fn = useCallback(
		(value) => {
			dispatch(setIsRh(value))
		},
		[dispatch],
	)
	return fn
}
