import { api_rh_get } from "#api/API_RH"
import { getPerfiles, getSucursales } from "#api/Api"
import { putColaborador } from "#api/ApiColaborador"
import PERMISOS from "#constants/perfiles"
import useHasPermission from "#hooks/useHasPermission"
import CheckIcon from "@mui/icons-material/Check"
import EditIcon from "@mui/icons-material/Edit"
import { Box } from "@mui/material"
import Autocomplete from "@mui/material/Autocomplete"
import Dialog from "@mui/material/Dialog"
import DialogContent from "@mui/material/DialogContent"
import DialogTitle from "@mui/material/DialogTitle"
import IconButton from "@mui/material/IconButton"
import Stack from "@mui/material/Stack"
import TextField from "@mui/material/TextField"
import { UseColaboradorContext } from "Components/Colaboradores/Solicitudes/SolicitudesContext"
import { useCallback, useEffect, useMemo, useState } from "react"
import { useSelector } from "react-redux"
import { toast } from "react-toastify"
import { useColaboradorReloadAll } from "../redux/slice"
import {
	ColaboradorEditProvider,
	UseColaboradorEditContext,
} from "./ColaboradorEditContext"

import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs"
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker"
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider"
import dayjs from "dayjs"

const DialogColaboradorEdit = ({ open, setOpen }) => {
	return (
		<Dialog
			open={open}
			onClose={() => setOpen(false)}
			fullWidth
			maxWidth={"sm"}
		>
			<DialogTitle sx={{ fontSize: 24, fontWeight: "bold", color: "#555" }}>
				Editar Colaborador
			</DialogTitle>
			<DialogContent>
				<ColaboradorEdit />
			</DialogContent>
		</Dialog>
	)
}

export default DialogColaboradorEdit

const ColaboradorEdit = () => {
	const reloadNow = useColaboradorReloadAll()
	const { colaborador, reload } = UseColaboradorContext()
	const [edit, setEdit] = useState("")
	const [newValue, setNewValue] = useState(null)
	const [disabledEdit, setDisabledEdit] = useState(false)
	const sendData = useCallback(() => {
		if (!newValue) {
			setEdit("")
			setNewValue(null)
			return
		}
		if (edit === "email" && !validatedEmailEmpresa(newValue)) {
			toast.error("Correo no válido")
			return
		}

		setDisabledEdit(true)
		putColaborador(
			colaborador.id,
			{ [edit]: newValue },
			{
				success: () => {
					setEdit("")
					setNewValue(null)
					reload()
					reloadNow()
				},
				end: () => setDisabledEdit(false),
			},
		)
	}, [colaborador.id, edit, newValue, reload, reloadNow])
	return (
		<Box width={"100%"}>
			<ColaboradorEditProvider
				{...{ sendData, setNewValue, disabledEdit, edit, setEdit }}
			>
				<ColaboradorEditPersonales
					{...{ edit, setEdit, sendData, setNewValue, disabledEdit }}
				/>
			</ColaboradorEditProvider>
		</Box>
	)
}

const ColaboradorEditPersonales = ({ setNewValue, edit }) => {
	const { colaborador } = UseColaboradorContext()
	const { hasPermissionV2 } = useHasPermission()
	const token = useSelector((state) => state.user.token)
	const { perfiles, colaboradores, sucursales } = GetDataSelects(token, edit)
	const is_admin = hasPermissionV2(PERMISOS.GRTE_RH)
	const extras = is_admin
		? [
				<InputSelectActivate
					key="perfil_puesto_id"
					id="perfil_puesto_id"
					label="Perfil de puesto"
					options={perfiles}
					onChange={(value) => setNewValue(value?.id)}
					defaultValue={colaborador.perfil_puesto}
				/>,
				<InputSelectActivate
					key="jefe_directo_id"
					id="jefe_directo_id"
					label="Jefe Directo"
					options={colaboradores}
					onChange={(value) => setNewValue(value?.value)}
					defaultValue={colaborador.jefe_directo}
				/>,
				<InputSelectActivate
					key="sucursal_id"
					id="sucursal_id"
					label="Sucursal"
					options={sucursales}
					onChange={(value) => setNewValue(value?.value)}
					defaultValue={colaborador.sucursal}
				/>,
				<InputDateActivate
					key="fecha_alta"
					id="fecha_alta"
					label="Fecha Alta"
					onChange={(value) => setNewValue(value)}
				/>,
		  ]
		: []

	return (
		<Stack
			spacing={2}
			m={2}
		>
			<InputTextActivate
				id="nombre"
				label="Nombre"
				onChange={(value) => setNewValue(value)}
			/>
			<InputTextActivate
				id="apellido_paterno"
				label="Apellido Paterno"
				onChange={(value) => setNewValue(value)}
			/>
			<InputTextActivate
				id="apellido_materno"
				label="Apellido Materno"
				onChange={(value) => setNewValue(value)}
			/>

			<InputTextActivate
				id="telefono_lubtrac"
				label="Teléfono Empresa"
				onChange={(value) => setNewValue(value)}
			/>
			<InputTextActivate
				id="email"
				label="Correo Empresa"
				onChange={(value) => {
					setNewValue(value)
				}}
			/>
			<InputTextActivate
				id="extension_lubtrac"
				label="Extensión"
				onChange={(value) => {
					setNewValue(value)
				}}
			/>
			{extras}
		</Stack>
	)
}

const InputSelectActivate = ({
	label,
	options,
	defaultValue = "",
	onChange = () => {},
	...props
}) => {
	const { edit, disabledEdit } = UseColaboradorEditContext()
	const [value, setValue] = useState(defaultValue)
	return (
		<EditField {...props}>
			<Autocomplete
				id={props.id}
				disabled={edit !== props.id || disabledEdit}
				options={options}
				sx={{ width: "100%" }}
				value={value}
				onChange={(event, value) => {
					setValue(value)
					onChange(value)
				}}
				renderInput={(params) => (
					<TextField
						{...params}
						label={label}
					/>
				)}
			/>
		</EditField>
	)
}
const InputTextActivate = ({
	label,
	defaultValue = "",
	onChange = () => {},
	type = "text",
	...props
}) => {
	const { edit } = UseColaboradorEditContext()
	const { colaborador } = UseColaboradorContext()
	const [value, setValue] = useState(colaborador[props.id])

	return (
		<EditField {...props}>
			<TextField
				label={label}
				variant="outlined"
				type={type}
				fullWidth
				disabled={edit !== props.id}
				value={value}
				onChange={(e) => {
					onChange(e.target.value)
					setValue(e.target.value)
				}}
			/>
		</EditField>
	)
}
const InputDateActivate = ({
	label,
	defaultValue = "",
	onChange = () => {},
	type = "text",
	...props
}) => {
	const { edit, disabledEdit } = UseColaboradorEditContext()
	const { colaborador } = UseColaboradorContext()
	const [value, setValue] = useState(dayjs(colaborador[props.id], "D-M-YYYY"))

	return (
		<EditField {...props}>
			<LocalizationProvider dateAdapter={AdapterDayjs}>
				<DesktopDatePicker
					sx={{ width: "100%" }}
					label="Fecha de Ingreso"
					inputFormat="MM/DD/YYYY"
					value={value}
					onChange={(value) => {
						setValue(value)
						onChange(value?.format("YYYY-MM-DD"))
					}}
					renderInput={(params) => (
						<TextField
							{...params}
							fullWidth
						/>
					)}
					disabled={edit !== props.id || disabledEdit}
				/>
			</LocalizationProvider>
		</EditField>
	)
}

const EditField = ({ id, children }) => {
	const { sendData, edit, setEdit, disabledEdit } = UseColaboradorEditContext()
	const Icon = edit === id ? CheckIcon : EditIcon
	return (
		<Stack
			direction={"row"}
			spacing={1}
		>
			{children}
			<IconButton
				aria-label="delete"
				size="large"
				color="success"
				disabled={(edit && edit !== id) || disabledEdit}
				onClick={() => {
					if (edit === id) {
						sendData()
					} else setEdit(id)
				}}
			>
				<Icon fontSize="inherit" />
			</IconButton>
		</Stack>
	)
}

const GetDataSelects = (token, field) => {
	const [perfiles, setPerfiles] = useState([])
	const [colaboradores, setColaboradores] = useState([])

	const [sucursales, setSucursales] = useState([])
	const params = useMemo(() => ({ only_name: true }), [])

	useEffect(() => {
		if (field === "perfil_puesto_id") {
			getPerfiles(token)
				.then((response) => {
					response.map((perfil) => (perfil.label = perfil.nombre))
					setPerfiles(response)
				})
				.catch((error) => console.error(error))
		}
		if (field === "jefe_directo_id") {
			api_rh_get(`colaboradores`, {
				params,
				success: (response) => {
					let colaboradores = []
					if (response?.colaboradores)
						colaboradores = response?.colaboradores.map((colaborador) => ({
							value: colaborador.id,
							label: colaborador.full_name,
						}))
					setColaboradores(colaboradores)
				},
			})
		}
		if (field === "sucursal_id") {
			getSucursales(token).then((response) => {
				response.data.data.map((sucursal) => {
					sucursal.label = sucursal.nombre
					sucursal.value = sucursal.id
					return sucursal
				})
				setSucursales(response.data.data)
			})
		}
	}, [field, params, token])

	return { perfiles, colaboradores, sucursales }
}
const validatedEmailEmpresa = (value) => {
	const correoPermitido = ["@lubtrac.com.mx", "@lubtrac.com.gt", "@becoming.mx"]
	const isEmailValid = correoPermitido.some((endStr) => value.endsWith(endStr))
	return isEmailValid
}
