import { candidatoPut } from "#api/Api/requerimientos"
import { useReloadDetalle } from "#vacantes/redux/actions"
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth"
import CheckCircleIcon from "@mui/icons-material/CheckCircle"
import EventAvailableIcon from "@mui/icons-material/EventAvailable"
import FolderSharedIcon from "@mui/icons-material/FolderShared"
import HistoryIcon from "@mui/icons-material/History"
import HowToRegIcon from "@mui/icons-material/HowToReg"
import IndeterminateCheckBoxIcon from "@mui/icons-material/IndeterminateCheckBox"
import MeetingRoomIcon from '@mui/icons-material/MeetingRoom'
import MilitaryTechIcon from "@mui/icons-material/MilitaryTech"
import RequestQuoteIcon from '@mui/icons-material/RequestQuote'
import Paper from "@mui/material/Paper"
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
import TableContainer from "@mui/material/TableContainer"
import TableHead from "@mui/material/TableHead"
import TableRow from "@mui/material/TableRow"
import DialogNewColaborador from "Components/Colaboradores/DialogNewColaborador"
import { Fragment, useState } from "react"
import { OPCIONESCANDIDATO, PERFILES } from "../../../Constants/Strings"
import { TABLEHEADER, TABLEROW } from "../../../Constants/Styles"
import { ButtonIcon } from "../../Common/Buttons"
import { HasPermission } from "../../Common/Funciones"
import ModalAgendar from "./ModalAgendar"
import ModalConfirm from "./ModalConfirm"
import ModalDocumentacion from "./ModalDocumentacion"
import ModalHistorialCandidato from "./ModalHistorialCandidato"
const TableCandidatos = ({ candidatos }) => {
	const reload = useReloadDetalle()
	const [openHistorial, setOpenHistorial] = useState(false)
	const [openDocum, setOpenDocum] = useState(false)
	const [openConfirm, setOpenConfirm] = useState(false)
	const [openAgendar, setOpenAgendar] = useState(false)
	const [openColabo, setOpenColabo] = useState(false)
	const [disabledBtn, setDisabledBtn] = useState(false)
	const [candidato, setCandidato] = useState(null)
	const [opcCand, setOpcCand] = useState({})
	const [dataCandidato, setDataCandidato] = useState({})

	candidatos = candidatos == null ? [] : candidatos


	const openModalHistorial = (candidato) => {
		setCandidato(candidato)
		setOpenHistorial(true)
	}

	const openModalDocumentation = (candidato) => {
		setCandidato(candidato)
		setOpenDocum(true)
	}

	const openModalAgendar = (candidato, opcion) => {
		setOpcCand(opcion)
		setCandidato(candidato)
		setOpenAgendar({ open: true })
	}
	const openModalAgendarFile = (candidato, option, file) => {
		setOpcCand(option)
		setCandidato(candidato)
		setOpenAgendar({ open: true, file })
	}

	//Control del modal ModalConfirm
	const openModalConfirmation = (candidato, option) => {
		setOpcCand(option)
		setCandidato(candidato)
		setOpenConfirm(true)
	}

	const cambioStatus = async (observaciones, file = null) => {
		setDisabledBtn(true)
		const success = () => {
			reload()
		}
		const end = () => {
			setDisabledBtn(false)
		}
		let formData = new FormData()
		formData.append("status_id", opcCand.siguienteStatus)
		formData.append("observaciones", observaciones)
		formData.append("file", file)

		candidatoPut(candidato.id, formData, { success, end })
	}

	const agendar = (fecha, file) => {
		setDisabledBtn(true)
		const success = () => {
			reload()
		}
		const end = () => {
			setDisabledBtn(false)
		}
		let formData = new FormData()
		formData.append("status_id", opcCand.siguienteStatus)
		formData.append("fecha", fecha)
		formData.append("file", file)

		candidatoPut(candidato.id, formData, { success, end })
	}

	const nuevoColaborador = (candidato) => {
		setDataCandidato(candidato.id)
		setOpenColabo(true)
	}
	const CANDIDATOS_STATUS = {
		Filtros: "1",
		Agendar: "2", //psychometrics
		Agendado: "3",
		Entrevistado: "4", //carta oferta y si es reparto certificado_manejo
		Descartado: "5",
		Final: "6",
		Ingresar: "7",
		Colaborador: "8",
		Propuesta: "9",
	}

	const BUTTONS = {
		[CANDIDATOS_STATUS.Filtros]: {
			permisos: HasPermission( [PERFILES.RH]), //*["RH"],
			icon: <CheckCircleIcon color="success" />,
			title: OPCIONESCANDIDATO.PORAGENDAR.tooltip,
			onClick: (candidato) =>
				openModalConfirmation(candidato, OPCIONESCANDIDATO.PORAGENDAR),
		},

		[CANDIDATOS_STATUS.Agendar]: {
			permisos: true,
			icon: <CalendarMonthIcon color="success" />,
			title: OPCIONESCANDIDATO.AGENDAR.tooltip,
			onClick: (candidato) =>
				openModalAgendarFile(candidato, OPCIONESCANDIDATO.AGENDAR, "Psicometricos"),
		},

		[CANDIDATOS_STATUS.Agendado]: {
			permisos: true,
			icon: <HowToRegIcon color="success" />,
			title: OPCIONESCANDIDATO.ENTREVISTADO.tooltip,
			onClick: (candidato) =>
				openModalConfirmation(candidato, OPCIONESCANDIDATO.ENTREVISTADO),
		},

		[CANDIDATOS_STATUS.Entrevistado]: {
			permisos: true,
			icon: <MilitaryTechIcon color="success" />,
			title: OPCIONESCANDIDATO.CANDIDATOFINAL.tooltip,
			onClick: (candidato) =>
				openModalConfirmation(candidato, OPCIONESCANDIDATO.CANDIDATOFINAL),
		},

		[CANDIDATOS_STATUS.Propuesta]: {
			permisos: HasPermission( [PERFILES.RH]), // ["RH", "Admin"],
			icon: <EventAvailableIcon color="success" />,
			title: OPCIONESCANDIDATO.PROPUESTA.tooltip,
			onClick: (candidato) =>
				openModalAgendar(candidato, OPCIONESCANDIDATO.AGENDARINGRESO),
		},
		[CANDIDATOS_STATUS.Final]: {
			permisos: HasPermission( [PERFILES.RH]), // ["RH", "Admin"],
			icon: <RequestQuoteIcon color="success" />,
			title: OPCIONESCANDIDATO.AGENDARINGRESO.tooltip,
			onClick: (candidato) =>
				openModalConfirmation(candidato, OPCIONESCANDIDATO.PROPUESTA),
		},

		[CANDIDATOS_STATUS.Ingresar]: {
			permisos: HasPermission( [PERFILES.RH]), // ["RH", "Admin"],
			icon: <MeetingRoomIcon color="success" />,
			title: OPCIONESCANDIDATO.INGRESO.tooltip,
			onClick: (candidato) => nuevoColaborador(candidato),
		},
	}

	return (
		<Fragment>

			<TableContainer component={Paper}>
				<Table aria-label="simple table">
					<TableHead>
						<TableRow>
							<TableCell sx={TABLEHEADER}>Nombre</TableCell>
							<TableCell sx={TABLEHEADER}>Correo</TableCell>
							<TableCell sx={TABLEHEADER}>Telefono</TableCell>
							<TableCell sx={TABLEHEADER}>Status</TableCell>
							<TableCell sx={TABLEHEADER}></TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{candidatos.map((candidato, index) => {
							let button = false
							let buttonRechazar = false
							let buttonShowDocument = (
								<ButtonIcon
									title="Ver Documentación"
									color="primary"
									onClick={() => openModalDocumentation(candidato)}
									icon={<FolderSharedIcon />}
								/>
							)
							let buttonShowHistory = (
								<ButtonIcon
									title="Historial del candidato"
									color="primary"
									onClick={() => openModalHistorial(candidato)}
									icon={<HistoryIcon />}
								/>
							)

							if (BUTTONS[candidato.status]?.permisos)
								button = (
									<ButtonIcon
										{...BUTTONS[candidato.status]}
										onClick={() => BUTTONS[candidato.status].onClick(candidato)}
									/>
								)
							if (candidato.status !== 5 && candidato.status !== 8)
								buttonRechazar = (
									<ButtonIcon
										title={OPCIONESCANDIDATO.RECHAZAR.tooltip}
										icon={<IndeterminateCheckBoxIcon color="error" />}
										onClick={() =>
											openModalConfirmation(
												candidato,
												OPCIONESCANDIDATO.RECHAZAR
											)
										}
									/>
								)
							return (
								<TableRow key={index + "candidato"}>
									<TableCell sx={TABLEROW}>{candidato.nombre}</TableCell>
									<TableCell sx={TABLEROW}>{candidato.correo}</TableCell>
									<TableCell sx={TABLEROW}>{candidato.telefono}</TableCell>
									<TableCell sx={TABLEROW}>{candidato.status_desc}</TableCell>
									<TableCell sx={TABLEROW}>
										{buttonShowHistory}
										{buttonShowDocument}
										{button}
										{buttonRechazar}
									</TableCell>
								</TableRow>
							)
						})}
					</TableBody>
				</Table>
			</TableContainer>

			<ModalHistorialCandidato
				open={openHistorial}
				setOpen={setOpenHistorial}
				candidato={candidato}
			/>

			<ModalDocumentacion
				open={openDocum}
				setOpen={setOpenDocum}
				candidato={candidato}
			/>

			<ModalAgendar
				open={openAgendar.open}
				setOpen={setOpenAgendar}
				candidato={candidato}
				agendarEntrevista={agendar}
				disabledBtn={disabledBtn}
				file={false}
			/>

			<ModalConfirm
				open={openConfirm}
				setOpen={setOpenConfirm}
				candidato={candidato}

				aprobar={cambioStatus}
				disabledBtn={disabledBtn}
				opcion={opcCand}
			/>

			<DialogNewColaborador
				open={openColabo}
				setOpen={setOpenColabo}
				candidato={dataCandidato}
			/>
		</Fragment>
	)
}

export default TableCandidatos
