import { Box, Button, Stack } from "@mui/material"
import { ChangeTitle } from "Components/menu/funciones"
import { useCallback, useState } from "react"
import { useNavigate } from "react-router"
import { useParams } from "react-router-dom"
import PromotionDetails from "./PromotionDetails"
import PromotionTable from "./PromotionTable"
// import { PromotionsProvider } from "./contexts/PromotionContext"
import { usePromotionData } from "./hooks/getPromotions"
import ArrowBackIcon from "@mui/icons-material/ArrowBack"
import { PromotionsProvider } from "./PromotionContext"
const Promotions = () => {
	ChangeTitle("Promociones")
	let navigate = useNavigate()
	const { promotionsId } = useParams()
	const [reload, setReload] = useState(true)
	const { promotions, loading } = usePromotionData(reload)

	const openPromotionDetail = useCallback(
		(PromotionId) => {
			navigate(`${PromotionId}`)
		},
		[navigate],
	)
	return (
		<Box>
			<PromotionsProvider {...{ reloadTable: () => setReload(!reload) }}>
				<Animation activar={!promotionsId}>
					<PromotionTable
						{...{ promotions, loading }}
						onClick={openPromotionDetail}
					/>
				</Animation>
				<Animation activar={promotionsId}>
					<PromotionsDetails promotionsId={promotionsId} />
				</Animation>
			</PromotionsProvider>
		</Box>
	)
}

export default Promotions

const PromotionsDetails = ({ promotionsId }) => {
	let navigate = useNavigate()
	const [promotionReload, setPromotionReload] = useState(true)

	return (
		<Stack spacing={2}>
			<div>
				<Button
					variant="outlined"
					onClick={() => navigate("/promociones")}
					startIcon={<ArrowBackIcon />}
				>
					Tabla de Promociones
				</Button>
			</div>

			<PromotionDetails
				promotionId={promotionsId}
				reload={() => setPromotionReload(!promotionReload)}
			/>
		</Stack>
	)
}
const Animation = ({ activar, children }) => {
	return (
		<div
			style={{
				overflow: "hidden",
				transition: "max-height 0.5s ease-out, opacity 0.5s ease, transform 0.5s ease",
				opacity: activar ? 1 : 0,
				maxHeight: activar ? "100vh" : "0",
				pointerEvents: activar ? "auto" : "none",
			}}
		>
			{children}
		</div>
	)
}
