import logoLubtrac from "./logos/logo_lubtrac.jpg"
import logoMorales from "./logos/logo_morales.jpg"
import logoDefault from "./logos/logo_lubtrac.jpg"

const logos={
    1: logoLubtrac,
    3: logoLubtrac,
    9: logoMorales
}

const getLogo=()=>{
    const sociedad_id = localStorage.getItem("sociedad_id")
    return logos[sociedad_id]??logoDefault

}

const LOGO =getLogo()
export {LOGO}
