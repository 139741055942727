import { promotionPut } from "#api/promotions"
import ButtonIcon from "#common/buttons/ButtonIcon"
import { MasterDialog } from "#common/Dialog/Dialog"
import { InputDate } from "#common/input/InputDate"
import CancelIcon from "@mui/icons-material/Cancel"
import CheckCircleIcon from "@mui/icons-material/CheckCircle"
import HistoryIcon from "@mui/icons-material/History"
import Box from "@mui/material/Box"
import Stack from "@mui/material/Stack"
import TextField from "@mui/material/TextField"
import Typography from "@mui/material/Typography"
import ModalHistorial from "Components/Colaboradores/MiEquipo/ModalHistorial"
import dayjs from "dayjs"
import { useCallback, useEffect, useMemo, useState } from "react"
import { toast } from "react-toastify"
import { usePromotionsContext } from "./PromotionContext"
import { STATUS } from "./utils/constants"

export function PromotionButtons({ promotion_id, statusId }) {
	const [open, setOpen] = useState(false)
	const [openConfirm, setOpenConfirm] = useState(false)
	const [openReject, setOpenReject] = useState(false)

	const hidden = useMemo(() => {
		return statusId !== STATUS.En_Proceso
	}, [statusId])
	const hiddenReject = useMemo(() => {
		return statusId === STATUS.Finalizada || statusId === STATUS.Rechazada
	}, [statusId])

	const ButtonsIcons = useMemo(() => {
		const buttonsList = [
			{
				title: "Aprobar",
				color: "success",
				hidden,
				icon: <CheckCircleIcon fontSize="inherit" />,
				onClick: () => {
					setOpenConfirm(true)
				},
			},
			{
				title: "Rechazar",
				color: "error",
				hidden: hiddenReject,
				icon: <CancelIcon fontSize="inherit" />,
				onClick: () => {
					setOpenReject(true)
				},
			},
			{
				title: "Historial",
				color: "primary",
				icon: <HistoryIcon fontSize="inherit" />,
				onClick: () => {
					setOpen(true)
				},
			},
		]
		return buttonsList.map((item) => (
			<ButtonIcon
				key={item.title}
				size="large"
				{...item}
			/>
		))
	}, [hidden, hiddenReject])

	return (
		<Box sx={{ display: "flex", justifyContent: "right", paddingX: 3 }}>
			<Stack direction={"row"}>{ButtonsIcons}</Stack>
			<ModalHistorial
				setOpen={setOpen}
				open={open}
				promotion={promotion_id}
			/>
			<PromotionDialogConfirm
				open={openConfirm}
				setOpen={setOpenConfirm}
			/>
			<PromotionDialogReject
				open={openReject}
				setOpen={setOpenReject}
			/>
		</Box>
	)
}
const PromotionDialogConfirm = ({ open, setOpen }) => {
	const { reload, promotionId } = usePromotionsContext()
	const [apply_date, setApply_date] = useState(null)
	const [disabled, setDisabled] = useState(false)
	const [alert, setAlert] = useState("")

	let sendResponse = useCallback(() => {
		let data = {
			apply_date: apply_date?.$d,
			status: STATUS.Aprobada_RH,
		}
		setDisabled(true)

		if (!apply_date?.isValid()) {
			toast.error("Se necesita una fecha valida", {
				toastId: "error_Fecha",
			})
			return null
		}
		const success = () => {
			setOpen(false)
			reload()
		}

		const end = () => {
			setDisabled(false)
		}

		promotionPut(promotionId, data, { success, end })
	}, [apply_date, promotionId, reload, setOpen])

	useEffect(() => {
		let now = dayjs()
		setDisabled(!apply_date?.isValid())
		if (apply_date?.isValid() && apply_date <= now) {
			setAlert(
				"Advertencia la promoción se efectuara de manera inmediata cuando se aprueben todas los movimientos",
			)
		} else setAlert("")
	}, [apply_date])

	const buttons = useMemo(
		() => [{ label: "aceptar", onClick: sendResponse, disabled: disabled }],
		[disabled, sendResponse],
	)

	return (
		<MasterDialog
			{...{ title: "Aprobar promoción", open, setOpen }}
			buttons={buttons}
			closeDialog={() => {
				setApply_date(null)
			}}
		>
			<Stack spacing={2}>
				<Typography
					width={"100%"}
					variant="h6"
					textAlign={"center"}
					sx={{ width: "100%" }}
				>
					Asigna la fecha en la que se efectuara la promoción.
				</Typography>

				<InputDate
					sx={{ width: "100%" }}
					label=""
					value={apply_date}
					onChange={setApply_date}
				/>
				<Typography
					color={"red"}
					align="center"
					variant="subtitle2"
				>
					{alert}
				</Typography>
			</Stack>
		</MasterDialog>
	)
}

const PromotionDialogReject = ({ open, setOpen }) => {
	const { reload, promotionId } = usePromotionsContext()
	const [comentario, setComentario] = useState("")
	const [disabled, setDisabled] = useState(false)
	let sendResponse = useCallback(() => {
		let data = {
			comentario: comentario.trim(),
			status: STATUS.Rechazada,
		}
		if (!data.comentario) {
			toast.error("Se necesita un comentario", {
				toastId: "error_comentario",
			})
			return null
		}

		const success = () => {
			setOpen(false)
			reload()
		}
		const end = () => {
			setDisabled(false)
		}
		setDisabled(true)
		promotionPut(promotionId, data, { success, end })
	}, [comentario, promotionId, reload, setOpen])

	useEffect(() => {
		setDisabled(!comentario.trim())
	}, [comentario])

	const buttons = useMemo(
		() => [{ label: "aceptar", onClick: sendResponse, disabled: disabled }],
		[disabled, sendResponse],
	)

	return (
		<MasterDialog
			{...{ title: "Rechazar promoción", open, setOpen }}
			buttons={buttons}
			closeDialog={() => {
				setComentario("")
			}}
		>
			<Typography
				variant="h6"
				textAlign={"center"}
			>
				¿Estas seguro de cancelar el proceso de promoción?
			</Typography>
			<TextField
				label="Comentario *"
				onChange={(e) => setComentario(e.target.value)}
				autoComplete="off"
				multiline
				fullWidth
			/>
		</MasterDialog>
	)
}
