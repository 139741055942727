import {useEffect, useState} from 'react'
import { Grid, Box } from '@mui/material';
import { PRIMARYCOLOR } from '../../../Constants/Styles'
import camisa from '../../../Images/camisa.png'
import polo from '../../../Images/polo.png'
import pantalones from '../../../Images/pantalones.png'
import botas from '../../../Images/botas.png'
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
// import LOGO from '../../../Images/logo.jpg'
import {LoadViewTable} from '../../Common/LoadingView'
import { LOGO } from 'Images/imgRutas';


function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}            >
                {value === index && (
                    <Box sx={{ p: 3 }}>{children}</Box>
                )}
        </div>
    );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const ItemDetalle = ({imagen, titulo, cantidades, tallas, total = 0}) => {
    return(
        <Grid container item xs={12} sx={{mt:2}}>
             <Grid item xs={2} sx={{ textAlign: 'center' }}>
                <Box
                    component="img"
                    sx={{ height: 75, marginTop: 2 }}
                    alt="No se cuenta con fotografia"
                    src={imagen}/>
            </Grid>
            <Grid container item xs={10} sx={{ textAlign: 'center' }}>
                <Grid item xs={12} >
                    <Box component={'span'} sx={{ fontSize: 20, fontWeight: 'bold', color: PRIMARYCOLOR }}>  {titulo} </Box>
                </Grid>
                {tallas.map(talla => {
                    return <Grid key={titulo+talla} container item xs={6} sx={{ border: 1 }}>
                                <Grid item xs={6} sx={{ textAlign: 'center', backgroundColor: PRIMARYCOLOR}}>
                                    <Box component={'span'} sx={{ fontSize: 18, fontWeight: 'bold', color: '#FFF' }}> {talla} </Box>
                                </Grid>
                                <Grid item xs={6} sx={{ textAlign: 'center' }}>
                                    <Box component={'span'} sx={{ fontSize: 18, fontWeight: 'bold', color: PRIMARYCOLOR }}> {cantidades[talla]} </Box>
                                </Grid>
                            </Grid>
                })}
               
            </Grid>
        </Grid>
    )
}

const UniformesTotales = ({dataTotales, depto=''}) => {
    const [activeTab, setActiveTab] = useState(0);
    const [totales, setTotales] = useState(null);
    var logo = LOGO
    if(depto === 'Mobil')
        logo = 'https://imagenespublicas.s3.us-east-2.amazonaws.com/Logos/Mobil.png'
    if(depto === 'Llantas y Urea')
        logo = 'https://imagenespublicas.s3.us-east-2.amazonaws.com/Logos/lubtracLlantas.png'
    else if(depto === 'Molykote')
        logo = 'https://imagenespublicas.s3.us-east-2.amazonaws.com/Logos/Molykote.png'
    
    useEffect(() => {
        setTotales(dataTotales)
    }, [dataTotales])

    if (totales === null)
        return <LoadViewTable />

    return (
        <Box sx={{ width: '100%', textAlign: 'center' }}>
            {/* Box para la imagen del logo*/}
            <Box
                component="img"
                sx={{ height: 75, marginTop: 2 }}
                alt="No se cuenta con fotografia"
                src={logo}/>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={activeTab} onChange={(event, newValue) => { setActiveTab(newValue); }} aria-label="basic tabs example">
                <Tab label="Hombres" {...a11yProps(0)} />
                <Tab label="Mujeres" {...a11yProps(1)} />
                </Tabs>
            </Box>
            <CustomTabPanel value={activeTab} index={0}>
                <Grid container spacing={2}>
                    <ItemDetalle imagen={camisa} titulo="Camisa" cantidades={totales.masculino.camisa} tallas={Object.keys(totales.masculino.camisa)} />
                    <ItemDetalle imagen={polo} titulo="Polo" cantidades={totales.masculino.polo}  tallas={Object.keys(totales.masculino.polo)} />
                    <ItemDetalle imagen={pantalones} titulo="Pantalones" cantidades={totales.masculino.pantalon} tallas={Object.keys(totales.masculino.pantalon)} />
                    <ItemDetalle imagen={botas} titulo="Botas" cantidades={totales.masculino.botas} tallas={Object.keys(totales.masculino.botas)} />
                </Grid>
            </CustomTabPanel>
            <CustomTabPanel value={activeTab} index={1}>
                <Grid container spacing={2}>
                    <ItemDetalle imagen={camisa} titulo="Camisa" cantidades={totales.femenino.camisa} tallas={Object.keys(totales.femenino.camisa)} />
                    <ItemDetalle imagen={polo} titulo="Polo" cantidades={totales.femenino.polo}  tallas={Object.keys(totales.femenino.polo)} />
                    <ItemDetalle imagen={pantalones} titulo="Pantalones" cantidades={totales.femenino.pantalon} tallas={Object.keys(totales.femenino.pantalon)} />
                    <ItemDetalle imagen={botas} titulo="Botas" cantidades={totales.femenino.botas} tallas={Object.keys(totales.femenino.botas)} />
                </Grid>
            </CustomTabPanel>
        </Box>
    )
}

export default UniformesTotales