import { useContext } from "react";
import { DocumentContext } from "../contexts/contexts";


/**
* Hook personalizado que proporciona acceso al contexto del fondo.
* @typedef {Object}  documentsParams Control
* @property {object} documentSelect - Datos .
* @property {function} setDocumentSelect - Función para guardad los datos seleccionados.
* @property {function} startReload - Función para iniciar la recarga .
* @property {boolean} reload - controla la recarga.
* @property {function}  setOpenDialog - Función para abrir modal.
* @property {boolean} openDialog - .
* @property {function} setDocumentEdit 
* @property {boolean} documentEdit 
*/
/**
*
* @returns {documentsParams} - Control de vacantes.
*/
export function useDocumentsContext() {
   return useContext(DocumentContext)
}
