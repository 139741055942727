import { useCallback, useMemo, useState } from "react"
import Box from "@mui/material/Box"
import Tab from "@mui/material/Tab"
import TabContext from "@mui/lab/TabContext"
import TabList from "@mui/lab/TabList"
import TabPanel from "@mui/lab/TabPanel"

export default function Tabs({ tabs = [], onChange = () => {}, value, setValue }) {
	const [valueControl, setValueControl] = useState(0)

	const setFunction = useMemo(() => {
		if (setValue) return setValue
		else return setValueControl
	}, [setValue])

	const handleChange = useCallback(
		(event, newValue) => {
			setFunction(newValue)
			onChange(newValue)
		},
		[onChange, setFunction],
	)

	return (
		<Box sx={{ width: "100%", typography: "body1" }}>
			<TabContext value={value || valueControl}>
				<Box sx={{ borderBottom: 1, borderColor: "divider" }}>
					<TabListItems
						tabs={tabs}
						handleChange={handleChange}
					/>
				</Box>
				<TabPanelItems tabs={tabs} />
			</TabContext>
		</Box>
	)
}

function TabListItems({ tabs, handleChange }) {
	tabs = tabs.filter((tab) => !!tab?.show)
	const items = tabs.map((tab, index) => (
		<Tab
			key={tab?.title}
			label={tab?.title}
			value={index}
		/>
	))
	return (
		<TabList
			onChange={handleChange}
			variant="scrollable"
			allowScrollButtonsMobile
			scrollButtons
		>
			{items}
		</TabList>
	)
}

function TabPanelItems({ tabs }) {
	tabs = tabs.filter((tab) => !!tab?.show)
	const items = tabs.map((tab, index) => (
		<TabPanel
			key={`panel-${tab?.title}`}
			value={index}
		>
			{tab?.component}
		</TabPanel>
	))
	return items
}
