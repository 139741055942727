import ButtonFile from "#common/buttons/ButtonFile"
import { MasterDialog } from "#common/Dialog/Dialog"
import { useDocumentsContext } from "#documents-society/hooks/context"
import {
	documentDelete,
	documentPut,
	documentsPost,
} from "#documents-society/services/Api"
import { useApiSend } from "#hooks/useApiSend"
import { Stack, TextField } from "@mui/material"
import Typography from "@mui/material/Typography"
import { useCallback, useEffect, useMemo, useState } from "react"
import { toast } from "react-toastify"
const defaultData = { id: null, name: "", category: "" }
const DocumentDialog = () => {
	const [disabled, setDisabled] = useState()
	const { startReload, setOpenDialog, openDialog, documentEdit, setDocumentEdit } =
		useDocumentsContext()
	const [sendData, setSendData] = useState(defaultData)
	const handleClose = () => {
		setDocumentEdit(null)
		setSendData(defaultData)
	}
	const success = () => {
		startReload()
		setOpenDialog(false)
		handleClose()
		setDisabled(false)
	}
	const { fetch } = useApiSend(documentEdit ? documentPut : documentsPost, { success })
	const { fetch: fetchDelete } = useApiSend(documentDelete, { success })

	useEffect(() => {
		if (documentEdit?.name)
			setSendData({
				name: documentEdit?.name,
				category: documentEdit?.category,
			})
	}, [documentEdit?.category, documentEdit?.name])

	const handleSend = useCallback(() => {
		setDisabled(true)
		const formData = new FormData()
		if (!sendData?.name) {
			toast.error("Se requiere un nombre")
			return
		}
		formData.append("name", sendData?.name)
		if (!sendData?.category) sendData.category = ""
		formData.append("category", sendData?.category)

		if (!documentEdit?.path && !sendData?.document) {
			toast.error("Se requiere un documento")
			return
		} else formData.append("document", sendData?.document)

		if (documentEdit) fetch(documentEdit.id, formData)
		else fetch(formData)
	}, [documentEdit, fetch, sendData])

	const handleDelete = useCallback(() => {
		setDisabled(true)
		fetchDelete(documentEdit?.id)
	}, [documentEdit?.id, fetchDelete])

	const buttons = useMemo(() => {
		const buttons = [{ label: "Guardar", onClick: handleSend, disabled }]
		if (documentEdit?.id)
			buttons.unshift({
				label: "eliminar",
				onClick: handleDelete,
				disabled,
			})
		return buttons
	}, [disabled, documentEdit?.id, handleDelete, handleSend])
	return (
		<MasterDialog
			title={documentEdit ? "Editar" : "Nuevo Documento"}
			open={openDialog}
			setOpen={setOpenDialog}
			closeDialog={handleClose}
			buttons={buttons}
		>
			<Stack spacing={2}>
				<TextField
					{...{ disabled }}
					label="Nombre"
					onClick={() => setOpenDialog(true)}
					value={sendData?.name}
					onChange={(event) => {
						setSendData((old) => ({
							...old,
							name: event.target.value,
						}))
					}}
					fullWidth
				></TextField>
				<TextField
					{...{ disabled }}
					label="Categoría"
					onClick={() => setOpenDialog(true)}
					value={sendData?.category}
					onChange={(event) => {
						setSendData((old) => ({
							...old,
							category: event.target.value,
						}))
					}}
					fullWidth
				/>
				<div>
					<Typography
						textAlign="center"
						width="100%"
						variant="caption"
						display="block" // Asegura que el Typography ocupe toda la anchura
						sx={{
							whiteSpace: "nowrap", // Evita que el texto se divida en varias líneas
							overflow: "hidden", // Oculta el texto que no cabe
							textOverflow: "ellipsis", // Muestra "..." al final si el texto es muy largo
						}}
					>
						{sendData?.document?.name}
					</Typography>
					<ButtonFile
						label="Buscar Archivo"
						disabled={disabled}
						fullWidth
						getFiles={(value) => {
							setSendData((old) => ({
								...old,
								[`document`]: value[0],
							}))
						}}
					/>
				</div>
			</Stack>
		</MasterDialog>
	)
}
export default DocumentDialog
