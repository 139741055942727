import Box from "@mui/material/Box"
import { ChangeTitle } from "Components/menu/funciones"
import DocumentDialog from "#documents-society/components/DocumentDialog"
import DocumentsList from "#documents-society/components/DocumentsList"
import DocumentViewPDF from "#documents-society/components/DocumentViewPdf"
import { DocumentProvider } from "#documents-society/contexts/contexts"

const Index = () => {
	ChangeTitle("Documentación")

	return (
		<DocumentProvider>
			<Box
				display="flex"
				width="100%"
				flexDirection="row"
				gap={2}
			>
				<div style={{ flex: 1 }}>
					<DocumentsList />
				</div>
				<div style={{ flex: 3.2, height: "87vh" }}>
					<DocumentViewPDF />
				</div>
				<DocumentDialog />
			</Box>
		</DocumentProvider>
	)
}

export default Index
