import { UseGetAreas } from "Hooks/areas/getAreas"
import Autocomplete from "@mui/material/Autocomplete"
import { Fragment, useEffect, useMemo, useState } from "react"
import { TextField } from "@mui/material"
import CircularProgress from "@mui/material/CircularProgress"
/**
 *
 * @param {*} props
 * @param {boolean} props.manager
 * @param {number} props.value
 * @param {boolean}props.disabled
 * @returns
 */
const InputSelectAreas = (props) => {
	const { areas, loading: loadingGet } = UseGetAreas(!props?.data, {
		manager: props?.manager,
	})
	const data = useMemo(() => props.data ?? areas, [areas, props.data])
	const loading = useMemo(() => {
		if (props?.data) return props?.loading ?? false

		return loadingGet
	}, [loadingGet, props?.data, props?.loading])
	const [selectedDepartamento, setSelectedDepartamento] = useState(null)

	useEffect(() => {
		data.forEach((element) => {
			if (props.value === element.id) setSelectedDepartamento(element)
		})
	}, [data, props.value])

	return (
		<Autocomplete
			disabled={props.disabled || loading}
			value={selectedDepartamento} // El valor seleccionado
			onChange={(event, newValue) => {
				if (props.setValue) props.setValue(newValue.id)
				setSelectedDepartamento(newValue)
			}}
			options={data}
			loading={loading}
			getOptionLabel={(option) => option.description}
			renderInput={(params) => (
				<TextField
					{...params}
					label="Departamento"
					slotProps={{
						input: {
							...params.InputProps,
							endAdornment: (
								<Fragment>
									{loading ? (
										<CircularProgress
											color="inherit"
											size={20}
										/>
									) : null}
									{params.InputProps.endAdornment}
								</Fragment>
							),
						},
					}}
				/>
			)}
		/>
	)
}

export default InputSelectAreas
