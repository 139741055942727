
import { useApiSend } from '#hooks/useApiSend';
import { useReloadPerfilesPuesto } from '#perfilesPuesto/redux/slice';
import { perfilesIdDelete } from '#perfilesPuesto/Services/api';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography, } from '@mui/material';
import { useState } from 'react';
import { MasterDialog } from './Dialog/Dialog';


export const ModalConfirmacion = ({ open, setOpen, message, actionApi, perfilID }) => {
    const [loading,setLoading] = useState(false)
    const [cancel,setCancel] = useState()
    const reload=useReloadPerfilesPuesto()
    const success=()=>{
        reload()
        setOpen(false)
    }
    const end=()=>{setLoading(false)}
    const {fetch}=useApiSend(perfilesIdDelete,{success,end})

    const confirm = () => {
        setLoading(true)
       const fn= fetch(perfilID)
       setCancel(()=>fn)
    }

    return (
        <MasterDialog {...{open,setOpen}} maxWidth={'sm'} fullWidth={true} 
        title={"Confirmación"}
        buttons={[{ label: "Cancelar", onClick:()=> {
            cancel()
            setOpen(false)
        } },
            { label: "Aceptar", onClick:() => confirm(),disabled:loading },

        ]}
        >
            <Typography >
                {message}
            </Typography>

        {/* <Dialog open={open} maxWidth={'sm'} fullWidth={true} >
            <DialogTitle sx={{ fontSize: 24, fontWeight: 'bold', color: "#555" }}>Confirmación</DialogTitle>
            <DialogContent>
            </DialogContent>
            <DialogActions>
                <Button disabled={loading} onClick={() => setOpen(false)}>Cancelar</Button>
                <Button disabled={loading} onClick={() => confirm()}>Confirmar</Button>
            </DialogActions>
        </Dialog> */}
        </MasterDialog>
    )
}

export const ModalConfirmacion2 = ({ open, setOpen, message, action, loading }) => {
    return (
        <Dialog open={open} maxWidth={'sm'} fullWidth={true} >
            <DialogTitle sx={{ fontSize: 24, fontWeight: 'bold', color: "#555" }}>Confirmación</DialogTitle>
            <DialogContent>
                {message}
            </DialogContent>
            <DialogActions>
                <Button disabled={loading} onClick={() => setOpen(false)}>Cancelar</Button>
                <Button disabled={loading} onClick={action()}>Confirmar</Button>
            </DialogActions>
        </Dialog>
    )
}

export function DialogConfirm({ open, setOpen, message,disabledButtons ,accept = () => {} }) {
	return (
		<Dialog
			open={open}
			onClose={() => setOpen(false)}
		>
			<DialogTitle id="alert-dialog-title">{message}</DialogTitle>
			<DialogActions sx={{ alignContent: "center", justifyContent: "center" }}>
				<Button disabled={disabledButtons} onClick={() => setOpen(false)}>Cancelar</Button>
				<Button
                disabled={disabledButtons}
					autoFocus
					onClick={accept}
				>
					Aceptar
				</Button>
			</DialogActions>
		</Dialog>
	)
}