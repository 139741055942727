import { useReduxUser } from "app/userSlice"
import { Route, Routes } from "react-router-dom"
import { rutasItems } from "./ListURL"
import HasPermission from "#functions/HasPermission"
import { MODULES } from "#constants/modules"
function Rutas() {
	const moduleAccess = useReduxUser().moduleAccess

	return (
		<Routes>
			{Object.keys(rutasItems).map((ruta) => {
				return rutasItems[ruta].map((item) => {
					if (
						ruta !== "rutas" &&
						!moduleAccess.includes(MODULES.ALL) &&
						!moduleAccess.includes(item.module)
					)
						return null

					if (item?.denied?.length) if (HasPermission(...item.denied, true)) return null

					if (item?.permissions?.length)
						if (!HasPermission(...item.permissions)) return null
					if (item?.subPath) {
						return (
							<Route
								key={item.path}
								{...item}
							>
								<Route
									path={`:${item.subPath}`}
									element={<></>}
								></Route>
							</Route>
						)
					}
					return (
						<Route
							key={item.path}
							{...item}
						/>
					)
				})
			})}
		</Routes>
	)
}

export default Rutas
