import { PERFILES } from "../../Constants/Strings"
//rutas
import Capacitaciones from "../../Pages/Capacitaciones"
// import Colaboradores from "../../Pages/Colaboradores"
import Documentacion from "../../Pages/Documentacion"
// import PerfilesPuesto from "../../Pages/PerfilesPuesto"
import PrestamosSolicitudes from "../../Pages/PrestamosSolicitudes"
import SolicitudesCajaAhorro from "../../Pages/SolicitudesCajaAhorro"
import TuPerfil from "../../Pages/TuPerfil"
import Uniformes from "../../Pages/Uniformes"
import Cursos from "../Capacitaciones/Cursos"
import CursosPorAprobar from "../Capacitaciones/CursosPorAprobar"
import MatrizCursos from "../Capacitaciones/MatrizCursos"
import FondoFijo from "../FondoFijo/FondoFijo"
import Pagos from "../pagos/Pagos"
import Ruta404 from "./Ruta404"
import VacationBoss from "Components/vacation/VacationBoss"

//Icons
import AirplaneTicketIcon from "@mui/icons-material/AirplaneTicket"
import AssignmentTurnedInIcon from "@mui/icons-material/AssignmentTurnedIn"
import CheckroomIcon from "@mui/icons-material/Checkroom"
import CurrencyExchangeIcon from "@mui/icons-material/CurrencyExchange"
import DescriptionIcon from "@mui/icons-material/Description"
import LibraryBooksIcon from "@mui/icons-material/LibraryBooks"
import MoneyIcon from "@mui/icons-material/Money"
import PeopleIcon from "@mui/icons-material/People"
import PermContactCalendarIcon from "@mui/icons-material/PermContactCalendar"
import PersonAddIcon from "@mui/icons-material/PersonAdd"
import PointOfSaleIcon from "@mui/icons-material/PointOfSale"
import PostAddIcon from "@mui/icons-material/PostAdd"
import RecentActorsIcon from "@mui/icons-material/RecentActors"
import SavingsIcon from "@mui/icons-material/Savings"
import SolicitudesBajas from "Components/bajas/SolicitudesBajas"
import PersonOffIcon from "@mui/icons-material/PersonOff"
import Promotions from "Components/promotions/Promotions"
import Vacantes from "Components/Vacantes/pages/Vacantes"
import Colaboradores from "Components/Colaboradores/pages/Colaboradores"
import { MODULES } from "#constants/modules"
import PerfilesPuesto from "#perfilesPuesto/pages/Index"
import PERMISOS from "#constants/perfiles"
import { URLS as DOCUMENTS_URL } from "modules/documents-society/urls"

const {
	// ...RH_ALL,
	SISTEMAS,
	GERENTE,
	JEFATURA,
	PAGOS,
	COMPRAS,
	CONT,
	GUATEMALA,
	GRTE_GUAT,
	CONTABILIDAD,
	MANTENIMIENTO,
	JEFE_NOMINAS,
	GRTE_RH,
} = PERFILES
const { RH_ALL } = PERMISOS
const iconStyle = { sx: { color: "#0D79D2" } }

export const rutasItems = {
	rutas: [
		//!siempre debe ser el primer item los que no tiene que aparecer en el menu
		{
			path: "/",

			element: <TuPerfil />,
		},
		{
			path: "perfil",
			element: <TuPerfil />,
		},
		{
			path: "*",
			element: <Ruta404 />,
		},
	],
	Colaboradores: [
		{
			path: "colaboradores",
			title: "Colaboradores",
			module: MODULES.COLABORADOR,
			element: <Colaboradores />,
			icon: <PeopleIcon {...iconStyle} />,
			permissions: [...RH_ALL, SISTEMAS],
			subPath: "no_empleado",
		},
		{
			path: "vacantes",
			title: "Vacantes",
			module: MODULES.VACANTES,
			element: <Vacantes />,
			permissions: [...RH_ALL, GERENTE, JEFATURA],
			icon: <PersonAddIcon {...iconStyle} />,
			notification: "requerimientos",
			subPath: "vacanteId",
		},
		{
			path: "promociones",
			title: "Promociones",
			module: MODULES.PROMOTIONS,
			element: <Promotions />,
			permissions: [...RH_ALL, GERENTE, JEFATURA],
			icon: <PersonAddIcon {...iconStyle} />,
			subPath: "promotionsId",
		},
		{
			path: "perfiles",
			module: MODULES.PERFILES_PUESTO,
			element: <PerfilesPuesto />,
			title: "Perfiles de Puesto",
			icon: <PermContactCalendarIcon {...iconStyle} />,
			permissions: [...RH_ALL, GERENTE],
			subPath: "perfilPuestoId",
		},
		{
			path: "bajas",
			module: MODULES.BAJAS,

			title: "Bajas de Colaborador",
			element: <SolicitudesBajas />,
			icon: <PersonOffIcon {...iconStyle} />,
			permissions: [
				...RH_ALL,
				CONTABILIDAD,
				SISTEMAS,
				GERENTE,
				MANTENIMIENTO,
				JEFE_NOMINAS,
			],
			notification: "bajas",
		},
	],
	Capacitaciones: [
		{
			path: "cursos",
			module: MODULES.CURSOS,
			title: "Mis Cursos",
			element: <Capacitaciones />,
			icon: <LibraryBooksIcon {...iconStyle} />,
			textDivider: "Capacitaciones",
			permissions: [],
		},
		{
			module: MODULES.CURSOS,
			path: "cursos-lista",
			title: "Catálogo Cursos",
			element: <Cursos />,
			icon: <PostAddIcon {...iconStyle} />,
			permissions: [...RH_ALL, GERENTE],
		},
		{
			module: MODULES.CURSOS,
			path: "cursos-aprobar",
			title: "Curso por Aprobar",
			element: <CursosPorAprobar />,

			icon: <AssignmentTurnedInIcon {...iconStyle} />,
			permissions: [...RH_ALL],
			notification: "curso_por_Aprobar",
		},
		{
			module: MODULES.CURSOS,
			path: "curso-colaboradores",
			title: "Matriz de Cursos",
			element: <MatrizCursos />,
			icon: <RecentActorsIcon {...iconStyle} />,
			permissions: [...RH_ALL, GERENTE],
		},
	],
	Solicitudes: [
		{
			module: MODULES.PRESTAMOS,
			path: "prestamos",
			title: "Prestamos",
			element: <PrestamosSolicitudes />,
			icon: <MoneyIcon {...iconStyle} />,
			permissions: [...RH_ALL, GERENTE],
			denied: [GUATEMALA],
			textDivider: "Solicitudes",
		},
		{
			module: MODULES.CAJA_AHORRO,
			path: "caja-ahorro",
			title: "Caja Ahorro",
			element: <SolicitudesCajaAhorro />,
			icon: <SavingsIcon {...iconStyle} />,
			denied: [GUATEMALA],
			permissions: [...RH_ALL],
			notification: "caja_ahorro",
		},
		{
			module: MODULES.FONDO_FIJO,
			path: "fondo-fijo",
			title: "Fondo Fijo",
			element: <FondoFijo />,
			icon: <PointOfSaleIcon {...iconStyle} />,
			permissions: [...RH_ALL, PAGOS, CONT, GERENTE],
			denied: [GUATEMALA],
		},
		{
			module: MODULES.UNIFORMES,
			path: "uniformes",
			title: "Uniformes",
			element: <Uniformes />,
			icon: <CheckroomIcon {...iconStyle} />,
			permissions: [...RH_ALL, COMPRAS],
			denied: [GUATEMALA],
		},
		{
			module: MODULES.VACACIONES,
			path: "vacaciones",
			title: "Vacaciones",
			element: <VacationBoss />,
			icon: <AirplaneTicketIcon {...iconStyle} />,
			permissions: [...RH_ALL, GRTE_GUAT],
		},
	],
	Contabilidad: [
		{
			module: MODULES.PAGOS,
			path: "pagos",
			title: "Pagos",
			element: <Pagos />,
			icon: <CurrencyExchangeIcon {...iconStyle} />,
			permissions: [PAGOS, GRTE_RH],
			textDivider: "Información Lubtrac",
			notification: "pagos",
		},
	],
	...DOCUMENTS_URL,
}
