import { COLOR_THIRD } from "#constants/Styles"
import { Add } from "@mui/icons-material/"
import { IconButton } from "@mui/material"

import { LoadViewTable } from "#common/LoadingView"
import { useApi } from "#hooks/useApi"
import MUIDataTable from "mui-datatables"
import { useMemo, useState } from "react"
import { useReduxVacantes } from "#vacantes/redux/slice"
import { vacantesGet } from "#vacantes/services/Api"
import DialogNewVacante from "#vacantes/DialogNewVacante"

const TableVacantes = ({ onClick, showDay = true, activas = true }) => {
	const { reloadTable } = useReduxVacantes()
	const params = useMemo(() => ({ activas }), [activas])
	const {
		data: vacantes,
		loading,
		error,
	} = useApi(vacantesGet, { autoFetch: true, params, reload: reloadTable })
	const [openModal, setOpenModal] = useState(false)
	const columns = [
		{ name: "id", label: "id", options: { display: false, filter: false, sort: true } },
		{ name: "id", label: "#", options: { filter: false, sort: true } },
		{
			name: "sucursal__nombre",
			label: "Sucursal",
			options: { filter: false, sort: true },
		},
		{
			name: "perfil_puesto__nombre",
			label: "Perfil Vacante",
			options: { filter: false, sort: true },
		},
		{
			name: "fecha_solicitud",
			label: "Fecha Solicitud",
			options: { filter: true, sort: true },
		},
		{
			name: "status__descripcion",
			label: "Status",
			options: { filter: true, sort: true },
		},
		{
			name: "last_modification",
			label: "Modificación",
			options: {
				filter: true,
				sort: true,
				display: showDay,
				customBodyRender: (value) => {
					let color = "#A91A1A"
					let txt = COLOR_THIRD

					if (value < 7) {
						color = "#266818"
					}

					return (
						<div
							style={{
								position: "relative",
								textAlign: "center",
								display: "flex",
								justifyContent: "center",
								alignItems: "center",
							}}
						>
							<div
								style={{
									width: "50px",
									borderRadius: 5,
									color: txt,
									fontWeight: "bold",
									backgroundColor: color,
								}}
							>
								{value}
							</div>
						</div>
					)
				},
				setCellProps: () => ({
					style: {
						textAlign: "center",
					},
				}),
			},
		},
	]
	const options = {
		selectableRowsHideCheckboxes: true,
		print: false,
		viewColumns: false,
		textLabels: { body: { noMatch: "No hay vacantes activas" } },
		onRowClick: (rowData, { dataIndex }) => {
			onClick(vacantes[dataIndex])
		},
		customToolbar: () => {
			return (
				<IconButton
					// style={{ order: -1 }}
					onClick={() => setOpenModal(true)}
				>
					<Add />
				</IconButton>
			)
		},
	}

	if (loading || error) return <LoadViewTable />
	return (
		<>
			<MUIDataTable
				title={""}
				data={vacantes}
				columns={columns}
				options={options}
				sx={{ with: 400 }}
			/>
			<DialogNewVacante
				open={openModal}
				setOpen={setOpenModal}
			/>
		</>
	)
}

export default TableVacantes
