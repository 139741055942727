import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux" //Token
import { actualizarNotify } from "../app/notificationSlice"
import { ESTADOS } from "../Components/Colaboradores/Solicitudes/constants"
import {
	GetCajaAhorroDoc,
	getCajaAhorro,
	getStatusCajaAhorro,
} from "../Services/Api_solicitudes"

import DownloadIcon from "@mui/icons-material/Download"
import SkeletoCajaAhorro from "../Components/Colaboradores/Solicitudes/CajaAhorro/SkeletoCajaAhorro"
import TablaCajaAhorro from "../Components/Colaboradores/Solicitudes/CajaAhorro/TablaCajaAhorro"

//Material UI
import Grid from "@mui/material/Unstable_Grid2"
import { ChangeTitle } from "Components/menu/funciones"
import { ButtonGrid2 } from "../Components/Common/Buttons"
import { InputSelectGrid2 } from "../Components/Common/Inputs2"

function SolicitudesCajaAhorro() {
	const dispatch = useDispatch()
	const token = useSelector((state) => state.user.token)
	const [db_cajaAhorro, setdb_cajaAhorro] = useState([])
	const [db_statusCajaAhorro, setdb_statusCajaAhorro] = useState([])

	const [status, setStatus] = useState(ESTADOS["REVISAR"])

	const [skeleto0, setSkeleto0] = useState(false)
	const [skeleto1, setSkeleto1] = useState(false)
	const [actualizar, setActualizar] = useState(true)

	ChangeTitle("Caja de Ahorro")
	useEffect(() => {
		getStatusCajaAhorro(token, setdb_statusCajaAhorro, setSkeleto1)
	}, [token])

	useEffect(() => {
		setSkeleto0(false)
	}, [status])

	useEffect(() => {
		getCajaAhorro(token, setdb_cajaAhorro, `?status=${status}`, setSkeleto0)
		dispatch(actualizarNotify(token))
	}, [token, actualizar, status, dispatch])

	return (
		<React.Fragment>
			{!skeleto1 && <SkeletoCajaAhorro />}

			{skeleto1 && (
				<Grid
					container
					spacing={1}
					justifyContent={"center"}
				>
					<Grid
						xs={12}
						container
						justifyContent={"center"}
					>
						<ButtonGrid2
							label="Descargar Calculo"
							style={{ height: "100%", display: "flex" }}
							startIcon={<DownloadIcon />}
							variant="outlined"
							onClick={() => {
								GetCajaAhorroDoc(token, { resumen: "" })
							}}
						/>
						<InputSelectGrid2
							label="Estado"
							data={db_statusCajaAhorro}
							dataLabel="descripcion"
							dataID="id"
							value={status}
							setValue={setStatus}
							all="Todas las Solicitudes"
							GridProps={{ xs: 4 }}
						/>
					</Grid>

					{skeleto0 ? (
						<React.Fragment>
							<TablaCajaAhorro
								db_cajaAhorro={db_cajaAhorro}
								rh={true}
								actualizar={actualizar}
								setActualizar={setActualizar}
								opciones={status}
								size={11}
							/>
						</React.Fragment>
					) : (
						<SkeletoCajaAhorro size={9} />
					)}
				</Grid>
			)}
		</React.Fragment>
	)
}

export default SolicitudesCajaAhorro
