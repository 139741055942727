import axios from "axios"
import { useCallback, useEffect, useState } from "react"
/**
 * @typedef {Object} UseApiReturn
 * @property {any} data - Los datos devueltos por la API. Será `null` si la llamada no ha sido exitosa o no se ha realizado.
 * @property {boolean} loading - Indica si la llamada a la API está en progreso.
 * @property {any} error - El error capturado durante la llamada a la API. Será `null` si no hay errores.
 * @property {function} fetch - Una función para realizar manualmente la llamada a la API.
 */

/**
 * Un hook personalizado para manejar llamadas a APIs de manera sencilla.
 *
 * @param {function} apiCall - Una función que recibe parámetros y retorna un objeto con dos propiedades:
 *                             - `call`: Una promesa que realiza la llamada a la API.
 *                             - `controller`: Una instancia de `AbortController` para cancelar la solicitud.
 * @param {Object} options - Opciones de configuración para el hook.
 * @param {boolean} [options.autoFetch=false] - Si es `true`, la llamada a la API se realiza automáticamente al montar el componente.
 * @param {boolean} [options.reload=false] - Si cambia su Valor realiza un reload.
 * @param {any} [options.params] - Parámetros que se pasarán a la función `apiCall` cuando se realice la llamada automática.
 *
 * @returns {UseApiReturn}
 */
export const useApi = (apiCall, options) => {
	const [loading, setLoading] = useState(true)
	const [data, setData] = useState(null)
	const [error, setError] = useState(null)

	const fetch = useCallback(
		(param) => {
			const { call, controller } = apiCall(param)
			setLoading(true)
			setError(null)
			call
				.then((response) => {
					setData(response.data.data)
					setError(null)
					setLoading(false)
				})
				.catch((err) => {
					if (axios.isCancel(err)) return
					setError(err)
					setLoading(false)
				})
			return () => controller.abort()
		},
		[apiCall],
	)

	useEffect(() => {
		if (options?.autoFetch) {
			return fetch(options.params)
		}
	}, [fetch, options?.autoFetch, options?.params, options?.reload])

	return { loading, data, error, fetch }
}
