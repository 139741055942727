import { apiGetRH } from "#api/API_RH"
import { useEffect, useState } from "react"

export const UseGetAreas = (activar, options = {}) => {
    const  {manager = false,reload=false,queryParams = {}}  = options
    const [areas, setAreas] = useState([])
    const [loading, setLoading] = useState(true)
    const{collaborators,annual_plan} = queryParams
    useEffect(() => {
        setLoading(true)
        const success = (data) => {
            setAreas(data?.areas)
            setLoading(false)
        }

        if (!activar) return
        
        if (manager) apiGetRH("areas/manager", { success,params:{collaborators,annual_plan} })
        else apiGetRH("areas", { success })
    }, [activar, annual_plan, collaborators, manager,reload])

    return { areas, loading }
}
