import ButtonDialog from '#common/Dialog/ButtonsDialog';
import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import PendingIcon from '@mui/icons-material/Pending';
import { Box, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, IconButton, Stack, Tooltip, Typography } from '@mui/material';
import { useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { GREENCOLOR, REDCOLOR } from '../../Constants/Styles';
import { authorizationVacante } from '../../Services/Api';
import { reloadDataVacante } from '../../app/pageSlice';
import { useVacanteContext } from './VacantesContext';

const ModalVoBo =  ({open, setOpen, autorizaciones, idVacante}) => {
    const [openConfirm,setOpenConfirm] = useState(false)
    const [authorization,setAuthorization]    = useState(null)
    const [colabVobo,setColabVobo]     = useState(null)
    const colaborador = useSelector(state => state.user.colaborador.id);
    const profiles = useSelector(state => state.user.profiles);

    let hasPerm = profiles.filter(userProfile => userProfile === 'RH' || userProfile === 'Admin').length
    let isRH = hasPerm > 0 ? true : false
    let modalSize = 'lg' 
    let gridSize = 3
    if(autorizaciones.length < 3){
        modalSize = 'xs'
        gridSize = 12 / autorizaciones.length 
    }
    if(autorizaciones.length === 3)
        gridSize = 4
    if(autorizaciones.length === 4)
        gridSize = 3

    const modalConfirmation = (authorization, colaborador) => {
        setOpenConfirm(true)
        setAuthorization(authorization)  
        setColabVobo(colaborador)  
    }

    if(!open)
        return
    return(
        <Dialog open={open} maxWidth={modalSize} fullWidth={true}  onClose={()=>setOpen(false)}>
            <DialogTitle sx={{fontSize:24,fontWeight:'bold',color: "#555",pb:0}}>VoBo's </DialogTitle>
            <DialogContent>
                <Grid container sx={{pt:0}} spacing={3}>
                    {autorizaciones.map(autorizacion => {
                        var content = <></>
                        if (autorizacion.fecha_autorizacion == null && (isRH || autorizacion.autorizacion === colaborador ))
                            content = <>
                                        <Typography sx={{ fontSize: 18, color: "#555", textAlign: 'center'}}>Dar Autorización </Typography>
                                        <Box > 
                                            <Tooltip title="Dar VoBo.">
                                                <IconButton color="success" size="large" onClick={() => modalConfirmation(true,autorizacion.id)}> <CheckCircleIcon fontSize="inherit" /> </IconButton>
                                            </Tooltip>
                                            <Tooltip title="Rechazar">
                                                <IconButton color="error" size="large" onClick={() => modalConfirmation(false,autorizacion.id)}> <CancelIcon fontSize="inherit" /> </IconButton>
                                            </Tooltip>
                                        </Box>
                                    </>
                        else if(autorizacion.fecha_autorizacion == null)
                            content = <>
                                        <Typography sx={{fontSize:18,color: "#555",textAlign:'center',mb:2}}> Pendiente </Typography>
                                        <PendingIcon/>
                                    </>
                        else
                            content = <>
                                        <Typography sx={{fontSize:18,color: "#555",textAlign:'center',mb:2}}> {autorizacion.fecha_autorizacion} </Typography>
                                        {autorizacion.autorizado === true ? 
                                             <><CheckCircleIcon color={'success'} /><Typography sx={{ fontSize: 18, color: GREENCOLOR, textAlign: 'center', fontWeight:'bold' }}> Autorizado </Typography></> :
                                             <><CancelIcon color={'error'} /><Typography sx={{ fontSize: 18, color: REDCOLOR, textAlign: 'center', fontWeight:'bold' }}> Rechazado </Typography></> 
                                        }
                                    </>
                        return <Grid item md={gridSize} key={'auto'+autorizacion.id}>
                            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: "center" }}> 
                                <Typography sx={{fontSize:18,fontWeight:'bold',color: "#555",textAlign:'center'}}> {autorizacion.autorizacion_nombre+' '+autorizacion.autorizacion_apellido} </Typography>
                                {content}
                            </Box>
                        </Grid>
                    })}
                    
                </Grid>
                <DialogConfirm open={openConfirm} setOpen={setOpenConfirm} reply={authorization} dataSend={{id:idVacante,requester:colabVobo}}/>
            </DialogContent>
        </Dialog>
    )
}

export default ModalVoBo


const DialogConfirm = ({ open, setOpen, reply, dataSend }) => {
	const dispatch = useDispatch()
    const { startReload } = useVacanteContext()
	const closeDialog = () => {
		setOpen(false)
	}
	const [btnDisabled, setBtnDisabled] = useState(false)
	const mensaje = reply
		? "¿Esta seguro que desea dar VoBo a esta vacante?"
		: "¿Esta seguro que desea rechazar a esta vacante?"
	const autorizar = () => {
		setBtnDisabled(true)
		const success = () => {
            startReload()
			dispatch(reloadDataVacante())
		}
		const end = () => {
			setBtnDisabled(false)
		}

		authorizationVacante(dataSend.id, dataSend.requester, reply, { success, end })
	}

	return (
		<Dialog
			open={open}
			onClose={closeDialog}
		>
			<DialogTitle> {"Confirmación"} </DialogTitle>
			<DialogContent>
				<DialogContentText>{mensaje}</DialogContentText>
			</DialogContent>
			<DialogActions sx={{ alignContent: "center", justifyContent: "center" }}>
				<Stack
					direction={"row"}
					spacing={3}
				>
					<ButtonDialog
						disabled={btnDisabled}
						label={"Cancelar"}
						onClick={closeDialog}
					/>

					<ButtonDialog
						disabled={btnDisabled}
						onClick={autorizar}
						autoFocus
						label={"Aceptar"}
					/>
				</Stack>
			</DialogActions>
		</Dialog>
	)
}