import { MODULES } from "#constants/modules"
import DescriptionIcon from "@mui/icons-material/Description"
import Index from "./pages/Index"
const iconStyle = { sx: { color: "#0D79D2" } }
export const URLS = {
	"Documentación": [
		{
			module: MODULES.DOCUMENTOS,
			path: "Documentation",
			element: <Index />,
			title: "Documentación",
			icon: <DescriptionIcon {...iconStyle} />,
			permissions: [],
			textDivider: "Documentación",
		},
	],
}
