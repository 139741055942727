import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Stack, Typography } from '@mui/material';
import { useCallback, useState } from 'react';
import { useDispatch } from "react-redux";
import { reloadDataVacante } from "../../../app/pageSlice";
import { candidatosPost } from '../../../Services/Api';
import { GridInputText } from '../../Common/Inputs';
import { useVacanteContext } from '../VacantesContext';
import { useVacanteReloadAll } from '#vacantes/redux/actions';

const defaultValue={
    nombre:"",
    telefono:"",
    correo:"",
    cv:""
}

const ModalNewCandidato = ({open, setOpen, vacanteID}) => {
    const reload = useVacanteReloadAll()
    const [btnDisabled,setBtnDisabled] = useState(false)
    const [dataSend,setDataSend] = useState(defaultValue)
    const editData=useCallback((campo,valor)=>setDataSend(old=>({...old,[campo]:valor})),
[])

    const fileChange = (e) => editData("cv",e.target.files && e.target.files.length > 0 ? e.target.files[0]: null);

    const save = () =>{
        setBtnDisabled(true)

        let formData = new FormData();
        formData.append("vacante_id", vacanteID);
        for (const key in dataSend) {
            if (Object.prototype.hasOwnProperty.call(dataSend, key)) {
                const element = dataSend[key];
                formData.append(key, element);
                
            }
        }
        
        const success=()=>{
            reload()
            setOpen(false)

        }
        const end=()=>{
            setBtnDisabled(false)
        }
        candidatosPost(formData,{success,end})
        
    }

    if(!open)
        return
    return(
        <Dialog open={open} maxWidth={'xs'} fullWidth={true} onClose={()=>setOpen(false)}>
            <DialogTitle sx={{fontSize:24,fontWeight:'bold',color: "#555"}}>Nuevo Candidato</DialogTitle>
            <DialogContent>
            <Grid container sx={{ mt: 0 }} spacing={3} justifyContent="center" alignItems="center">
                <GridInputText  label={'Nombre del Candidato'} value={dataSend.nombre} setValue={(v)=>editData("nombre",v)} size={12}/>
                <GridInputText  label={'Telefono'} value={dataSend.telefono} setValue={(v)=>editData("telefono",v)} size={12} typeInput={'number'}/>
                <GridInputText  label={'Correo'} value={dataSend.correo} setValue={(v)=>editData("correo",v)} size={12}/>
                <Grid item md={12} >
                    <Stack  spacing={1} justifyContent="center" alignItems="center">

                    <Button
                        sx={{ mt: 1, mb: 2 }}
                        fullWidth
                        variant="contained"
                        component="label" >
                        Agregar CV
                        <input type="file" hidden onChange={fileChange} accept=".pdf "/>
                    </Button>
                    <Typography sx={{ mt: 12, textAlign: "center" }} variant="span" gutterBottom>
                        {!dataSend?.cv  ? '' : `CV: ${dataSend.cv.name}`}
                    </Typography>
                            </Stack>

                </Grid>
            </Grid>
            </DialogContent>
            <DialogActions sx={{ alignContent: "center", justifyContent: "center" }}>
                <Stack direction={"row"} spacing={3} > 

                <Button onClick={() =>setOpen(false)} disabled={btnDisabled}>Cancelar</Button>
                <Button onClick={() =>save()} disabled={btnDisabled}>Agregar</Button>
                </Stack>
            </DialogActions>
        </Dialog>
    )

}

export default ModalNewCandidato