
import {Grid, Dialog,  DialogContent, DialogTitle, Typography, Box } from '@mui/material'
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineOppositeContent from '@mui/lab/TimelineOppositeContent';
import { Skeleton, Stack } from '@mui/material/';

const ModalHistorial = ({open, setOpen, historial}) => {
    var content = <></>
    if(!open) return
    if(historial.length == [])
        content =   <>
                        <Stack direction="row" spacing={2} sx={{ mb: 1, mt: 3 }} width="100%" justifyContent="center">
                            <Skeleton variant="rectangular" width="33%" height={40} />
                            <Skeleton variant="circular" width={40} height={40} />
                            <Skeleton variant="rectangular" width="33%" height={20} style={{marginTop:10}}/>
                        </Stack>
                        <Stack direction="row" spacing={2} sx={{ mb: 1, mt: 3 }} width="100%" justifyContent="center">
                            <Skeleton variant="rectangular" width="33%" height={40} />
                            <Skeleton variant="circular" width={40} height={40} />
                            <Skeleton variant="rectangular" width="33%" height={20} style={{ marginTop: 10 }} />
                        </Stack>
                        <Stack direction="row" spacing={2} sx={{ mb: 1, mt: 3 }} width="100%" justifyContent="center">
                            <Skeleton variant="rectangular" width="33%" height={40} />
                            <Skeleton variant="circular" width={40} height={40} />
                            <Skeleton variant="rectangular" width="33%" height={20} style={{ marginTop: 10 }} />
                        </Stack>
                    </>
    else    
        content = <>
            <Grid container sx={{ mt: 0 }} spacing={3}>
                <Timeline position="left">
                    {historial.map((log, index) => {
                        return <TimelineItem key={'hist' + index}>
                            <TimelineOppositeContent
                                sx={{ m: 'auto 0' }}
                                variant="body2"
                                color="text.secondary" >
                                {log.fecha}
                            </TimelineOppositeContent>
                            <TimelineSeparator>
                                <TimelineConnector />
                                {log.icon}
                                <TimelineConnector />
                            </TimelineSeparator>
                            <TimelineContent sx={{ py: '12px', px: 2 }}>
                                <Typography variant="h6" component="span" fontWeight={'bolder'} fontSize={18}>
                                  {log.autorizacion_nombre + ' ' + log.autorizacion_apellido}
                                </Typography>
                                <Typography> {log.descripcion} </Typography>
                            </TimelineContent>
                        </TimelineItem>
                    })}
                </Timeline>
            </Grid>
        </>
    return(
        <Dialog open={open} maxWidth={'md'} fullWidth={true}  onClose={()=>setOpen(false)}>
            <DialogTitle sx={{fontSize:24,fontWeight:'bold',color: "#555"}}>Historial de la Vacante</DialogTitle>
            <DialogContent>
                {content}
            </DialogContent>
        </Dialog>
    )
}

export default ModalHistorial