import Grid2 from "@mui/material/Unstable_Grid2/Grid2"
import { useState } from "react"
import { toast } from "react-toastify"
import { ButtonFile } from "../../Common/Buttons"
import { ButtonDialog, DialogGeneral } from "../../Common/Dialog"
import InputDateTime from "#common/input/InputDateTime"

const ModalAgendar = ({
	open,
	setOpen,
	candidato,
	agendarEntrevista,
	disabledBtn,
	file,
}) => {
	const [fecha, setFecha] = useState(null)
	const [documento, setDocumento] = useState(null)
	let entrada = null
	if (file)
		entrada = (
			<ButtonFile
				label={`${file}`}
				operation={setDocumento}
				accept=".pdf"
			/>
		)
	if (!open || candidato == null) return
	return (
		<DialogGeneral
			title={candidato.status === 2 ? "Agendar Entrevista" : "Agendar Ingreso"}
			propsTitle={{ sx: { fontSize: 24, fontWeight: "bold", color: "#555" } }}
			open={open}
			setOpen={setOpen}
			maxWidth={"xs"}
			fullWidth={true}
			buttons={[
				<ButtonDialog
					key={"cancelar"}
					label="Cancelar"
					disabled={disabledBtn}
					onClick={() => setOpen(false)}
				/>,
				<ButtonDialog
					key={"confirmar"}
					label="Confirmar"
					disabled={disabledBtn}
					onClick={() => {
						if (fecha == null) {
							toast.error("Seleccione una fecha")
							return null
						}
						agendarEntrevista(fecha?.format(), documento)
					}}
				/>,
			]}
		>
			<Grid2
				container
				justifyContent="center"
				alignItems="center"
				spacing={2}
			>
				<Grid2>
					<InputDateTime
						label="Fecha "
						value={fecha}
						onChange={setFecha}
					/>
				</Grid2>
				<Grid2>{entrada}</Grid2>
			</Grid2>
		</DialogGeneral>
	)
}

export default ModalAgendar
