import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings"
import AirplaneTicketIcon from "@mui/icons-material/AirplaneTicket"
import ArticleIcon from "@mui/icons-material/Article"
import AssignmentIndIcon from "@mui/icons-material/AssignmentInd"
import BusinessIcon from "@mui/icons-material/Business"
import CheckroomIcon from "@mui/icons-material/Checkroom"
import CurrencyExchangeIcon from "@mui/icons-material/CurrencyExchange"
import GroupsIcon from "@mui/icons-material/Groups"
import SavingsIcon from "@mui/icons-material/Savings"
import { Box } from "@mui/material/"
import Asset from "Components/Inventario/Asset"
import VacationDetail from "Components/vacation/VacationDetail"
import { useMemo, useState } from "react"

import Tabs from "#common/tabs/Tabs"
import PERMISOS from "#constants/perfiles"
import HasPermission from "#functions/HasPermission"
import { useReduxUser } from "app/userSlice"
import Documentos from "Components/Colaboradores/Detail/Documentos"
import CompanyDetails from "Components/Colaboradores/DetalleColaborarComponents/CompanyDetails"
import InventarioRH from "Components/Colaboradores/InventarioRH"
import MiEquipoCards from "#colaborador/MiEquipo/MiEquipo"
import Prestamos from "Components/Colaboradores/Solicitudes/Prestamos"
import { SolicitudesProvider } from "Components/Colaboradores/Solicitudes/SolicitudesContext"
import SolicitudesUsuario from "Components/Colaboradores/Solicitudes/SolicitudesUsuario"
import MiUniforme from "Components/Colaboradores/Uniforme/MiUniforme"
import { useReduxColaborador, useSetTab } from "../redux/slice"
import DetalleBaja from "./DetalleBaja"
import { MODULES } from "#constants/modules"

const ColaboradorDetalleTaps = ({ colaborador, show = true }) => {
	const moduleAccess = useReduxUser().moduleAccess
	const colaboradorId = useMemo(() => colaborador.id, [colaborador])
	const setValue = useSetTab()
	const { tab } = useReduxColaborador()
	const is_rh = HasPermission(PERMISOS.RH_ALL)
	const [reloadData, setReloadData] = useState(false)

	let ShowVacation = HasPermission(
		PERMISOS.GUATEMALA,
		PERMISOS.GRTE_GUAT,
		...PERMISOS.RH_ALL,
	)

	let ShowTeam = HasPermission(
		...PERMISOS.RH_ALL,
		...PERMISOS.GRTE_ALL,
		PERMISOS.JEFATURA,
		PERMISOS.GUATEMALA,
	)

	let notView = true

	if (HasPermission(PERMISOS.GUATEMALA, true) || colaborador?.sociedad_id === 3) {
		notView = false
	}
	const taps = useMemo(() => {
		if (!colaboradorId) return []
		return [
			{
				show: !!(colaborador?.fecha_baja || colaborador?.motivo_baja),
				title: "Baja",
				module: "default",
				icon: <BusinessIcon />,
				component: (
					<DetalleBaja
						colaborador_id={colaboradorId}
						activar={true}
						is_rh={is_rh}
					/>
				),
			},
			{
				show: true,
				title: "Compañía",
				icon: <BusinessIcon />,
				component: <CompanyDetails />,
				module: "default",
			},
			{
				show: true,
				title: "Inventario RH",
				icon: <AssignmentIndIcon />,
				component: <InventarioRH dataColaborador={colaborador} />,
				module: "default",
			},
			{
				show: true,
				title: "Mis Documentos",
				icon: <ArticleIcon />,
				component: <Documentos colaboradorId={colaboradorId} />,
				module: "default",
			},
			{
				show: notView,
				title: "caja de ahorro",
				icon: <SavingsIcon />,
				component: <SolicitudesUsuario />,
				module: MODULES.CAJA_AHORRO,
			},
			{
				show: notView,
				title: "prestamos",
				icon: <CurrencyExchangeIcon />,
				component: <Prestamos />,
				module: MODULES.PRESTAMOS,
			},
			{
				show: true,
				id: "tabUniforme",
				title: "uniforme",
				module: MODULES.UNIFORMES,

				icon: <CheckroomIcon />,
				component: (
					<MiUniforme
						dataColaborador={colaborador}
						setReloadData={setReloadData}
						reloadData={reloadData}
					/>
				),
			},
			{
				show: true,
				id: "responsivas",
				title: "Mis herramientas",
				module: "colaborador_herramientas",

				icon: <AdminPanelSettingsIcon />,
				component: <Asset colaborador={colaborador} />,
			},
			{
				show: ShowTeam,
				title: "Mi equipo",
				icon: <GroupsIcon />,
				module: "default",
				component: (
					<MiEquipoCards
						colaboradorId={colaboradorId}
						perfilPuestoId={colaborador.perfil_puesto_id}
					/>
				),
			},
			{
				show: ShowVacation,
				title: "Vacaciones",
				icon: <AirplaneTicketIcon />,
				module: "vacaciones",

				component: <VacationDetail colaborador={colaboradorId} />,
			},
		]
	}, [ShowTeam, ShowVacation, colaborador, colaboradorId, is_rh, notView, reloadData])

	const tapsShow = taps.filter((tap) => {
		return (
			moduleAccess.includes(MODULES.ALL) ||
			moduleAccess.includes(tap.module) ||
			tap.module === "default"
		)
	})

	if (!show) return null
	else
		return (
			<Box>
				<SolicitudesProvider
					colaboradorID={colaboradorId}
					sucursal={colaborador.sucursal}
				>
					<Tabs
						tabs={tapsShow}
						{...{ value: tab, setValue }}
					/>
				</SolicitudesProvider>
			</Box>
		)
}

export default ColaboradorDetalleTaps
