const { LUBTRAC, LUBTRAC_MX, LUBTRAC_GTM } = require("#constants/sociedades")

export function companyEmailEnd(area_id,sociedad_id = parseInt(localStorage.getItem("sociedad_id"))) {
	
	if (LUBTRAC.includes(sociedad_id)) {
		
		const mailRegion = {
			[LUBTRAC_MX]: ".mx",
			[LUBTRAC_GTM]: ".gt",
		}
		let endMail = ""
		endMail = "@lubtrac.com"
		if (area_id === 2) endMail = "@becoming"
		return endMail + mailRegion[sociedad_id]
	}
    return ""
}
export function validateCompanyEmail(email, area_id,sociedad_id = parseInt(localStorage.getItem("sociedad_id"))) {
	if (LUBTRAC.includes(sociedad_id)) {
		const end = companyEmailEnd(area_id,sociedad_id)
		return email.endsWith(end)
	}
    return true
}
