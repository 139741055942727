import Logout from "@mui/icons-material/Logout"
import Avatar from "@mui/material/Avatar"
import Box from "@mui/material/Box"
import Divider from "@mui/material/Divider"
import IconButton from "@mui/material/IconButton"
import ListItemIcon from "@mui/material/ListItemIcon"
import Menu from "@mui/material/Menu"
import MenuItem from "@mui/material/MenuItem"
import Tooltip from "@mui/material/Tooltip"

import { useSelector } from "react-redux"
import { Link } from "react-router-dom"
import { PRIMARYCOLOR as PRIMARY_COLOR } from "../../Constants/Styles"
import { useLogout } from "../../app/userSlice"
//Icons
import { LUBTRAC, LUBTRAC_MX } from "#constants/sociedades"
import useHasPermission from "#hooks/useHasPermission"
import BadgeIcon from "@mui/icons-material/Badge"
import BeachAccessIcon from "@mui/icons-material/BeachAccess"
import FolderSharedIcon from "@mui/icons-material/FolderShared"
import PaidOutlinedIcon from "@mui/icons-material/PaidOutlined"
import SosIcon from "@mui/icons-material/Sos"
import { Badge } from "@mui/material"
import { Fragment, useEffect, useState } from "react"
import { apiGetDependent } from "../../Services/Api_promotion"
import DialogColabPagos from "../FondoFijo/dialogsFondo/DialogColabPagos"

const menuColaborador = {
	[LUBTRAC_MX]: [
		{
			nombre: "Talentrac",
			ruta: "https://talentrac.lubtrac.com.mx/talentracLubtrac/login",
			target: "_blank",
			icon: <BeachAccessIcon sx={{ color: PRIMARY_COLOR }} />,
			denied: "ColaboradorGuatemala",
		},
		{
			nombre: "Intranet",
			ruta: "http://i.lubtrac.com.mx/login.asp",
			target: "_blank",
			icon: <FolderSharedIcon sx={{ color: PRIMARY_COLOR }} />,
		},
		{
			nombre: "Lubtrac Te Escucha",
			ruta: "https://lubtracteescucha.ethicsglobal.com/",
			target: "_blank",
			icon: <SosIcon sx={{ color: PRIMARY_COLOR }} />,
		},
	],
}

const Colaborador = (idColaborador) => {
	const token = useSelector((state) => state.user.token)
	const [colaborador, setColaborador] = useState([])
	const [loading, setLoading] = useState(true)

	useEffect(() => {
		const controller = new AbortController()
		const params = {
			id: idColaborador,
			fields: "photo",
		}
		setLoading(true)
		apiGetDependent({ token, setDB: setColaborador, params, controller })
			.then((response) => {
				if (response?.success) setLoading(false)
			})
			.finally(() => {})
		return () => {
			controller.abort()
		}
	}, [token, idColaborador])
	return { colaborador, loading }
}
export default function MenuUser() {
	const { hasPermissionV2 } = useHasPermission()
	const { logoutFn } = useLogout()
	const sociedad_id = parseInt(localStorage.getItem("sociedad_id"))
	const notification = useSelector((state) => state.notification.notification)
	const user = useSelector((state) => state.user)
	const { colaborador } = Colaborador(user?.colaborador?.id)
	const [anchorEl, setAnchorEl] = useState(null)
	const open = Boolean(anchorEl)
	const [dialogFondo, setDialogFondo] = useState(false)
	const [notificar, setNotificar] = useState(false)

	const version = localStorage.getItem("version")

	if (version == null || version !== process.env.REACT_APP_VERSION) {
		logoutFn()
	}

	const handleClick = (event) => {
		setAnchorEl(event.currentTarget)
	}

	const handleClose = () => {
		setAnchorEl(null)
	}

	const closeSession = () => {
		logoutFn()
	}
	// //notificaciones de menu
	let menu_notification = Object.keys(notification).filter((n) => {
		return !!n.includes("menu")
	})

	// //niega la vista si tiene un permiso negado
	let menu = menuColaborador[sociedad_id] ?? []
	if (LUBTRAC.includes(sociedad_id)) menu = menuColaborador[LUBTRAC_MX]

	let itemsMenu = menu.filter((item) => {
		return !hasPermissionV2(item.denied, true)
	})

	itemsMenu.unshift({
		nombre: "Mi Perfil",
		ruta: "/perfil",
		icon: <BadgeIcon sx={{ color: PRIMARY_COLOR }} />,
	})

	let actions = []
	if (menu_notification.indexOf("menu_fondo_fijo") > -1) {
		if (!notificar) setNotificar(true)
		actions.push(
			<MenuItem onClick={() => setDialogFondo(true)}>
				<ListItemIcon>
					<Badge
						color="secondary"
						variant="dot"
					>
						<PaidOutlinedIcon
							// fontSize="small"
							sx={{ color: PRIMARY_COLOR }}
						/>
					</Badge>
				</ListItemIcon>
				Pagar Fondo Fijo
			</MenuItem>,
		)
	}
	if (notificar && !actions.length) setNotificar(false)

	return (
		<Fragment>
			<Box sx={{ display: "flex", alignItems: "center", textAlign: "center" }}>
				<Tooltip title={colaborador[0]?.nombre_completo}>
					<IconButton
						onClick={handleClick}
						size="small"
						sx={{ ml: 2 }}
						aria-controls={open ? "account-menu" : undefined}
						aria-haspopup="true"
						aria-expanded={open ? "true" : undefined}
					>
						<Badge
							variant="dot"
							invisible={!notificar}
							color="primary"
						>
							<Avatar
								alt={user.nombre[0]}
								sx={{ width: 30, height: 30 }}
								src={colaborador[0]?.photo}
							/>
						</Badge>
					</IconButton>
				</Tooltip>
			</Box>

			<Menu
				anchorEl={anchorEl}
				id="account-menu"
				open={open}
				onClose={handleClose}
				onClick={handleClose}
				transformOrigin={{ horizontal: "right", vertical: "top" }}
				anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
			>
				{itemsMenu.map((item) => (
					<Link
						key={item.ruta}
						to={item.ruta}
						target={item.target}
						style={{ textDecoration: "none", color: "inherit" }}
					>
						<MenuItem onClick={handleClose}>
							<ListItemIcon>{item.icon}</ListItemIcon>
							{item.nombre}
						</MenuItem>
					</Link>
				))}
				<Divider />
				{actions}

				<Divider />

				<MenuItem onClick={closeSession}>
					<ListItemIcon>
						<Logout
							fontSize="small"
							sx={{ color: PRIMARY_COLOR }}
						/>
					</ListItemIcon>
					Cerrar sesión
				</MenuItem>
			</Menu>
			<DialogColabPagos
				open={dialogFondo}
				setOpen={setDialogFondo}
				colaborador={colaborador[0]?.id}
			/>
		</Fragment>
	)
}
