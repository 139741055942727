const AnimationTable = ({ activar, children }) => {
	return (
		<div
			style={{
				overflow: "hidden",
				transition: "max-height 0.5s ease-out, opacity 0.5s ease, transform 0.5s ease",
				opacity: activar ? 1 : 0,
				maxHeight: activar ? "100%" : "0",
				pointerEvents: activar ? "auto" : "none",
			}}
		>
			{children}
		</div>
	)
}

export default AnimationTable
