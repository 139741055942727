import { createContext, useContext, useMemo, useState } from "react"

export const MiEquipoContext = createContext()

/**
 * @param {Object} props
 * @param {Array} props.colaboradores - Lista de colaboradores inicial.
 * @param {React.ReactNode} props.children - Elementos hijos que tendrán acceso al contexto. *
 */
export const MiEquipoProvider = ({
	colaboradorId,
	perfilPuestoId,
	colaboradores,
	setSubColaboradores,
	subColaboradores,
	actualizar,
	setActualizar,
	children,
}) => {
	const [data, setData] = useState(colaboradores)
	// const [actualizar, setActualizar] = useState(true)

	const value = useMemo(
		() => ({
			colaboradorId,
			colaboradores,
			perfilPuestoId,
			data,
			setData,
			actualizar,
			actualizarNow: () => setActualizar((old) => !old),
			setSubColaboradores,
			subColaboradores,
		}),
		[
			actualizar,
			colaboradorId,
			colaboradores,
			data,
			perfilPuestoId,
			setActualizar,
			setSubColaboradores,
			subColaboradores,
		],
	)
	return <MiEquipoContext.Provider value={value}>{children}</MiEquipoContext.Provider>
}
/**
 * @typedef {Object} MiEquipoContextReturn
 * @property {int} colaboradorId - colaborador.
 * @property {int} perfilPuestoId - Perfil de puestos del colaborador.
 * @property {Array} data - Datos de los colaboradores.
 * @property {Function} setData - Función para actualizar los datos.
 * @property {boolean} actualizar - Estado que indica si se debe actualizar la información.
 * @property {Function} actualizarNow - Función para alternar el estado de actualización.
 * @property {Function} setSubColaboradores - Función para alternar el estado de ApiParams.
 * @property {boolean} subColaboradores - Estado que indica si se debe actualizar la búsqueda.
 * @property {Array} colaboradores - Lista inicial de colaboradores.
 */

/**
 * Hook para acceder al contexto de detalle de colaboradores.
 *
 * @returns {MiEquipoContextReturn} Valores y funciones del contexto.
 */
export function useMiEquipoContext() {
	return useContext(MiEquipoContext)
}
