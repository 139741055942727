import { useDispatch } from "react-redux"
import { changeTitle } from "app/pageSlice"
import { useEffect } from "react"

export const ChangeTitle = (title) => {
	const dispatch = useDispatch()
	useEffect(() => {
		dispatch(changeTitle(title.toUpperCase()))
	}, [dispatch, title])
}
