import { createContext, useContext, useMemo, useState } from "react"
const PromotionsContext = createContext()

export function PromotionsProvider({ reloadTable, children }) {
	const [promotion, setPromotion] = useState({})
	const [actualizar, setActualizar] = useState(null)
	const [disableButtons, setDisableButtons] = useState(false)
	const [openConfirm, setOpenConfirm] = useState(false)
	const [response, setResponse] = useState(null)
	const [promotionDetail, setPromotionDetail] = useState(null)

	const datos = useMemo(
		() => ({
			actualizar,
			reload: () => {
				setActualizar(!actualizar)
				reloadTable()
			},
			openConfirm,
			disableButtons,
			setDisableButtons,
			setOpenConfirm,
			reloadTable,
			response,
			setResponse,
			promotionDetail,
			setPromotionDetail,
			promotionId: promotion?.id ?? null,
			promotion,
			setPromotion,
		}),
		[
			actualizar,
			disableButtons,
			openConfirm,
			promotion,
			promotionDetail,
			reloadTable,
			response,
		],
	)
	return <PromotionsContext.Provider value={datos}>{children}</PromotionsContext.Provider>
}
/**
 * Hook personalizado que proporciona acceso al contexto del fondo.
 * @typedef {Object}  paramsContext Control de la vacante
 * @property {boolean} actualizar - valor para control de actualización.
 * @property {boolean} openConfirm - abre el dialogo de confirmación.
 * @property {boolean} response - respuesta de la solicitud.
 * @property {object} promotionDetail - el detalle de la promoción.
 * @property {function} reload - actualiza el estado.
 * @property {function} reloadTable - actualiza la Tabla.
 * @property {function} setOpenConfirm - Cambia el valor de openConfirm.
 * @property {function} setResponse - cambia el valor de response.
 * @property {function} setPromotionDetail - cambia el valor de PromotionDetail.
 * @property {boolean} disableButtons - cambia el valor de response.
 * @property {function} setDisableButtons - cambia el valor de PromotionDetail.
 */
/**
 *
 * @returns {paramsContext} .
 */
export function usePromotionsContext() {
	return useContext(PromotionsContext)
}
