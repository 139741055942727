// COLORS
export const COLOR_PRIMARY = process.env.REACT_APP_COLOR_PRIMARY
export const COLOR_PRIMARY_LIGHT = process.env.REACT_APP_COLOR_PRIMARY_LIGHT
export const COLOR_PRIMARY_DARK = process.env.REACT_APP_COLOR_PRIMARY_DARK
export const COLOR_PRIMARY_TEXT = process.env.REACT_APP_COLOR_PRIMARY_TEXT

export const COLOR_SECONDARY = process.env.REACT_APP_COLOR_SECONDARY
export const COLOR_THIRD = "#eeeeee"
export const COLOR_MODE = "light"//"dark"//process.env.REACT_APP_COLOR_MODE



export const PRIMARYCOLOR = process.env.REACT_APP_COLOR_PRIMARY
export const SECONDARYCOLOR = process.env.REACT_APP_COLOR_SECONDARY
export const GREYCOLOR = '#545454'
export const REDCOLOR = '#ad1a1a'
export const RED_COLOR = '#ad1a1a'
export const GREENCOLOR = '#1f7a14'
export const YELLOWCOLOR = '#a6af14'
export const COLOR_ = '#a6af14'
// TABLES
export const TABLEHEADER = { backgroundColor: process.env.REACT_APP_COLOR_PRIMARY, color: '#eee', fontWeight: 'bold', paddingTop: 1, paddingBottom: 1, fontSize: 16, }
export const TABLEROW = { fontSize: 14, fontWeight: 'bold' }