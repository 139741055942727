import { LoadSimpleTable } from "#common/LoadingView"
import { Stack } from "@mui/material"
import Typography from "@mui/material/Typography"
import Grid2 from "@mui/material/Unstable_Grid2/Grid2"
import { useMemo, useState } from "react"
import { UseColaborador } from "../UseColaborador"
import { ColaboradorTools } from "./ColaboradorTools"
import MiEquipoCardColaborador from "./MiEquipoCardColaborador"
import { MiEquipoProvider, useMiEquipoContext } from "./MiEquipoContext"

const MiEquipo = ({ colaboradorId, perfilPuestoId }) => {
	const [subColaboradores, setSubColaboradores] = useState(false)
	const [actualizar, setActualizar] = useState(false)

	const params = useMemo(() => {
		let params = {}
		if (subColaboradores) params["sub_colaboradores"] = true
		return params
	}, [subColaboradores])

	const { colaboradores, loading } = UseColaborador(`${colaboradorId}/my_team`, {
		params,
		actualizar: actualizar,
	})

	if (loading) return <LoadSimpleTable />

	return (
		<MiEquipoProvider
			{...{
				colaboradorId,
				perfilPuestoId,
				colaboradores,
				setSubColaboradores,
				subColaboradores,
				actualizar,
				setActualizar,
			}}
		>
			<Stack
				justifyItems={"center"}
				alignItems={"center"}
				spacing={2}
			>
				<ColaboradorTools />
				<div>
					<MiEquipoCards />
				</div>
			</Stack>
		</MiEquipoProvider>
	)
}

export default MiEquipo

const MiEquipoCards = () => {
	const { data } = useMiEquipoContext()
	const cardsColaboradores = useMemo(() => {
		const cards = data.map((colaborador, index) => (
			<Grid2
				key={colaborador.id}
				width={"33%"}
				minWidth={"550px"}
				maxWidth={"650px"}
			>
				<MiEquipoCardColaborador colaborador={colaborador} />
			</Grid2>
		))
		if (cards.length === 0)
			return [
				<Typography
					key={"no_colaboradores"}
					variant="h6"
					sx={{ textAlign: "center" }}
				>
					No hay colaboradores
				</Typography>,
			]
		else return cards
	}, [data])

	return (
		<Grid2
			container
			spacing={2}
			justifyContent={"center"}
		>
			{cardsColaboradores}
		</Grid2>
	)
}
