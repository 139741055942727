import TablaDetalle from "#common/tablaDetalle/TablaDetalle"
import { ChangeTitle } from "Components/menu/funciones"
import { useNavigate } from "react-router-dom"
import ColaboradorDetalle from "./ColaboradorDetalle"
import ColaboradoresTabla from "./ColaboradoresTabla"

const Colaboradores = () => {
	ChangeTitle("Colaboradores")
	const navigate = useNavigate()

	return (
		<TablaDetalle
			title="Colaboradores"
			nameParam="no_empleado"
			ruta="/colaboradores"
			tabla={
				<ColaboradoresTabla
					onClick={(colaborador) => {
						navigate(`${colaborador.no_empleado}`)
					}}
				/>
			}
			Detalle={ColaboradorDetalle}
		/>
	)
}

export default Colaboradores
