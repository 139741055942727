import { ChangeTitle } from 'Components/menu/funciones';
import { useSelector } from "react-redux";
import ColaboradorDetalle from 'Components/Colaboradores/pages/ColaboradorDetalle';

const TuPerfil = () => {
    const colaborador = useSelector(state => state.user.colaborador.id);
    ChangeTitle('perfil')

    return (<ColaboradorDetalle pk={colaborador} />)
}

export default TuPerfil