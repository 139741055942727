import { InputAutocomplete } from "#common/input/InputAutocomplete"
import { FactCheck, History, PersonAddAlt1 } from "@mui/icons-material/"
import AssignmentTurnedInIcon from "@mui/icons-material/AssignmentTurnedIn"
import DangerousIcon from "@mui/icons-material/Dangerous"
import { Box, IconButton, TextField, Tooltip } from "@mui/material/"
import { useEffect, useMemo, useState } from "react"
import { vacantePut } from "../../Services/Api"
import { ButtonDialog, DialogGeneral } from "../Common/Dialog"

import { apiGetRH } from "#api/API_RH"
import { COLABORADORES_RH } from "#api/colaboradores"
import { HasPermission } from "#common/Funciones"
import CircularSkeleton from "#common/skeleton/CircularSkeleton"
import { PERFILES } from "#constants/Strings"
import { useCallback } from "react"
import { toast } from "react-toastify"
import { useVacanteReloadAll } from "./redux/actions"

const ButtonsVacante = ({
	statusVacante,
	setOpenCandidato,
	setOpenModalVoBo,
	setOpenModalHist,
	vacante,
}) => {
	const isRH = HasPermission(PERFILES.RH, PERFILES.GRTE_RH)
	const [dialogData, setDialogData] = useState({})
	const [disabled, setDisabled] = useState(false)
	const [comentario, setComentario] = useState("")
	const reload = useVacanteReloadAll()

	const { inputUser, reclutador, loading } = GetColaboradoresRh({
		vacanteStatus: statusVacante,
		open: dialogData.open,
	})

	const TEXT_RECHAZAR = "¿Está seguro de rechazar la vacante?"
	const TEXT_RECEPTIONS = "¿Está seguro de confirmar la recepción?"

	let botones = []
	const success = useCallback(() => {
		reload()
	}, [reload])
	const end = () => {
		setDisabled(false)
	}

	const handleSendData = useCallback(() => {
		let data = { status: 10, comentario }
		if (dialogData.action) {
			if (!reclutador) {
				toast.error("Debe seleccionar un reclutador")
				return
			}
			data = { status: 3, reclutador }
		} else if (!comentario) {
			toast.error("Debe escribir una observación")
			return
		}

		setDisabled(true)
		vacantePut(vacante, data, { success, end })
	}, [comentario, dialogData.action, reclutador, success, vacante])

	const optionReceptions = {
		message: TEXT_RECEPTIONS,
		action: true,
	}

	const optionRechazo = {
		message: TEXT_RECHAZAR,
		action: false,
	}

	const openModal = useCallback((option) => {
		setDialogData({
			title: option.message,
			open: true,
			action: option.action,
		})
	}, [])

	if (statusVacante < 9) {
		botones.push({
			title: "VoBo's",
			color: "success",
			onClick: () => setOpenModalVoBo(true),
			icono: <FactCheck fontSize="inherit" />,
		})

		if (isRH) {
			botones.push({
				title: "Cancelar Vacante",
				color: "error",
				disabled: disabled,
				onClick: () => openModal(optionRechazo),
				icono: <DangerousIcon fontSize="inherit" />,
			})
		}
	}

	if (statusVacante >= 3 && statusVacante <= 8 && isRH)
		botones.push({
			title: "Agregar candidato",
			color: "success",
			onClick: () => setOpenCandidato(true),
			icono: <PersonAddAlt1 fontSize="inherit" />,
		})

	if (isRH && statusVacante === 2)
		botones.push({
			title: "Recepción RH",
			color: "success",
			disabled: disabled,
			onClick: () => openModal(optionReceptions),
			icono: <AssignmentTurnedInIcon fontSize="inherit" />,
		})

	let contenido = useMemo(() => {
		if (dialogData.title === TEXT_RECHAZAR) {
			return (
				<TextField
					label="Observaciones*"
					rows={3}
					multiline
					fullWidth
					onChange={(event) => {
						setComentario(event.target.value)
					}}
				/>
			)
		}

		if (statusVacante === 2 && dialogData.title !== TEXT_RECHAZAR) {
			setDisabled(loading)
			return inputUser
		}
	}, [dialogData.title, inputUser, loading, statusVacante])

	return (
		<>
			<Box
				display="flex"
				justifyContent="right"
			>
				{botones.map((button) => (
					<Tooltip
						key={button.title}
						title={button.title}
					>
						<IconButton
							color={button.color}
							disabled={button.disabled}
							onClick={button.onClick}
							size="large"
						>
							{button.icono}
						</IconButton>
					</Tooltip>
				))}
				<Tooltip
					title="Ver Historial"
					onClick={() => setOpenModalHist(true)}
				>
					<IconButton
						color="primary"
						size="large"
					>
						<History fontSize="inherit" />
					</IconButton>
				</Tooltip>
			</Box>
			<DialogGeneral
				title={dialogData.title}
				open={!!dialogData.open}
				setOpen={setDialogData}
				buttons={[
					<ButtonDialog
						key={"cancelar"}
						disabled={disabled}
						onClick={() => setDialogData(false)}
						label="Cancelar"
					/>,
					<ButtonDialog
						key={"aceptar"}
						disabled={disabled}
						onClick={handleSendData}
						label="aceptar"
					/>,
				]}
			>
				{contenido}
			</DialogGeneral>
		</>
	)
}

export default ButtonsVacante

const GetColaboradoresRh = ({ vacanteStatus, open }) => {
	const [colaboradores, setColaboradores] = useState([])
	const [loading, setLoading] = useState(false)
	const [reclutador, setReclutador] = useState(null)

	useEffect(() => {
		if (vacanteStatus === 2 && open) {
			setLoading(true)
			const success = (data) => {
				setColaboradores(data)
				setLoading(false)
			}
			apiGetRH(COLABORADORES_RH, { success })
		}
	}, [open, vacanteStatus])

	let inputUser = useMemo(() => {
		if (loading) return <CircularSkeleton />

		return (
			<InputAutocomplete
				{...{
					label: "Reclutador*",
					options: colaboradores,
					valueId: "id",
					valueLabel: "full_name",
					setValue: setReclutador,
					value: reclutador,
				}}
			></InputAutocomplete>
		)
	}, [colaboradores, loading, reclutador])

	return { inputUser, reclutador, loading }
}
