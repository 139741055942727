import { createContext, useContext, useMemo, useState } from "react"
const VacanteContext = createContext()

export function VacanteProvider({ reload, setReload, children }) {
	const [vacanteSelect, setVacanteSelect] = useState(null)
	const datos = useMemo(
		() => ({
			vacanteSelect,
			setVacanteSelect,
			reload,
			startReload: () =>
				setReload((old) => {
					return !old
				}),
		}),

		[reload, setReload, vacanteSelect],
	)
	return <VacanteContext.Provider value={datos}>{children}</VacanteContext.Provider>
}
/**
 * Hook personalizado que proporciona acceso al contexto del fondo.
 * @typedef {Object}  paramsVacante Control de la vacante
 * @property {object} vacanteSelect - Datos de la vacante.
 * @property {function} setVacanteSelect - Función para establecer los datos de la vacante.
 * @property {function} startReload - Función para iniciar el recarga de la lista de vacantes.
 */
/**
 *
 * @returns {paramsVacante} - Control de vacantes.
 */
export function useVacanteContext() {
	return useContext(VacanteContext)
}
