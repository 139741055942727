import { useState } from 'react';
import {InputLabel, FormControl, OutlinedInput, InputAdornment, IconButton, Button, CssBaseline, TextField, Box, Typography, Container } from '@mui/material/';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useDispatch } from 'react-redux'
import { login } from '../app/userSlice';
import { changeTitle } from '../app/pageSlice';
import { loginWeb } from '../Services/Api';
import Copyright from '../Components/Common/Copyright';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { PRIMARYCOLOR } from '../Constants/Styles';
import { toast, ToastContainer } from "react-toastify"
import { useGoogleLogin } from '@react-oauth/google';
import GoogleIcon from '@mui/icons-material/Google';

import Joyride from 'react-joyride';
import { LOGO } from 'Images/imgRutas';

const theme = createTheme({
    palette: {
        primary: { main: PRIMARYCOLOR, },
        secondary: { main: '#ff0000', },
    },
});

export default function SignIn() {
    const dispatch             = useDispatch()
    const [loading,setLoading] = useState(false)
    const [values, setValues]  = useState({
        username: '',
        password: '',
        showPassword: false,
    });

    const tourSteps = [
        {
            target: '#btnGoogle', 
            disableBeacon: true,
            content: 'O puedes iniciar sesión con tu cuenta corporativa', 
        },
        {
            target: '#userName', 
            disableBeacon: true,
            content: 'Ingresa tu usuario', 
        },
    ];
    
    const loginGoogle = useGoogleLogin({
        onSuccess: tokenResponse => getUserData(tokenResponse.access_token),
        onError: error => toast.error("Error al realizar el inicio se sesión"),
    });

    const getUserData = async (token) => {
        let userInfoResponse = await fetch("https://www.googleapis.com/userinfo/v2/me", { headers: { Authorization: `Bearer ${token}` } });
        userInfoResponse.json().then(data =>{
            console.log(data)
            loginServer('google', data.email, data.id)
        } ).catch(err => toast.error("Error al realizar el inicio se sesión"));
    };

    const handleMouseDownPassword = (event) => { event.preventDefault(); };

    const handleChange = (prop) => (event) => { setValues({ ...values, [prop]: event.target.value }); };
    
    const handleClickShowPassword = () => { setValues({ ...values, showPassword: !values.showPassword, }); };

    const handleKeypress = e => {if (e.charCode === 13) loginServer('userpass') };

    const loginServer = (loginType, email = '', google_id= '') =>{
        if((values.password === '' || values.username === '') &&loginType === 'userpass'){
            toast.error("Debes de llenar todos los campos requeridos")
            return
        }
        setLoading(true)
        loginWeb(loginType, email, values.username, values.password, google_id).then( response =>{
            if(response.success === true){
                dispatch(login(response.data))
                
                dispatch(changeTitle("Colaboradores"))
                
                // window.location.reload(true)
            }
            else
                toast.error(response.message)
        }).catch( err => {
            toast.error("Error al realizar el inicio se sesión")
        }).finally(()=>setLoading(false))
    }


    return (
        <ThemeProvider theme={theme}>
            <Joyride
                continuous={true}
                locale= {{ back: 'Regresar', close: 'Cerrar', last: 'Ultimo', next: 'Siguiente', open: 'Abrr dialogo', skip: 'Cerrar' }}
                showProgress
                run={false}
                steps={tourSteps}          />
            <Container
                component="main"
                maxWidth="xs"
                sx={{
                    backgroundImage: 'url(https://images.unsplash.com/photo-1497032628192-86f99bcd76bc?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8M3x8d29ya3xlbnwwfHwwfHw%3D&w=1000&q=80)',
                    backgroundRepeat: 'no-repeat',
                    backgroundColor: (t) =>
                        t.palette.mode === 'light' ? t.palette.grey[50] : t.palette.grey[900],
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    height: '100vh',
                    minWidth: '100%'
                }}>
                <CssBaseline />
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        pt:10, }}>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            p:10,
                            pb:5,
                            backgroundColor:'#ffffffa6',
                            maxWidth:500,
                            boxShadow: 6
                        }} >
                        <img  src={LOGO} alt="Logo"  height='100px' sx={{opacity: 0.5}}/>
                            <Typography component="h1" variant="h5" fontFamily={'Emprint'} sx={{ mt: 2 }}> {process.env.REACT_APP_SERVER_NAME} </Typography>
                            <Typography  fontFamily={'Emprint'} sx={{ fontSize:12 }}> V {process.env.REACT_APP_VERSION} </Typography>
                        <Box  sx={{ mt: 1,textAlign:'center' }}>
                            <TextField
                                margin="normal"
                                fullWidth
                                id="userName"
                                label="Usuario"
                                name="user"
                                autoFocus
                                onKeyPress={handleKeypress}
                                onChange={handleChange('username')} />   
                            <FormControl sx={{ mt: 1, }} fullWidth variant="outlined">
                                <InputLabel htmlFor="outlined-adornment-password">Contraseña</InputLabel>
                                <OutlinedInput
                                    id="outlined-adornment-password"
                                    type={values.showPassword ? 'text' : 'password'}
                                    value={values.password}
                                    onChange={handleChange('password')}
                                    onKeyPress={handleKeypress}
                                    endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={handleClickShowPassword}
                                        onMouseDown={handleMouseDownPassword}
                                        edge="end" >
                                        {values.showPassword ? <VisibilityOff /> : <Visibility />}
                                        </IconButton>
                                    </InputAdornment>
                                    }
                                    label="Contraseña"
                                />
                            </FormControl>
                            <Button
                                type="submit"
                                fullWidth
                                variant="contained"
                                sx={{ mt: 3,mb: 2, fontFamily:'Emprint', backgroundColor: PRIMARYCOLOR, color:'#FFF'}} 
                                onClick={()=>loginServer('userpass')}>
                                Iniciar Sesión
                            </Button>
                            <Button 
                                id="btnGoogle"
                                color='secondary'
                                fullWidth
                                variant="contained" 
                                onClick={() => loginGoogle()}>
                                Inicio de sesión con Google <GoogleIcon sx={{ml:1}}/>
                            </Button>      
                            <Copyright />
                        </Box>
                    </Box>
                </Box>
                <ToastContainer
                    position="bottom-right"
                    autoClose={1500}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss={false}
                    draggable                />
                <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={loading}
                    onClick={() => setLoading(false)}                >
                    <CircularProgress color="inherit" />
                </Backdrop>
            </Container>
        </ThemeProvider>
    );
}