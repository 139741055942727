import { apiBajaGet } from "#api/bajas"
import CircularSkeleton from "#common/skeleton/CircularSkeleton"
import { TextCampoValor } from "#common/text/CampoValor"
import { RED_COLOR } from "#constants/Styles"
import { Stack } from "@mui/material/"
import DetailTermination from "Components/bajas/DialogDetailBaja/components/DetailTermination"
import { UseDetalleColaboradorContext } from "Components/Colaboradores/Solicitudes/SolicitudesContext"
import { useEffect, useState } from "react"

const DetalleBaja = ({ colaborador_id, activar = false, is_rh }) => {
	const { colaborador } = UseDetalleColaboradorContext()
	const [baja, setBaja] = useState({})
	const [loading, setLoading] = useState(true)
	const [dialogIndex, setDialogIndex] = useState(0)
	const [bajaNotExist, setBajaNotExist] = useState(false)
	useEffect(() => {
		if (colaborador?.fecha_baja && !colaborador?.motivo_baja && is_rh)
			apiBajaGet(colaborador_id, {
				success: setBaja,
				end: () => setLoading(false),
				fail: () => setBajaNotExist(true),
				params: { colaborador_id: true },
				notification: false,
			})
		else {
			setLoading(false)
			setBajaNotExist(true)
		}
	}, [activar, colaborador?.fecha_baja, colaborador?.motivo_baja, colaborador_id, is_rh])
	if (loading) return <CircularSkeleton />

	if (bajaNotExist)
		return (
			<Stack
				spacing={2}
				direction={"column"}
				// alignItems={"center"}
			>
				<TextCampoValor
					field={"Fecha"}
					value={colaborador?.fecha_baja}
					sx={{ color: RED_COLOR, fontSize: 24 }}
				/>
				<TextCampoValor
					field="Motivo"
					value={colaborador?.motivo_baja}
					sx={{ color: RED_COLOR, fontSize: 24 }}
				/>
			</Stack>
		)

	return (
		<DetailTermination
			{...{
				baja,
				dialogIndex,
				setDialogIndex,
				disabled: true,
			}}
		/>
	)
}
export default DetalleBaja
