import TOAST_CONFIG from "#constants/toasConfig"
import axios from "axios"
import { useCallback } from "react"
import { toast } from "react-toastify"
/**
 * @typedef {Object} UseApiReturn
 * @property {function} fetch - Una función para realizar manualmente la llamada a la API.
 */

/**
 * Un hook personalizado para manejar llamadas a APIs de manera sencilla.
 *
 * @param {function} apiCall - Una función que recibe parámetros y retorna un objeto con dos propiedades:
 *                             - `call`: Una promesa que realiza la llamada a la API.
 *                             - `controller`: Una instancia de `AbortController` para cancelar la solicitud.
 * @param {Object} options - Opciones de configuración para el hook.
 * @param {function} [options.success]
 * @param {function} [options.error] 
 * @param {function} [options.end]
 * @returns {UseApiReturn}
 */
export const useApiSend = (apiCall, options) => {
	const fetch = useCallback(
		(...param) => {
			const { call, controller } = apiCall(...param)
			const id = toast.loading("Enviando...")

			call
				.then((response) => {
					if (options.success) options?.success(response)

					toast.update(id, {
						render: response.data.message,
						type: "success",
						isLoading: false,
						...TOAST_CONFIG,
					})
				})
				.catch((err) => {
					let mensaje = "error"
					if (axios.isCancel(err)) {
						mensaje = "Cancelando"
					}

					if (err?.response?.data?.message) mensaje = err?.response?.data?.message

					toast.update(id, {
						render: mensaje,
						type: "error",
						isLoading: false,
						...TOAST_CONFIG,
					})
					if (options.error) options?.error(err)
				})
				.finally(() => {
					if (options?.finally) options?.finally()
					if (options?.end) options?.end()
				})
			return () => controller.abort()
		},
		[apiCall, options],
	)

	return { fetch }
}
