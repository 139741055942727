import TextField from "@mui/material/TextField"
import React, { useMemo } from "react"

export default function InputText({
	label,
	required,
	fullWidth,
	onChange,
	value,
	setValue,
	disabled,
}) {
	const props = { label, value, required, fullWidth }

	const handleChange = useMemo(
		() => (setValue ? (event) => setValue(event.target.value) : onChange),
		[onChange, setValue],
	)

	return (
		<TextField
			onChange={handleChange}
			disabled={disabled}
			{...props}
			variant="outlined"
			size="small"
			defaultValue={value}
		/>
	)
}
