import Grid from "@mui/material/Unstable_Grid2"
import MenuItem from "@mui/material/MenuItem"
import InputLabel from "@mui/material/InputLabel"
import FormControl from "@mui/material/FormControl"
import Select from "@mui/material/Select"

/**
 * Componente InputSelect con Grid2.
 *
 * Este componente proporciona una interfaz para seleccionar opciones en un menú desplegable.
 *
 * @component
 * @param {Object} props - Las propiedades del componente.
 *
 * @param {string} [props.label="hola"] - La etiqueta del input. Valor predeterminado: "hola".
 * @param {Array} [props.data=[]] - Los datos a mostrar en el menú. Valor predeterminado: [].
 * @param {string} [props.dataLabel="label"] - Propiedad en los datos que se usará como etiqueta. Valor predeterminado: "label".
 * @param {string} [props.dataID="id"] - Propiedad en los datos que se usará como ID. Valor predeterminado: "id".
 * @param {boolean} [props.error=false] - Indicador de si hay un error. Valor predeterminado: false.
 * @param {boolean} [props.all=false] - Opción para mostrar "todos". Valor predeterminado: false.
 * @param {boolean} [props.disabled=false] - Opción para deshabilitar el inputSelect. Valor predeterminado: false.
 * @param {number} [props.xs=12] - Tamaño en pantallas extra-small. Valor predeterminado: 12.
 * @param {number} [props.md=4] - Tamaño en pantallas medium. Valor predeterminado: 4.
 * @param {number} [props.xl=2] - Tamaño en pantallas extra-large. Valor predeterminado: 2.
 * @param {string} [props.option=""] - Opción actualmente seleccionada. Valor predeterminado: "".
 * @param {Function} [props.setOption=() => {}] - Función para cambiar la opción seleccionada. Valor predeterminado: () => {}.
 * @param {Function} [props.operation] - Operación a realizar cuando cambia el valor. Valor predeterminado: undefined.
 * @returns {JSX.Element} Un componente que muestra un menú desplegable de opciones.
 */

export const InputSelect = ({
	label = "hola",
	data = [],
	dataLabel = "label",
	dataID = "id",
	error = false,
	all = false,
	disabled = false,
	xs = 12,
	md = 8,
	xl = 6,
	option = "",
	setOption = () => {},
	operation = (event) => {
		setOption(event.target.value)
	}
}) => {
	if (data.length === 0) {
		data = []
	}
	return (
		<Grid
			xs={xs}
			//sm={sm}
			md={md}
			//lg={lg}
			xl={xl}
		>
			<FormControl
				fullWidth
				error={error}
			>
				<InputLabel id={`input${label}`}>{label}</InputLabel>
				<Select
					labelId={`input${label}`}
					id={`select${label}`}
					value={option}
					label={label}
					disabled={disabled}
					onChange={operation}
				>
					{all && (
						<MenuItem
							key={"default"}
							value={0}
						>
							{all}
						</MenuItem>
					)}
					{data.map((option, index) => (
						<MenuItem
							key={label + index}
							value={option[dataID]}
						>
							{option[dataLabel]}
						</MenuItem>
					))}
				</Select>
			</FormControl>
		</Grid>
	)
}
