import React from "react"
import { STATUS_LOAN } from "../../constants"
//Componentes
import { InputGrid } from "../../../../Common/Inputs"

//Material UI
import DialogContent from "@mui/material/DialogContent"
import DialogContentText from "@mui/material/DialogContentText"

function DialogConfirm2({ setComentario, data, show }) {
	const comment = data["status"] === STATUS_LOAN["REJECT"] || data["status"] === -1
	return show ? (
		<>
			<DialogContent>
				<DialogContentText
					style={{
						paddingBottom: "10px",
					}}
				>{`${data["mensaje"]}`}</DialogContentText>
				{comment && (
					<InputGrid
						label="Comentarios"
						setOption={setComentario}
						style={{ paddingTop: "10px" }}
					/>
				)}
			</DialogContent>
		</>
	) : null
}

export default DialogConfirm2
