import { apiGetPromotionHistory } from "#api/Api_promotion"
import { TimelineDot } from "@mui/lab"
import Timeline from "@mui/lab/Timeline"
import TimelineConnector from "@mui/lab/TimelineConnector"
import TimelineContent from "@mui/lab/TimelineContent"
import TimelineItem from "@mui/lab/TimelineItem"
import TimelineOppositeContent from "@mui/lab/TimelineOppositeContent"
import TimelineSeparator from "@mui/lab/TimelineSeparator"
import Dialog from "@mui/material/Dialog"
import DialogContent from "@mui/material/DialogContent"
import DialogTitle from "@mui/material/DialogTitle"
import Grid from "@mui/material/Grid"
import Typography from "@mui/material/Typography"
import { useContext, useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { ActualizarContext } from "./ContextActualizar"

const useHistoryData = (promotion = null) => {
	const token = useSelector((state) => state.user.token)
	// const { actualizar } = useContext(ActualizarContext)
	const [history, setHistory] = useState([])
	const [load, setLoad] = useState([])
	useEffect(() => {
		const controller = new AbortController()
		setLoad(true)
		apiGetPromotionHistory({
			token,
			setDB: setHistory,
			controller,
			params: { promotion },
		}).then((response) => {
			if (response?.success) setLoad(false)
		})
		return () => {
			controller.abort()
		}
	}, [token, promotion])
	return { history, load }
}

const ModalHistorial = ({ open, setOpen, promotion }) => {
	const { history } = useHistoryData(promotion)
	if (!open) return
	return (
		<Dialog
			open={open}
			maxWidth={"md"}
			fullWidth={true}
			onClose={() => setOpen(false)}
		>
			<DialogTitle sx={{ fontSize: 24, fontWeight: "bold", color: "#555" }}>
				Historial Promoción #{promotion}
			</DialogTitle>
			<DialogContent>
				<Grid
					container
					sx={{ mt: 0 }}
					spacing={3}
				>
					<Timeline position="left">
						{history.map((log, index) => {
							return (
								<TimelineItem key={"hist" + index}>
									<TimelineOppositeContent
										sx={{ m: "auto 0" }}
										variant="body2"
										color="text.secondary"
									>
										{log.date}
									</TimelineOppositeContent>
									<TimelineSeparator>
										<TimelineConnector />
										<TimelineDot color="success"></TimelineDot>
										<TimelineConnector />
									</TimelineSeparator>
									<TimelineContent sx={{ py: "12px", px: 2 }}>
										<Typography
											variant="h6"
											component="span"
											fontWeight={"bolder"}
											fontSize={18}
										>
											{log.colaborador_name}
										</Typography>
										<Typography style={{ whiteSpace: "pre-line" }}>
											{log.action}
										</Typography>
									</TimelineContent>
								</TimelineItem>
							)
						})}
					</Timeline>
				</Grid>
			</DialogContent>
		</Dialog>
	)
}

export default ModalHistorial
