import { colaboradorIdGet, colaboradorNoEmpleadoGet } from "#api/ApiColaboradores"
import { HasPermission } from "#common/Funciones"
import { LoadViewDetail } from "#common/LoadingView"
import { PERFILES } from "#constants/Strings"
import { COLOR_PRIMARY } from "#constants/Styles"
import VerifiedIcon from "@mui/icons-material/Verified"
import { Box, Grid, Stack, Typography } from "@mui/material/"
import Grid2 from "@mui/material/Unstable_Grid2/Grid2"
import ColaboradorDetalleTaps from "Components/Colaboradores/DetalleColaborarComponents/ColaboradorDetalleTaps"
import { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { ColaboradorImage } from "../DetalleColaborarComponents/ColaboradorImage"
import ButtonsColaborador from "../DetalleColaborarComponents/DetalleColaborarComponents"
import { useReduxColaborador, useSetColaborador } from "../redux/slice"
import { DetalleColaboradorProvider } from "../Solicitudes/SolicitudesContext"
import SearchOffIcon from "@mui/icons-material/SearchOff"
import SearchError from "#common/error/SearchError"
const ColaboradorDetalle = ({ pk, no_empleado }) => {
	const { reloadColaborador } = useReduxColaborador()
	const [dataColaborador, setDataColaborador] = useState({})
	const [reloadData, setReloadData] = useState(false)
	const [error, setError] = useState(null)
	const reloadDataNow = () => setReloadData(!reloadData)
	const [showLoading, setShowLoading] = useState(true)
	const is_rh = HasPermission(PERFILES.RH)
	const { actualizar } = useSelector((state) => state.actualizar)
	const setColaborador = useSetColaborador()

	useEffect(() => {
		setShowLoading(true)
		setError(false)
		setDataColaborador({})
		const success = (data) => {
			setDataColaborador(data)
			setColaborador(data)
		}

		const end = () => {
			setShowLoading(false)
		}
		const settings = { success, end, fail: setError, notification: false }
		if (no_empleado) colaboradorNoEmpleadoGet(no_empleado, settings)
		if (pk) colaboradorIdGet(pk, settings)
	}, [reloadData, actualizar, no_empleado, setColaborador, reloadColaborador, pk])

	const activarDetalles = !dataColaborador.no_empleado?.includes("VC")

	if (showLoading) return <LoadViewDetail />
	if (error)
		return (
			<SearchError
				error={error}
				alternative="No se encontró el Información del Colaborador"
			/>
		)

	return (
		<Box>
			<DetalleColaboradorProvider
				colaborador={dataColaborador}
				reload={reloadDataNow}
				isRH={is_rh}
			>
				<Grid
					container
					width={"100%"}
				>
					<Grid
						item
						xs={12}
					>
						<div style={{ width: "100%" }}>
							<ColaboradorHeader
								colaborador={dataColaborador}
								is_rh={is_rh}
								reload={reloadDataNow}
							/>
						</div>
					</Grid>

					<Grid
						container
						item
						xs={12}
						sx={{ color: COLOR_PRIMARY }}
					>
						<Grid
							item
							md={12}
							xs={12}
						>
							<ColaboradorDetalleTaps
								colaborador={dataColaborador}
								show={activarDetalles}
							/>
						</Grid>
					</Grid>
				</Grid>
			</DetalleColaboradorProvider>
		</Box>
	)
}

export default ColaboradorDetalle

const ColaboradorHeader = ({ colaborador, is_rh, reload }) => {
	const { fecha_baja } = colaborador
	const buttonsHidden = fecha_baja != null || !is_rh
	return (
		<Box>
			<ButtonsColaborador
				dataColaborador={colaborador}
				reloadNow={reload}
				hidden={buttonsHidden}
			/>
			<Grid2
				container
				spacing={3}
				style={{ width: "100%" }}
				justifyContent={"left"}
				columns={{ md: 12, lg: 16 }}
			>
				<Grid2
					xs={12}
					md={2}
				>
					<ColaboradorImage
						colaborador={colaborador}
						is_rh={is_rh}
					/>
				</Grid2>
				<Grid2
					xs={12}
					md={10}
					lg={14}
				>
					<ColaboradorInfo colaborador={colaborador} />
				</Grid2>
			</Grid2>
		</Box>
	)
}

const ColaboradorInfo = ({ colaborador }) => {
	const { full_name, perfil_puesto } = colaborador
	const { sucursal, no_empleado } = colaborador

	let verificado = colaborador?.verificado ? (
		<VerifiedIcon sx={{ marginLeft: "5px", color: "#49A7A7" }} />
	) : null

	const alignItems = { md: "start", xs: "center" }
	return (
		<Stack
			spacing={{ xs: 0, md: 3, lg: 20 }}
			useFlexGap
			direction={{ md: "row", xs: "column" }}
			justifyContent={"top"}
			alignItems={alignItems}
		>
			<Stack alignItems={alignItems}>
				<Typography
					sx={{ fontSize: 24, fontWeight: "bold" }}
					mb={-0.5}
					color={"primary"}
				>
					{full_name}
					{verificado}
				</Typography>
				<FieldInfo
					campo="No. Empleado:"
					valor={no_empleado}
				/>

				<FieldInfo
					campo="Puesto: "
					valor={perfil_puesto}
				/>

				<FieldInfo
					campo={"Sucursal:"}
					valor={sucursal}
				/>
			</Stack>
		</Stack>
	)
}

const FieldInfo = ({ campo = "", valor, sx, center }) => {
	const styleData = { fontSize: 19, fontWeight: "bold", ...sx }
	if (valor == null) return null
	return (
		<Typography
			sx={styleData}
			mb={-0.5}
			color={"primary"}
		>
			{`${campo} ${valor}`}
		</Typography>
	)
}
