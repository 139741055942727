import { Fragment, useEffect, useState } from "react"
// material
import Box from "@mui/material/Box"
import Button from "@mui/material/Button"
import Stack from "@mui/material/Stack"
import TextField from "@mui/material/TextField"
//icons
import CachedIcon from "@mui/icons-material/Cached"
import PersonIcon from "@mui/icons-material/Person"
// componentes
import { MODULES } from "#constants/modules"
import GroupsIcon from "@mui/icons-material/Groups"
import { useHasModuleAccess } from "app/userSlice"
import { useMiEquipoContext } from "./MiEquipoContext"
import MiEquipoPromotionDialog from "./MiEquipoPromotionDialog"

export const ColaboradorTools = () => {
	const { setSubColaboradores, subColaboradores } = useMiEquipoContext()

	return (
		<Box>
			<Stack
				width={"100%"}
				height={"40px"}
				direction={"row"}
				spacing={1}
				justifyContent={"right"}
			>
				<SearchColaboradores />
				<Button
					variant="contained"
					disabled={subColaboradores}
					onClick={() => {
						setSubColaboradores(true)
					}}
				>
					<GroupsIcon />
				</Button>
				<ButtonPromotion />
			</Stack>
		</Box>
	)
}

const SearchColaboradores = () => {
	const { colaboradores, setData } = useMiEquipoContext()
	const [search, setSearch] = useState("")

	useEffect(() => {
		const colaboradoresFilter = colaboradores?.filter((colaborador) => {
			if (search.length === 0) return colaborador
			if (colaborador.nombre_completo?.toLowerCase()?.includes(search?.toLowerCase()))
				return colaborador
			return false
		})
		setData(colaboradoresFilter)
	}, [colaboradores, search, setData])

	return (
		<TextField
			sx={{ width: `350px`, height: "40px" }}
			label="Buscar por nombre"
			variant="outlined"
			size="small"
			autoComplete="off"
			value={search}
			onChange={(e) => setSearch(e.target.value)}
		/>
	)
}

const ButtonPromotion = () => {
	const [openPromotion, setOpenPromotion] = useState(false)
	const viewPromotions = useHasModuleAccess(MODULES.PROMOTIONS)
	const { colaboradorId, perfilPuestoId, actualizarNow: reload } = useMiEquipoContext()
	if (!viewPromotions) return null

	return (
		<Fragment>
			<Button
				variant="contained"
				size="large"
				onClick={() => setOpenPromotion(!openPromotion)}
			>
				<PersonIcon />
				<CachedIcon />
			</Button>
			<MiEquipoPromotionDialog
				{...{ colaboradorId, perfilPuestoId, reload }}
				open={openPromotion}
				setOpen={setOpenPromotion}
			/>
		</Fragment>
	)
}
