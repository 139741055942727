import { createSlice } from "@reduxjs/toolkit"
import { useSelector } from "react-redux"

const initialState = {
	reloadTable: true,
	reloadDetalle: true,
	vacante: null,
	isRh: false,
}
const VacanteSlice = createSlice({
	name: "vacante",
	initialState,
	reducers: {
		ReloadAll(state) {
			state.reloadTable = !state.reloadTable
			state.reloadDetalle = !state.reloadDetalle
		},
		ReloadTable(state) {
			state.reloadTable = !state.reloadTable
		},
		DetalleReload(state) {
			state.reloadDetalle = !state.reloadDetalle
		},
		setVacante(state, action) {
			state.PerfilesPuesto = action.payload
		},
		setIsRh(state, action) {
			state.isRh = action.payload
		},
	},
})

export default VacanteSlice

/**
 * Hook para obtener el estado del PerfilesPuesto desde Redux.
 *
 * @returns {{reloadTable: boolean, reloadDetalle: boolean,PerfilesPuesto:object,isRh:boolean}}
 * @property {boolean} reloadTable - Indica si se debe recargar la tabla.
 * @property {boolean} reloadDetalle - Indica si se debe recargar el PerfilesPuesto.
 * @property {boolean} isRh - validar si es una persona de rh
 * @property {object} vacante - Objeto del PerfilesPuesto.
 */
export const useReduxVacantes = () => {
	return useSelector((state) => state.vacante)
}
