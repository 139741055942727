import { perfilPuestoGet } from "#api/Api/perfilPuesto"
import SearchError from "#common/error/SearchError"
// import { HasPermission } from "#common/Funciones"
import PERMISOS from "#constants/perfiles"
import { PRIMARYCOLOR, RED_COLOR } from "#constants/Styles"
import { useApi } from "#hooks/useApi"
import useHasPermission from "#hooks/useHasPermission"
import { ButtonDisable } from "#perfilesPuesto/detalle/Buttos"
import ModalColaboradores from "#perfilesPuesto/ModalColaboradores"
import ModalNewPerfil from "#perfilesPuesto/ModalNewPerfil"
import ModalPerfilPuestoPDF from "#perfilesPuesto/ModalPerfilPuestoPDF"
import { useReduxPerfilesPuesto } from "#perfilesPuesto/redux/slice"
import { perfilesIdGet } from "#perfilesPuesto/Services/api"
import {
	CastForEducation,
	Edit,
	Laptop,
	LocalPrintshop,
	People,
	School,
	ThumbUp,
	Translate,
	Verified,
	Work,
} from "@mui/icons-material/"
import HandymanIcon from "@mui/icons-material/Handyman"
import SimCardDownloadIcon from "@mui/icons-material/SimCardDownload"
import { Skeleton, Stack } from "@mui/material"
import {
	Box,
	Grid,
	IconButton,
	List,
	ListItem,
	ListItemIcon,
	ListItemText,
	Paper,
	Tooltip,
	Typography,
} from "@mui/material/"
import Zoom from "@mui/material/Zoom"
import { useMemo, useState } from "react"

const Detalle = ({ perfilPuestoId }) => {
	const { hasPermissionV2 } = useHasPermission()
	const { reloadPerfilesPuesto } = useReduxPerfilesPuesto()
	const params = useMemo(() => perfilPuestoId, [perfilPuestoId])
	const {
		data: dataPerfil,
		loading,
		error,
	} = useApi(perfilesIdGet, {
		autoFetch: !!perfilPuestoId,
		params,
		reload: reloadPerfilesPuesto,
	})
	const [openModal, setOpenModal] = useState(false)
	// const [openModalConfirm, setOpenModalConfirm] = useState(false)
	const [openModalColab, setOpenModalColab] = useState(false)
	const [openModalPDF, setOpenModalPDF] = useState(false)
	// const [messageConfirm, setMessageConfirm] = useState("")
	// const [actionApi, setActionApi] = useState(null)
	const isRH = hasPermissionV2(PERMISOS.RH_ALL)
	if (loading)
		return (
			<Skeleton
				variant="rectangular"
				height={"70vh"}
				width={"100%"}
			/>
		)

	if (error)
		return (
			<SearchError
				error={error}
				alternative="No se encontró información de perfil puesto "
			/>
		)

	return (
		<>
			<Grid
				container
				component={Paper}
				elevation={12}
				id={"pdf"}
			>
				<Grid
					container
					item
					md={12}
					sx={{ p: 5 }}
				>
					<Grid
						item
						md={8}
					>
						<Box component={"span"}>
							<Typography sx={{ fontSize: 22, fontWeight: "bold", color: PRIMARYCOLOR }}>
								{" "}
								{dataPerfil.nombre}{" "}
							</Typography>
							{!dataPerfil?.habilitado && (
								<Typography sx={{ fontSize: 20, fontWeight: "bold", color: RED_COLOR }}>
									Desactivado
								</Typography>
							)}
							<Typography sx={{ fontSize: 20, fontWeight: "bold", color: "#555" }}>
								{" "}
								Departamento: {dataPerfil.centro_costos.descripcion}{" "}
							</Typography>
							{isRH ? (
								<Typography sx={{ fontSize: 20, fontWeight: "bold", color: "#555" }}>
									{" "}
									Departamento Plataforma: {dataPerfil.area.descripcion}{" "}
								</Typography>
							) : (
								<></>
							)}
							<Typography sx={{ fontSize: 20, fontWeight: "bold", color: "#555" }}>
								Reporta a: {dataPerfil.reporta_a}{" "}
							</Typography>
						</Box>
					</Grid>
					<Grid
						item
						md={4}
					>
						<ButtonsPerfil
							dataPerfil={dataPerfil}
							// openConfirm={openConfirm}
							setOpenModal={setOpenModal}
							setOpenModalColab={setOpenModalColab}
							setOpenModalPDF={setOpenModalPDF}
						/>
					</Grid>
					<Grid
						item
						md={12}
					>
						<Typography sx={{ fontSize: 16, color: "#555" }}>
							{" "}
							{dataPerfil.objetivo}{" "}
						</Typography>
					</Grid>
				</Grid>
				<ListCompet
					listItem={dataPerfil.perfil_competencia}
					IconList={School}
					listName={"Educación"}
				/>
				<ListCompet
					listItem={dataPerfil.perfil_competencia}
					IconList={CastForEducation}
					listName={"Capacitación"}
				/>
				<ListCompet
					listItem={dataPerfil.perfil_competencia}
					IconList={Work}
					listName={"Experiencia"}
				/>
				<ListCompet
					listItem={dataPerfil.perfil_competencia}
					IconList={Translate}
					listName={"Idiomas"}
				/>
				<ListCompet
					listItem={dataPerfil.perfil_competencia}
					IconList={Laptop}
					listName={"Software"}
				/>
				<ListCompet
					listItem={dataPerfil.perfil_competencia}
					IconList={ThumbUp}
					listName={"Adicionales"}
				/>

				<ListCompet
					listItem={dataPerfil.perfil_competencia}
					IconList={Verified}
					listName={"Responsabilidades"}
					size={12}
				/>
				<Grid
					item
					md={6}
					sx={{ p: 5, pt: 1 }}
				>
					<Typography
						sx={{ fontSize: 20, fontWeight: "bold", color: PRIMARYCOLOR, pb: 2 }}
					>
						Interacciones Internas
					</Typography>
					{dataPerfil.perfil_interaccion
						.filter((item) => item.tipo == "Interna")
						.map((interaccion) => {
							return (
								<Box key={interaccion.con_quien}>
									<Typography sx={{ fontSize: 18, fontWeight: "bold", color: "#555" }}>
										{" "}
										{interaccion.con_quien}
									</Typography>
									<Typography sx={{ fontSize: 16, color: "#555" }}>
										{" "}
										{interaccion.para_que}
									</Typography>
								</Box>
							)
						})}
				</Grid>
				<Grid
					item
					md={6}
					sx={{ p: 5, pt: 1 }}
				>
					<Typography
						sx={{ fontSize: 20, fontWeight: "bold", color: PRIMARYCOLOR, pb: 2 }}
					>
						Interacciones Externas
					</Typography>
					{dataPerfil.perfil_interaccion
						.filter((item) => item.tipo == "Externa")
						.map((interaccion) => {
							return (
								<Box key={interaccion.con_quien}>
									<Typography sx={{ fontSize: 18, fontWeight: "bold", color: "#555" }}>
										{" "}
										{interaccion.con_quien}
									</Typography>
									<Typography sx={{ fontSize: 16, color: "#555" }}>
										{" "}
										{interaccion.para_que}
									</Typography>
								</Box>
							)
						})}
				</Grid>
				<ListTools
					listItem={dataPerfil.herramientas}
					IconList={HandymanIcon}
					listName={"Herramientas de Trabajo"}
				/>
				<Grid xs={12}></Grid>
			</Grid>

			<ModalPerfilPuestoPDF
				open={openModalPDF}
				setOpen={setOpenModalPDF}
				data={dataPerfil}
			/>

			<ModalNewPerfil
				open={openModal}
				setOpen={setOpenModal}
				perfiles={[]}
				dataPerfil={dataPerfil}
			/>
			<ModalColaboradores
				open={openModalColab}
				setOpen={setOpenModalColab}
				perfilID={dataPerfil.id}
			/>
		</>
	)
}

export default Detalle

const handleDownloadFile = (id, fileName) => {
	const success = (url) => {
		const link = document.createElement("a")
		link.href = url // La URL del archivo
		document.body.appendChild(link)
		link.click()
		document.body.removeChild(link)
	}
	perfilPuestoGet(id, { success, params: { file: fileName } })
}
const ButtonsPerfil = ({
	dataPerfil,
	setOpenModal,
	setOpenModalColab,
	setOpenModalPDF,
}) => {
	const { id, habilitado } = dataPerfil
	const isAuthorized = habilitado
	const buttons = useMemo(
		() => [
			{
				title: "Descargar Entrevista RH",
				onClick: () => handleDownloadFile(id, "entrevista_rh"),
				icon: SimCardDownloadIcon,
				show: dataPerfil.url_entrevista_rh && habilitado,
			},
			{
				title: "Descargar Entrevista Depto",
				onClick: () => handleDownloadFile(id, "entrevista_depto"),
				icon: SimCardDownloadIcon,
				show: dataPerfil.url_entrevista_depto && habilitado,
			},

			{
				title: "Colaboradores",
				onClick: () => setOpenModalColab(true),
				icon: People,
				show: isAuthorized,
				color: "success",
			},
			{
				title: "Editar",
				onClick: () => setOpenModal(true),
				icon: Edit,
				show: true && habilitado,
			},
			{
				title: "Generar Formato PDF",
				onClick: () => setOpenModalPDF(true),
				icon: LocalPrintshop,
				show: true && habilitado,
			},
		],
		[
			dataPerfil.url_entrevista_depto,
			dataPerfil.url_entrevista_rh,
			habilitado,
			id,
			isAuthorized,
			setOpenModal,
			setOpenModalColab,
			setOpenModalPDF,
		],
	)
	const buttonsItems = useMemo(() => {
		return buttons.map((item) => {
			if (item.show)
				return (
					<Tooltip
						key={item.title}
						{...item}
						TransitionComponent={Zoom}
					>
						<IconButton
							color={item.color || "primary"}
							size="large"
						>
							<item.icon fontSize="inherit" />
						</IconButton>
					</Tooltip>
				)
			else return null
		})
	}, [buttons])
	return (
		<Box>
			<Stack
				direction={"row"}
				spacing={2}
				justifyContent={"flex-end"}
			>
				{buttonsItems}
				<ButtonDisable
					perfilID={id}
					habilitado={habilitado}
				/>
			</Stack>
		</Box>
	)
}
const ListCompet = ({ listItem, IconList, listName, size = 4 }) => {
	if (listItem == null) return <></>
	return (
		<Grid
			item
			sx={{ fontWeight: "bold", fontSize: 15, p: 5, pt: 0 }}
			md={size}
		>
			<Typography sx={{ fontSize: 18, fontWeight: "bold", color: PRIMARYCOLOR }}>
				{" "}
				{listName}{" "}
			</Typography>
			<List dense={false}>
				{listItem
					.filter((item) => item.tipo === listName)
					.map((competencia) => {
						return (
							<ListItem
								key={competencia.id}
								sx={{ p: 0.5 }}
							>
								<ListItemIcon sx={{ minWidth: 35 }}>
									{" "}
									<IconList sx={{ color: "green" }} />{" "}
								</ListItemIcon>
								<ListItemText primary={competencia.descripcion} />
							</ListItem>
						)
					})}
			</List>
		</Grid>
	)
}
export const ListTools = ({ listItem, IconList = HandymanIcon, listName, size = 4 }) => {
	if (listItem == null) return <></>
	return (
		<Grid
			item
			sx={{ fontWeight: "bold", fontSize: 15, p: 5, pt: 0 }}
			md={size}
		>
			<Typography sx={{ fontSize: 18, fontWeight: "bold", color: PRIMARYCOLOR }}>
				{" "}
				{listName}{" "}
			</Typography>
			<List dense={false}>
				{listItem.map((competencia) => {
					return (
						<ListItem
							key={competencia.id}
							sx={{ p: 0.5 }}
						>
							<ListItemIcon sx={{ minWidth: 35 }}>
								{" "}
								<IconList sx={{ color: "green" }} />{" "}
							</ListItemIcon>
							<ListItemText
								primary={`${competencia.description} [ ${competencia.area} ]`}
							/>
						</ListItem>
					)
				})}
			</List>
		</Grid>
	)
}
