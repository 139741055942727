import { MasterDialog } from "#common/Dialog/Dialog"
import { useCallback, useMemo, useState } from "react"
import { FormularioNewVacante, validation } from "./FormularioVacante/FormularioVacante"
import { vacantesPost } from "#api/Api_Vacantes"
import { useVacanteContext } from "./VacantesContext"

export default function DialogNewVacante({ open, setOpen }) {
	const { startReload } = useVacanteContext()
	const [send, setSend] = useState({})
	const [disabled, setDisabled] = useState(false)
	const handleSend = useCallback(() => {
		if (!validation(send)) {
			return
		}
		const success = (response) => {
			setOpen(false)
			setSend({})
			startReload()
			// reload((old) => !old)
		}
		setDisabled(true)
		vacantesPost(
			{ ...send.vacante, ...send.vacante_details },
			{ success, end: () => setDisabled(false) },
		)
	}, [send, setOpen, startReload])

	const buttons = useMemo(
		() => [{ label: "Aceptar", onClick: handleSend, disabled }],
		[disabled, handleSend],
	)

	return (
		<MasterDialog {...{ open, setOpen, maxWidth: "xl", buttons }}>
			<FormularioNewVacante
				data={send}
				setData={setSend}
			/>
		</MasterDialog>
	)
}
