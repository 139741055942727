import TablaDetalle from "#common/tablaDetalle/TablaDetalle"
import DetalleVacante from "#vacantes/pages/DetalleVacante"
import TableVacantes from "#vacantes/pages/TableVacantes"
import Box from "@mui/material/Box"
import Tab from "@mui/material/Tab"
import Tabs from "@mui/material/Tabs"
import Typography from "@mui/material/Typography"
import { ChangeTitle } from "Components/menu/funciones"
import { VacanteProvider } from "Components/Vacantes/VacantesContext"
import PropTypes from "prop-types"
import { useState } from "react"
import { useNavigate } from "react-router-dom"

const Vacantes = () => {
	ChangeTitle("Vacantes")
	let navigate = useNavigate()
	const [activeTab, setActiveTab] = useState(0)
	const [reload, setReload] = useState(true)
	const [vacantesActivas, setVacantesActivas] = useState(true)
	const handleChange = (event, newValue) => {
		setVacantesActivas(!vacantesActivas)
		setActiveTab(newValue)
		navigate(`/vacantes`)
	}

	const handleClick = (vacante) => {
		const id = vacante?.id
		if (!id) return
		navigate(`/vacantes/${id}`)
	}

	return (
		<Box
			display="flex"
			width="100%"
			flexDirection="column" // Asegura que crezca verticalmente con su contenido
		>
			<VacanteProvider {...{ reload, setReload }}>
				<TablaDetalle
					tabla={
						<Box>
							<Tabs
								value={activeTab}
								onChange={handleChange}
							>
								<Tab
									iconPosition="start"
									label={"Activas"}
									{...a11yProps(0)}
								/>
								<Tab
									iconPosition="start"
									label={"Cerradas"}
									{...a11yProps(1)}
								/>
							</Tabs>

							<TableVacantes
								showDay={vacantesActivas}
								onClick={handleClick}
								activas={vacantesActivas}
							/>
						</Box>
					}
					Detalle={DetalleVacante}
					ruta="/vacantes"
					nameParam="vacanteId"
					title="Vacantes"
				/>
			</VacanteProvider>
		</Box>
	)
}

function TabPanel(props) {
	const { children, value, index, ...other } = props
	return (
		<div
			role="tabpanel"
			hidden={value !== index}
			id={`simple-tabpanel-${index}`}
			aria-labelledby={`simple-tab-${index}`}
			{...other}
		>
			{value === index && (
				<Box sx={{ p: 3 }}>
					<Typography>{children}</Typography>
				</Box>
			)}
		</div>
	)
}

TabPanel.propTypes = {
	children: PropTypes.node,
	index: PropTypes.number.isRequired,
	value: PropTypes.number.isRequired,
}

function a11yProps(index) {
	return {
		id: `simple-tab-${index}`,
		"aria-controls": `simple-tabpanel-${index}`,
	}
}
export default Vacantes
