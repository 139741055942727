import { initAxiosRH, loadAbort } from "#api/axios.service"
const PERFILES_PUESTO="job_profile"
const axios=initAxiosRH()

export const perfilesGet = (params) => {
    const controller= loadAbort()
    const call=axios.get(PERFILES_PUESTO,{signal:controller.signal,params})
    return {call,controller}
}

export const perfilesPost = (data,params) => {
    const controller= loadAbort()
    const call=axios.post(PERFILES_PUESTO,data,{signal:controller.signal,params})
    return {call,controller}
}

export const perfilesIdGet = (id,params) => {
    const controller= loadAbort()
    const call=axios.get(`${PERFILES_PUESTO}/${id}`,{signal:controller.signal,params})
    return {call,controller}
}

export const perfilesIdPut = (id,data,params) => {
    const controller= loadAbort()
    const call=axios.put(`${PERFILES_PUESTO}/${id}`,data,{signal:controller.signal,params})
    return {call,controller}
}
export const perfilesIdDelete = (id,params) => {
    const controller= loadAbort()
    const call=axios.delete(`${PERFILES_PUESTO}/${id}`,{signal:controller.signal,params})
    return {call,controller}
}