import { LoadViewTable } from "#common/LoadingView"
import { RED_COLOR } from "#constants/Styles"
import { useApi } from "#hooks/useApi"
import ModalNewPerfil from "#perfilesPuesto/ModalNewPerfil"
import { useReduxPerfilesPuesto } from "#perfilesPuesto/redux/slice"
import { perfilesGet } from "#perfilesPuesto/Services/api"
import { Add } from "@mui/icons-material/"
import { IconButton } from "@mui/material/"
import MUIDataTable from "mui-datatables"
import { useMemo, useState } from "react"

const Table = ({ onClick }) => {
	const { reloadTable } = useReduxPerfilesPuesto()

	const params = useMemo(() => ({ list: true, all: true }), [])
	const { data, loading, error } = useApi(perfilesGet, {
		autoFetch: true,
		params,
		reload: reloadTable,
	})
	const perfiles = useMemo(() => data?.data ?? [], [data])
	const [openModal, setOpenModal] = useState(false)
	const customBodyRender = (rowData, { rowIndex }) => {
		const { habilitado = false } = perfiles[rowIndex] ?? {}
		let color = habilitado ? "" : RED_COLOR
		return <span style={{ color: color }}>{rowData}</span>
	}
	const columns = [
		{
			name: "nombre",
			label: "Nombre",
			options: {
				filter: false,
				sort: true,
				customBodyRender,
			},
		},
		{
			name: "area",
			label: "Departamento",
			options: {
				filter: true,
				sort: true,
				customBodyRender,
			},
		},
		{
			name: "create_at",
			label: "Fecha de creación",
			options: {
				filter: true,
				sort: true,
				customBodyRender,
			},
		},
	]
	const options = {
		selectableRowsHideCheckboxes: true,
		print: false,
		viewColumns: false,
		onRowClick: (rowData, { dataIndex }) => {
			if (onClick) onClick(data.data[dataIndex])
		},
		customToolbar: () => {
			return (
				<IconButton
					style={{ order: -1 }}
					onClick={() => setOpenModal(true)}
				>
					<Add />
				</IconButton>
			)
		},
	}
	if (loading || error) return <LoadViewTable />
	return (
		<div>
			<MUIDataTable
				title={""}
				data={perfiles}
				columns={columns}
				options={options}
				sx={{ with: 400 }}
			/>
			<ModalNewPerfil
				open={openModal}
				setOpen={setOpenModal}
			/>
		</div>
	)
}

export default Table
