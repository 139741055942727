// React
import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"

//
import { getPerfiles } from "#api/Api"
import { reloadDataVacante } from "app/pageSlice"

//componentes
import Competencias from "./formulario/Competencias"
import Interacciones from "./formulario/Interacciones"
import { VacanteHerramientas } from "./formulario/VacanteHerramientas"

// Material
import InputSelectAreas from "#common/input/InputSelectAreas"
import { InputAutocomplete } from "#common/Inputs2"
import CircularSkeleton from "#common/skeleton/CircularSkeleton"
import { useApiSend } from "#hooks/useApiSend"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import Accordion from "@mui/material/Accordion"
import AccordionDetails from "@mui/material/AccordionDetails"
import AccordionSummary from "@mui/material/AccordionSummary"
import Box from "@mui/material/Box"
import Button from "@mui/material/Button"
import Dialog from "@mui/material/Dialog"
import DialogActions from "@mui/material/DialogActions"
import DialogContent from "@mui/material/DialogContent"
import DialogTitle from "@mui/material/DialogTitle"
import Grid from "@mui/material/Grid"
import TextField from "@mui/material/TextField"
import EntrevistasCompetencias from "./formulario/EntrevistasCompetencias"
import { Responsabilidades } from "./formulario/Responsabilidades"
import { usePerfilesPuestoReloadAll } from "./redux/slice"
import { perfilesIdPut, perfilesPost } from "./Services/api"

const ModalNewPerfil = ({ open, setOpen, dataPerfil }) => {
	const token = useSelector((state) => state.user.token)
	const reload = usePerfilesPuestoReloadAll()
	const dispatch = useDispatch()
	const [fnCancelar, setFnCancelar] = useState(() => {})
	const success = () => {
		setOpen(false)
		reload()
		dispatch(reloadDataVacante())
	}
	const apiFinally = () => setBtnDisabled(false)

	const { fetch: fetchPut } = useApiSend(perfilesIdPut, {
		finally: apiFinally,
		success,
	})
	const { fetch: fetchPost } = useApiSend(perfilesPost, {
		finally: apiFinally,
		success,
	})
	const actionApi =
		dataPerfil == null ? fetchPost : (data) => fetchPut(dataPerfil.id, data)

	const [dataSend, setDataSend] = useState({
		nombre: dataPerfil == null ? "" : dataPerfil.nombre,
		area: dataPerfil?.area?.id || null,
		centro_costos: dataPerfil?.area?.id || null,

		reporta_a: dataPerfil?.reporta_id || null,
		objetivo: dataPerfil == null ? "" : dataPerfil.objetivo,
	})
	const [dataSendFiles, setDataSendFiles] = useState()
	const [expanded, setExpanded] = useState("panelDescription") // Accordion
	const [loadingPuesto, setLoadingPuesto] = useState(false)
	const [perfiles, setPerfiles] = useState([])
	const [btnDisabled, setBtnDisabled] = useState(false)

	useEffect(() => {
		if (!open) return
		setLoadingPuesto(true)
		Promise.all([getPerfiles(token)])
			.then(([perfiles]) => {
				setPerfiles(perfiles.map((perf) => ({ value: perf.id, label: perf.nombre })))
			})
			.finally(() => setLoadingPuesto(false))
	}, [open, token, dataPerfil])

	const handleChangePanel = (panel) => (event, isExpanded) => {
		setExpanded(isExpanded ? panel : false)
	}
	const save = () => {
		setBtnDisabled(true)
		const formData = new FormData()
		for (const file in dataSendFiles) {
			formData.append(file, dataSendFiles[file])
		}

		for (const campo in dataSend) {
			if (typeof dataSend[campo] === "object")
				formData.append(campo, JSON.stringify(dataSend[campo]))
			else formData.append(campo, dataSend[campo])
		}
		const fn = actionApi(formData)
		setFnCancelar(() => fn)
	}
	const title = dataPerfil ? "Editar Perfil" : "Nuevo Perfil De Puesto"
	if (loadingPuesto)
		return (
			<Dialog
				open={open}
				maxWidth={"md"}
				fullWidth={true}
				onClose={() => {
					setOpen(false)
				}}
			>
				<DialogTitle>{title}</DialogTitle>
				<DialogContent>
					<CircularSkeleton />
				</DialogContent>
			</Dialog>
		)
	return (
		<Dialog
			open={open}
			maxWidth={"md"}
			fullWidth={true}
			onClose={() => {
				setOpen(false)
			}}
		>
			<DialogTitle>{title}</DialogTitle>
			<DialogContent>
				{/* Descripción de puesto */}
				<AccordionPerfil
					{...{
						label: "Descripción de puesto",
						name: "panelDescription",
						expanded,
						handleChangePanel,
					}}
				>
					<Grid
						container
						spacing={2}
					>
						<Grid
							item
							xs={12}
							sx={{ mt: 0, mb: 0 }}
						>
							<Box>
								<TextField
									autoFocus
									id="nombre"
									label="Nombre del Puesto"
									fullWidth
									required
									value={dataSend.nombre}
									onChange={(event) =>
										setDataSend((oldData) => ({
											...oldData,
											nombre: event.target.value,
										}))
									}
									sx={{ mt: 0 }}
								/>
							</Box>
						</Grid>
						<Grid
							item
							xs={6}
						>
							<InputSelectAreas
								value={dataSend.area}
								setValue={(selected) => {
									setDataSend((oldData) => ({
										...oldData,
										area: selected,
										centro_costos: selected,
									}))
								}}
							/>
						</Grid>
						<Grid
							item
							xs={6}
						>
							<InputAutocomplete
								label="Reporta a *"
								options={perfiles}
								optionValue={"value"}
								setOption={(selected) =>
									setDataSend((oldData) => ({
										...oldData,
										reporta_a: selected,
									}))
								}
								value={dataSend.reporta_a}
							/>
						</Grid>
						<Grid
							item
							xs={12}
						>
							<TextField
								id="outlined-multiline-static"
								label="Objetivo del puesto"
								multiline
								fullWidth
								required
								value={dataSend.objetivo}
								rows={4}
								sx={{ zIndex: 0 }}
								onChange={(event) =>
									setDataSend((oldData) => ({
										...oldData,
										objetivo: event.target.value,
									}))
								}
							/>
						</Grid>
					</Grid>
				</AccordionPerfil>

				{/* Competencias */}
				<AccordionPerfil
					{...{
						label: "Competencias",
						name: "panelCompetencias",
						expanded,
						handleChangePanel,
					}}
				>
					<Competencias
						dataPerfil={dataPerfil}
						setDataSend={setDataSend}
					/>
				</AccordionPerfil>
				{/* Responsabilidades */}
				<AccordionPerfil
					{...{
						label: "Responsabilidades",
						name: "panelResponsabilidades",
						expanded,
						handleChangePanel,
					}}
				>
					<Responsabilidades
						dataPerfil={dataPerfil}
						setDataSend={setDataSend}
					/>
				</AccordionPerfil>
				{/* Interacciones con otros */}
				<AccordionPerfil
					label={"Interacciones"}
					name={"panelInteracciones"}
					expanded={expanded}
					handleChangePanel={handleChangePanel}
				>
					<Interacciones
						title={"Interacciones Internas"}
						id={"Interna"}
						dataPerfil={dataPerfil}
						name={"internas"}
						setDataSend={setDataSend}
					/>
					<Interacciones
						title={"Interacciones Externas"}
						id={"Externa"}
						name={"externas"}
						dataPerfil={dataPerfil}
						setDataSend={setDataSend}
					/>
				</AccordionPerfil>
				<AccordionPerfil
					{...{
						label: "Herramientas de Trabajo",
						name: "panelHerramientas",
						expanded,
						handleChangePanel,
					}}
				>
					<VacanteHerramientas
						dataPerfil={dataPerfil}
						setDataSend={setDataSend}
					/>
				</AccordionPerfil>
				<AccordionPerfil
					{...{
						label: "Entrevistas Por Competencias",
						name: "panelEntrevistas",
						expanded,
						handleChangePanel,
					}}
				>
					<EntrevistasCompetencias {...{ dataPerfil, setDataSendFiles, dataSendFiles }} />
				</AccordionPerfil>
			</DialogContent>
			<DialogActions>
				<Button
					onClick={() => {
						if (typeof myFunc === "function") fnCancelar()
						setOpen(false)
					}}
				>
					Cancelar
				</Button>
				<Button
					onClick={() => save()}
					disabled={btnDisabled}
				>
					Guardar perfil
				</Button>
			</DialogActions>
		</Dialog>
	)
}

export default ModalNewPerfil

const AccordionPerfil = ({ label, name, expanded, handleChangePanel, children }) => {
	return (
		<Accordion
			expanded={expanded === name}
			onChange={handleChangePanel(name)}
		>
			<AccordionSummary
				expandIcon={<ExpandMoreIcon />}
				aria-controls="panel1a-content"
				id="panel1a-header"
			>
				<Box
					component="b"
					sx={{ fontSize: 20, color: "#1d3454" }}
				>
					{" "}
					{label}{" "}
				</Box>
			</AccordionSummary>
			<AccordionDetails>{children}</AccordionDetails>
		</Accordion>
	)
}
