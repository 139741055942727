import { promotionDetailPut } from "#api/Api/promotions"
import { Box, Checkbox, FormControlLabel, Stack, Typography } from "@mui/material"
import {
	FormularioNewVacante,
	validation,
} from "Components/Vacantes/FormularioVacante/FormularioVacante"
import { useCallback, useEffect, useMemo, useState } from "react"
import { usePromotionsContext } from "../../PromotionContext"

export function ResponseTrue({ setMaxWidth, setButtons }) {
	const {
		response,
		promotionDetail,
		setOpenConfirm: setOpen,
		reload,
	} = usePromotionsContext()
	const [buttonDisabled, setButtonDisabled] = useState(false)
	const colaboradores = [
		{
			id: promotionDetail.authorization,
			full_name: promotionDetail.authorization_name,
		},
		{
			id: promotionDetail.colaborador_id,
			full_name: promotionDetail.colaborador_name,
		},
	]
	const sucursales = [
		{ id: promotionDetail.sucursal_id, nombre: promotionDetail.sucursal_name },
	]
	const perfiles = [
		{
			id: promotionDetail.old_profile_id,
			nombre: promotionDetail.old_profile_name,
		},
	]
	const [sendResponse, setSendResponse] = useState({
		authorized: true,
		new_vacante: false,
	})

	const success = useMemo(
		() => (response) => {
			if (response) {
				setOpen(false)
				reload()
			}
			return false
		},
		[reload, setOpen],
	)

	const onClick = useCallback(() => {
		if (sendResponse.new_vacante && !validation(sendResponse)) {
			return
		}
		setButtonDisabled(true)
		promotionDetailPut(promotionDetail.id, sendResponse, {
			success,
			end: () => setButtonDisabled(false),
		})
	}, [promotionDetail, sendResponse, success])

	useEffect(() => {
		if (response === true)
			setButtons([
				{
					label: "Aceptar",
					onClick,
					disabled: buttonDisabled,
				},
			])
	}, [buttonDisabled, onClick, response, setButtons])

	if (!response) return null
	return (
		<Box>
			<Stack
				spacing={3}
				alignItems={"center"}
			>
				<Typography
					whiteSpace="pre-line"
					textAlign={"center"}
				>
					Al aceptar, el puesto de <strong>{promotionDetail.old_profile}</strong> quedará
					vacío.
					{/* {"\n"}¿Deseas abrir un nuevo requerimiento? */}
				</Typography>
				<FormControlLabel
					control={
						<Checkbox
							checked={sendResponse.new_vacante}
							onClick={(event) => {
								event.stopPropagation()
								setMaxWidth(event.target.checked ? "xl" : "xs")
								setSendResponse((response) => ({
									...response,
									new_vacante: event.target.checked,
								}))
							}}
						/>
					}
					label="¿Deseas abrir un nuevo requerimiento?"
				/>

				{sendResponse.new_vacante && (
					<FormularioNewVacante
						setData={setSendResponse}
						promotion
						data={promotionDetail}
						list={{ colaboradores, sucursales, perfiles }}
					/>
				)}
			</Stack>
		</Box>
	)
}
