const PERMISOS = Object.freeze({
	ADMIN: "Admin",
	RH: "RH",
	COLABORADOR: "Colaborador",
	GUATEMALA: "ColaboradorGuatemala",
	SYSTEM: "Sistemas",
	COMPRAS: "Compras",
	PAGOS: "Pagos",
	SUPER_USER: "SuperUser",
	CONTABILIDAD: "Contabilidad",
	SISTEMAS: "Sistemas",
	MANTENIMIENTO: "Mantenimiento",
	TEST: "Test",
	STOREHOUSE: "Almacen",
	ACCOUNTING: "Contabilidad",
	VENDEDOR: "Vendedor",
	MANAGEMENT: "Direccion",
	MANAGEMENT_OP: "DirectorOperaciones",
	GRTE: "Gerente",
	GRTE_GUATEMALA: "GerenteGuatemala",
	GRTE_COMERCIAL: "GerenteComercial",
	GRTE_DS: "GerenteDeptoSucursal",
	GRTE_RH: "GerenteRH",
	GRTE_SUCURSAL: "GerenteSucursal",
	JEFE_NOMINAS: "JefeNominas",
	JEFATURA: "Jefatura",

	get RH_ALL() {
		return [this.RH, this.GRTE_RH]
	},
	get GRTE_ALL() {
		return [
			this.GRTE,
			this.GRTE_COMERCIAL,
			this.GRTE_DS,
			this.GRTE_GUATEMALA,
			this.GRTE_RH,
			this.GRTE_SUCURSAL,
		]
	},
})
export default PERMISOS
