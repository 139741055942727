import { Stack, Typography } from "@mui/material"
import React from "react"
import SearchOffIcon from "@mui/icons-material/SearchOff"
import { COLOR_PRIMARY } from "#constants/Styles"

const ErrorResponse = ({ error }) => {
	let text =
		error?.response?.data?.message ?? "No se encontró el Información del Colaborador"
	return (
		<Stack>
			<Typography
				sx={{ fontSize: 24, fontWeight: "bold", color: COLOR_PRIMARY }}
				mb={2}
			>
				{text}
			</Typography>
			<SearchOffIcon sx={{ fontSize: 100, color: COLOR_PRIMARY }} />
		</Stack>
	)
}

export default ErrorResponse
