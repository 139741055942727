import { createSlice } from "@reduxjs/toolkit"
import { useCallback } from "react"
import { useDispatch, useSelector } from "react-redux"

const initialState = {
	reloadTable: true,
	reloadPerfilesPuesto: true,
	PerfilesPuesto: null,
	isRh: false,
}
const PerfilesPuestoSlice = createSlice({
	name: "perfilesPuesto",
	initialState,
	reducers: {
		PerfilesPuestoReloadAll(state) {
			state.reloadTable = !state.reloadTable
			state.reloadPerfilesPuesto = !state.reloadPerfilesPuesto
		},
		PerfilesPuestoReloadTable(state) {
			state.reloadTable = !state.reloadTable
		},
		PerfilesPuestoReload(state) {
			state.reloadPerfilesPuesto = !state.reloadPerfilesPuesto
		},
		setPerfilesPuesto(state, action) {
			state.PerfilesPuesto = action.payload
		},
		setIsRh(state, action) {
			state.isRh = action.payload
		},
	},
})

export const {
	PerfilesPuestoReload,
	PerfilesPuestoReloadAll,
	PerfilesPuestoReloadTable,
	setPerfilesPuesto,
} = PerfilesPuestoSlice.actions

export default PerfilesPuestoSlice.reducer

export const useReloadPerfilesPuesto = () => {
	const dispatch = useDispatch()
	const fn = useCallback(() => {
		dispatch(PerfilesPuestoReloadTable())
	}, [dispatch])
	return fn
}

export const usePerfilesPuestoReloadAll = () => {
	const dispatch = useDispatch()
	const fn = useCallback(() => {
		dispatch(PerfilesPuestoReloadAll())
	}, [dispatch])
	return fn
}
export const useSetPerfilesPuesto = () => {
	const dispatch = useDispatch()
	const fn = useCallback(
		(value) => {
			dispatch(setPerfilesPuesto(value))
		},
		[dispatch],
	)
	return fn
}


/**
 * Hook para obtener el estado del PerfilesPuesto desde Redux.
 *
 * @returns {{reloadTable: boolean, reloadPerfilesPuesto: boolean,PerfilesPuesto:object,isRh:boolean}}
 * @property {boolean} reloadTable - Indica si se debe recargar la tabla.
 * @property {boolean} reloadPerfilesPuesto - Indica si se debe recargar el PerfilesPuesto.
 * @property {object} PerfilesPuesto - Objeto del PerfilesPuesto.
 */
export const useReduxPerfilesPuesto = () => {
	return useSelector((state) => state.perfilesPuesto)
}
