const STATUS = {
    "TO_AUTHORIZE": 1,
    "AUTHORIZED": 2,
    "POSTING_VACANCY": 3,
    "FILTERING_CANDIDATES": 4,
    "IN_INTERVIEWS": 5,
    "FINAL_CANDIDATE": 6,
    "WITH_OFFER_LETTER": 7,
    "PENDING_ENTRY": 8,
    "COMPLETED": 9,
    "REJECTED": 10,
    "PROMOTION": 11,
}
export default STATUS