import { createContext, useContext, useMemo, useState } from "react"

const DialogBajaContext = createContext()

export function DialogBajaProvider({
	children,
	reload,
	baja,
	isRH,
	setButtonsDialog,
	closeDialog,
	disabled,
}) {
	const [reloadContabilidad, setReloadContabilidad] = useState(true)
	const [disabledContabilidad, setDisabledContabilidad] = useState(false)
	const value = useMemo(
		() => ({
			reload,
			reloadContabilidad,
			startReloadContabilidad: () => setReloadContabilidad((old) => !old),
			disabledContabilidad,
			setDisabledContabilidad,
			baja,
			isRH,
			setButtonsDialog,
			closeDialog,
			disabled,
		}),
		[
			reload,
			reloadContabilidad,
			disabledContabilidad,
			baja,
			isRH,
			setButtonsDialog,
			closeDialog,
			disabled,
		],
	)

	return <DialogBajaContext.Provider value={value}>{children}</DialogBajaContext.Provider>
}

// Hook personalizado para usar el contexto

/**
 * @typedef {Object} bajaContextParams
 * @property {} reload
 * @property {} baja
 * @property {} isRH
 * @property {} setButtonsDialog
 * @property {} closeDialog
 * @property {} disabled
 * @property {boolean}disabledContabilidad
 * @property {boolean} reloadContabilidad
 * @property {function} startReloadContabilidad
 * @property {function}setDisabledContabilidad
 *
 */

/**
 *
 * @returns {bajaContextParams}
 */
export function UseDialogBajaContext() {
	return useContext(DialogBajaContext)
}
