import { LoadViewTable } from "#common/LoadingView"
import { RED_COLOR } from "#constants/Styles"
import VerifiedIcon from "@mui/icons-material/Verified"
import WarningIcon from "@mui/icons-material/Warning"
import MUIDataTable from "mui-datatables"
// import { useReduxColaborador, UseReloadColaborador } from "../redux/slice"
import { useReduxColaborador } from "../redux/slice"
// import { colaboradoresAllGet } from "#api/colaboradores"
import { colaboradoresAllGet } from "#colaborador/Services/api"
import { useApi } from "#hooks/useApi"

const ColaboradoresTabla = ({ onClick }) => {
	const { reloadTable } = useReduxColaborador()
	const {
		data: colaboradores,
		loading,
		error,
	} = useApi(colaboradoresAllGet, {
		autoFetch: true,
		reload: reloadTable,
	})
	// const { colaboradores, loading } = UseColaborador("all", { reload: reloadTable })

	const optionCellGeneral = {
		customBodyRender: renderCustomBody,
	}
	function renderCustomBody(value, { rowIndex }) {
		const { fecha_baja } = colaboradores[rowIndex]
		let color = fecha_baja === null ? "" : RED_COLOR
		return <span style={{ color: color }}>{value}</span>
	}

	function styleName(value, colaborador) {
		const { verificado, fecha_baja: activo } = colaborador

		let color = activo ? RED_COLOR : ""
		let icon = null

		if (verificado === false)
			icon = <WarningIcon sx={{ fontSize: 18, marginRight: "5px", color: "#B3BE20" }} />
		else if (verificado)
			icon = <VerifiedIcon sx={{ fontSize: 18, marginRight: "5px", color: "#49A7A7" }} />

		return (
			<div
				style={{
					display: "flex",
					color: color,
				}}
			>
				{icon} <span>{value}</span>
			</div>
		)
	}

	const customNombre = (value, { rowIndex }) => {
		return styleName(value, colaboradores[rowIndex])
	}

	const columns = [
		{
			name: "no_empleado",
			label: "No empleado",
			options: {
				filter: true,
				sort: true,
				...optionCellGeneral,
			},
		},
		{
			name: "nombre_completo",
			label: "Nombre",
			options: {
				filter: false,
				sort: true,
				customBodyRender: customNombre,
			},
		},
		{
			name: "sucursal",
			label: "Sucursal",
			options: {
				filter: true,
				sort: true,
				...optionCellGeneral,
			},
		},
		{
			name: "centro_costos",
			label: "Departamento",
			options: {
				filter: true,
				sort: true,
				...optionCellGeneral,
			},
		},
		{
			name: "perfil_puesto",
			label: "Puesto",
			options: {
				filter: true,
				sort: true,
				...optionCellGeneral,
			},
		},

		{
			name: "fecha_baja",
			label: "Activo",
			options: {
				display: false,
				filter: true,
				sort: false,
				customBodyRender: (value, tableMeta) => {
					return value == null ? "Activo" : "Baja"
				},
			},
		},
		{
			name: "verificado",
			label: "Verificado",
			options: {
				display: false,
				filter: true,

				sort: false,
				customBodyRender: (value, tableMeta) => {
					let is_verify = "No Verificado"
					if (value) is_verify = "Verificado"
					else if (value === false) is_verify = "Por Verificar"
					return is_verify
				},
			},
		},
	]

	const onRowClick = (rowData, { dataIndex }) => {
		if (onClick && typeof onClick === "function") onClick(colaboradores[dataIndex])
	}

	const options = {
		selectableRowsHideCheckboxes: true,
		print: false,
		viewColumns: false,
		onRowClick,
	}

	if (loading || error) return <LoadViewTable />
	return (
		<MUIDataTable
			data={colaboradores}
			columns={columns}
			options={options}
		/>
	)
}

export default ColaboradoresTabla
