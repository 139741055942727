import { useMemo, createContext, useContext } from "react"

const ColaboradorEditContext = createContext({})

export function ColaboradorEditProvider({
	sendData,
	setNewValue,
	disabledEdit,
	edit,
	setEdit,
	children,
}) {
	const value = useMemo(
		() => ({ sendData, setNewValue, disabledEdit, edit, setEdit }),
		[sendData, setNewValue, disabledEdit, edit, setEdit],
	)

	return (
		<ColaboradorEditContext.Provider value={value}>
			{children}
		</ColaboradorEditContext.Provider>
	)
}

export function UseColaboradorEditContext() {
	return useContext(ColaboradorEditContext)
}
