import Tabs from "#common/tabs/Tabs"
import PERMISOS from "#constants/perfiles"
import { TERMINATION } from "#constants/StatusRequest "
import useHasPermission from "#hooks/useHasPermission"
import { Box, Stack } from "@mui/material"
import { useCallback } from "react"
import { useSelector } from "react-redux"
import ApprovalRH from "./ApprovalRH"
import ContabilidadBajas from "./ContabilidadBajas"
import DebtBajas from "./DebtBajas"
import { DialogBajaProvider } from "./DialogBajaContext"
import Documents from "./Documents"
import InfoBajasDialog, {
	InformationSolicitudBajas,
	InformationTracsaSolicitudBajas,
} from "./InfoBajasDialog"

export default function DetailTermination({
	baja,
	dialogIndex,
	setDialogIndex,
	disabled = false,
	reload = () => {},
	setButtonsDialog = () => {},
	closeDialog = () => {},
}) {
	const { hasPermissionV2 } = useHasPermission()
	const isRH = hasPermissionV2(PERMISOS.RH_ALL)
	const isJefeNominas = hasPermissionV2(PERMISOS.JEFE_NOMINAS)
	const tabsChange = useCallback(() => {
		setButtonsDialog(null)
	}, [setButtonsDialog])

	const tabsShow = !TERMINATION.All_FINISH.includes(baja?.termination_status_id)
	// (baja?.termination_status_id !== TERMINATION.FINALIZADA ||
	// 	baja?.termination_status_id !== TERMINATION.RECHAZADA) &&
	// baja?.termination_status_id !== TERMINATION.SOLICITADA

	const request_collaborator_id = useSelector((state) => state.user.colaborador.id)
	const tabs = [
		{
			title: "Solicitud",
			component: <InformationSolicitudBajas baja={baja} />,
			show: isRH || baja.request_collaborator === request_collaborator_id,
		},
		{
			title: "Tracsa",
			component: <InformationTracsaSolicitudBajas baja={baja} />,
			show: (isRH || isJefeNominas) && tabsShow,
		},
		{
			title: "Adeudos",
			component: <DebtBajas />,
			show: tabsShow,
		},
		{
			title: "Contabilidad",
			component: <ContabilidadBajas />,
			show: (isRH || isJefeNominas) && tabsShow,
		},
		{
			title: "Documentos",
			component: <Documents />,
			show: (isRH || baja.request_collaborator === request_collaborator_id) && tabsShow,
		},
	]

	return (
		<Box width={"100%"}>
			<DialogBajaProvider
				baja={baja}
				reload={reload}
				isRH={isRH}
				setButtonsDialog={setButtonsDialog}
				closeDialog={closeDialog}
				disabled={disabled}
			>
				<Stack
					container
					spacing={2}
					rowSpacing={3}
				>
					<InfoBajasDialog
						baja={baja}
						notAll={!isRH}
					/>
					<Tabs
						tabs={tabs}
						onChange={tabsChange}
						value={dialogIndex}
						setValue={setDialogIndex}
					/>
					<ApprovalRH
						baja={baja}
						hidden={!isRH}
					/>
				</Stack>
			</DialogBajaProvider>
		</Box>
	)
}
