import { createSlice } from "@reduxjs/toolkit"
import { useCallback } from "react"
import { useDispatch, useSelector } from "react-redux"

const initialState = {
	reloadTable: true,
	reloadColaborador: true,
	colaborador: null,
	isRh: false,
	tab: 0,
}
const ColaboradorSlice = createSlice({
	name: "colaboradorN",
	initialState,
	reducers: {
		ColaboradorReloadAll(state) {
			state.reloadTable = !state.reloadTable
			state.reloadColaborador = !state.reloadColaborador
		},
		ColaboradorReloadTable(state) {
			state.reloadTable = !state.reloadTable
		},
		ColaboradorReload(state) {
			state.reloadColaborador = !state.reloadColaborador
		},
		setColaborador(state, action) {
			state.colaborador = action.payload
		},
		setIsRh(state, action) {
			state.isRh = action.payload
		},
		setTab(state, action) {
			state.tab = action.payload
		},
	},
})

export const {
	ColaboradorReload,
	ColaboradorReloadAll,
	ColaboradorReloadTable,
	setColaborador,
	setTab,
} = ColaboradorSlice.actions
export default ColaboradorSlice.reducer

export const useReloadColaborador = () => {
	const dispatch = useDispatch()
	const fn = useCallback(() => {
		dispatch(ColaboradorReloadTable())
	}, [dispatch])
	return fn
}

export const useSetColaborador = () => {
	const dispatch = useDispatch()
	const fn = useCallback(
		(value) => {
			dispatch(setColaborador(value))
		},
		[dispatch],
	)
	return fn
}

export const useSetTab = () => {
	const dispatch = useDispatch()
	const fn = useCallback(
		(value) => {
			dispatch(setTab(value))
		},
		[dispatch],
	)
	return fn
}

export const useColaboradorReloadAll = () => {
	const dispatch = useDispatch()
	const fn = useCallback(() => {
		dispatch(ColaboradorReloadAll())
	}, [dispatch])
	return fn
}

/**
 * Hook para obtener el estado del colaborador desde Redux.
 *
 * @returns {{reloadTable: boolean, reloadColaborador: boolean,colaborador:object,isRh:boolean}}
 * @property {boolean} reloadTable - Indica si se debe recargar la tabla.
 * @property {boolean} reloadColaborador - Indica si se debe recargar el colaborador.
 * @property {object} colaborador - Objeto del colaborador.
 */
export const useReduxColaborador = () => {
	return useSelector((state) => state.colaboradorN)
}
