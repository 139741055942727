import axios from "axios"

let axiosInstance

/**
 * Crea una instancia de Axios con una URL base.
 *
 * @param {string} baseURL - La URL base que se utilizará para todas las solicitudes.
 * @returns {import('axios').AxiosInstance} Una instancia de Axios configurada con la URL base proporcionada.
 */
const createAxios = (baseURL) => {
	axiosInstance = axios.create({ baseURL })
}

const setupInterceptors = () => {
	axiosInstance.interceptors.request.use(
		(config) => {
			const token = localStorage.getItem("token")
			if (token) {
				config.headers.set(`Authorization: Token ${token}`)
			}
			// console.log(`Request made to: ${config.url}`)
			return config
		},
		(error) => {
			return Promise.reject(error)
		},
	)

	axiosInstance.interceptors.response.use(
		(response) => {
			// console.log(`Response from: ${response.config.url}`, {
			// 	data: response.data,
			// 	status: response.status,
			// })
			return response
		},
		(error) => {
			if (axios.isCancel(error)) {
				// console.error("se cancelo")
			} else if (error.response) {
				console.error(`Error response from: ${error.response.config.url}`)
			} else {
				// console.error(`Error: ${error.message}`)
				console.error(`Error:,`, error)
			}
			return Promise.reject(error)
		},
	)
}

/**
 * Inicializa una instancia de Axios configurada para interactuar con el módulo de Recursos Humanos (RH).
 *
 * Esta función realiza lo siguiente:
 * 1. Construye la URL base para las solicitudes al módulo de RH utilizando la variable de entorno `REACT_APP_SERVER_URL`.
 * 2. Crea una instancia de Axios con la URL base utilizando la función `createAxios`.
 * 3. Configura interceptores globales para la instancia de Axios utilizando la función `setupInterceptors`.
 * 4. Retorna la instancia de Axios configurada.
 *
 * @returns {import('axios').AxiosInstance} Una instancia de Axios configurada para hacer solicitudes al módulo de RH.
 */
export const initAxiosRH = () => {
	const URL_API = process.env.REACT_APP_SERVER_URL + "rh/"
	createAxios(URL_API)
	setupInterceptors()
	return axiosInstance
}

/**
 * Crea y retorna una nueva instancia de `AbortController`.
 * @returns {AbortController} Una nueva instancia de `AbortController`.
 */
export const loadAbort = () => {
	return new AbortController()
}
