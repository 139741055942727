import { createContext, useMemo, useState } from "react"

export const DocumentContext = createContext()

export function DocumentProvider({ children }) {
	const [documentSelect, setDocumentSelect] = useState(null)
	const [documentEdit, setDocumentEdit] = useState(null)
	const [reload, setReload] = useState(false)
	const [openDialog, setOpenDialog] = useState(false)
	const datos = useMemo(
		() => ({
			documentSelect,
			setDocumentSelect,
			reload,
			openDialog,
			setOpenDialog,
			documentEdit,
			setDocumentEdit,
			startReload: () =>
				setReload((old) => {
					return !old
				}),
		}),

		[documentSelect, reload, openDialog, documentEdit],
	)
	return <DocumentContext.Provider value={datos}>{children}</DocumentContext.Provider>
}
