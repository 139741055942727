import { apiPromotionGet } from "#api/Api_promotion"
import { useEffect, useState } from "react"

export const usePromotionData = (reload) => {
    const [promotions, setPromotions] = useState([])
    const [loading, setLoading] = useState([])
    useEffect(() => {
        const controller = new AbortController()
        setLoading(true)
        const success = (data) => {
            setPromotions(data)
            setLoading(false)
        }
        apiPromotionGet({ success, signal: controller.signal })

        return () => {
            controller.abort()
        }
    }, [reload])
    return { promotions, loading }
}

export const usePromotionId = (id, reload) => {
    const [promotion, setPromotion] = useState([])
    const [loading, setLoading] = useState([])
    useEffect(() => {
        const controller = new AbortController()
        setLoading(true)
        const success = (data) => {
            setPromotion(data[0])
            setLoading(false)
        }
        apiPromotionGet({ success, params: { id }, signal: controller.signal })

        return () => {
            controller.abort()
        }
    }, [id, reload])
    return { promotion, loading }
}