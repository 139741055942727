import { initAxiosRH, loadAbort } from "#api/axios.service"
const COLABORADORES="colaboradores"
const axios=initAxiosRH()

export const colaboradoresGet = (params) => {
    const controller= loadAbort()
    const call=axios.get(COLABORADORES,{signal:controller.signal,params})
    return {call,controller}
}
export const colaboradoresAllGet = (params) => {
    const controller= loadAbort()
    const call=axios.get(`${COLABORADORES}/all`,{signal:controller.signal,params})
    return {call,controller}
}
export const colaboradoresIdGet = (id,params) => {
    const controller= loadAbort()
    const call=axios.get(`${COLABORADORES}/${id}`,{signal:controller.signal,params})
    return {call,controller}
}