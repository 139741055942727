import Box from "@mui/material/Box"
import Checkbox from "@mui/material/Checkbox"
import FormControl from "@mui/material/FormControl"
import FormControlLabel from "@mui/material/FormControlLabel"
import FormGroup from "@mui/material/FormGroup"
import List from "@mui/material/List"
import ListItem from "@mui/material/ListItem"
import ListItemText from "@mui/material/ListItemText"
import ListSubheader from "@mui/material/ListSubheader"
import Grid from "@mui/material/Unstable_Grid2/Grid2"
import { UseGetAreas } from "Hooks/areas/getAreas"
import { useEffect, useState } from "react"
import { SkeletonSquare } from "../../Common/Skeleton"
import { TextGrid } from "../../Common/Texto"
import CursosPlanAnualSelection from "./CursosPlanAnualSelection"
import { usePlanAnualContext } from "./PlanAnualContext"

function ListAreas(props) {
	const { actualizar } = usePlanAnualContext()
	const [state, setState] = useState([])
	const [areaSelected, setAreaSelected] = useState([])
	const [count, setCount] = useState(0)
	const [areasId, setAreasId] = useState([])

	const { areas, loading } = UseGetAreas(true, {
		manager: true,
		queryParams: {
			collaborators: true,
			annual_plan: true,
		},
		reload: actualizar,
	})
	useEffect(() => {
		setState([])
		setAreaSelected([])
		setCount(0)
		setAreasId([])
	}, [actualizar])

	useEffect(() => {
		let show = {}
		let count = 0
		show = areas.filter((area) => {
			if (state[area.id]) count += parseInt(area?.collaborators?.count)
			return state[area.id]
		})
		setCount(count)
		setAreaSelected(show)
		let areaId = []
		for (let area in state) {
			if (state[area]) areaId.push(area)
		}
		setAreasId(areaId)
	}, [state, areas])

	const handleChange = (event) => {
		setState({
			...state,
			[event.target.name]: event.target.checked,
		})
	}

	if (loading) return <SkeletonSquare />

	if (!areas.length) return <TextGrid label="No Tienes departamentos a tu cargo" />

	return (
		<Box
			sx={{
				display: "flex",
				justifyContent: "center", // Centrar horizontalmente
				alignItems: "center", // Centrar verticalmente
				width: "100%", // Ajustar el ancho al contenedor padre
			}}
		>
			<Grid
				sx={{
					width: "100%",
				}}
				container
				justifyContent={"center"}
				spacing={1}
			>
				<Grid xs={3}>
					<Grid
						container
						justifyContent={"center"}
						spacing={1}
					>
						<Grid xs={12}>
							<div>
								<Grid
									container
									alignContent={"flex-start"}
									justifyContent={"flex-start"}
									alignItems={"center"}
									spacing={1}
								>
									<TextGrid
										allSize={1}
										label={`Departamentos`}
										tipo="h5"
									/>
									<Grid xs={12}>
										<FormControl
											required
											component="fieldset"
											sx={{
												border: "2px solid black",
												borderTopLeftRadius: "10px",
												borderTopRightRadius: "5px",
												borderBottomRightRadius: "5px",
												borderBottomLeftRadius: "10px",
												width: "100%",
												maxWidth: 500,
												maxHeight: 300,
												position: "relative",
												overflow: "auto",
												"& ul": { padding: 0.5 },
												"&::-webkit-scrollbar": {
													width: "10px",
												},
												"&::-webkit-scrollbar-thumb": {
													margin: 10,
													backgroundColor: "black", // Color del pulgar de la barra de desplazamiento
													borderRadius: "10%", // Borde redondeado para hacerlo circular
												},
											}}
											variant="standard"
										>
											<FormGroup>
												{areas.map((area) => {
													return (
														<DataCheck
															disabled={area?.annual_plan?.exists}
															key={area.id}
															label={area.description}
															idArea={area.id}
														/>
													)
												})}
											</FormGroup>
										</FormControl>
									</Grid>
								</Grid>
							</div>
						</Grid>

						{areaSelected.length ? (
							<Grid
								xs={12}
								//xl={3}
							>
								<div>
									<Grid
										container
										alignContent={"flex-start"}
										justifyContent={"flex-start"}
										alignItems={"center"}
										spacing={1}
									>
										<TextGrid
											allSize={12}
											label={`Colaboradores: ${count}`}
											tipo="h5"
										/>
										<Grid xs={12}>
											<ListPersonally data={areaSelected} />
										</Grid>
									</Grid>
								</div>
							</Grid>
						) : null}
					</Grid>
				</Grid>
				{areaSelected.length ? (
					<Grid xs={9}>
						<CursosPlanAnualSelection areas={areasId} />
					</Grid>
				) : null}
			</Grid>
		</Box>
	)

	function DataCheck({ label = "a", idArea = "1", disabled = false }) {
		return (
			<FormControlLabel
				control={
					<Checkbox
						disabled={disabled}
						checked={state[idArea]}
						onChange={handleChange}
						name={`${idArea}`}
					/>
				}
				label={label}
			/>
		)
	}
}

export default ListAreas
export function ListPersonally({ data }) {
	return (
		<List
			sx={{
				border: "2px solid black",
				borderTopLeftRadius: "10px",
				borderTopRightRadius: "5px",
				borderBottomRightRadius: "5px",
				borderBottomLeftRadius: "10px",
				width: "100%",
				maxWidth: 500,
				maxHeight: 300,
				position: "relative",
				overflow: "auto",
				"& ul": { padding: 0.5 },
				"&::-webkit-scrollbar": {
					width: "10px",
				},
				"&::-webkit-scrollbar-thumb": {
					margin: 10,
					backgroundColor: "black", // Color del pulgar de la barra de desplazamiento
					borderRadius: "10%", // Borde redondeado para hacerlo circular
				},
			}}
			subheader={<li />}
		>
			{data.map((area) => {
				const { collaborators = {} } = area
				return (
					<li key={`section-${area.id}`}>
						<ul>
							<ListSubheader>{`${area.description} | Colaboradores : ${collaborators?.count}`}</ListSubheader>
							{collaborators.data.map((item) => (
								<ListItem key={`item-${area?.id}-${item["id"]}`}>
									<ListItemText primary={`${item["name"]}`} />
								</ListItem>
							))}
						</ul>
					</li>
				)
			})}
		</List>
	)
}
