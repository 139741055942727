import { useEffect, useMemo, useState } from "react"
import { toast } from "react-toastify"
// common
import ButtonDownload from "#common/buttons/ButtonDownload"
import ButtonFile from "#common/buttons/ButtonFile"
import InputNumber from "#common/input/InputNumber"
import CircularSkeleton from "#common/skeleton/CircularSkeleton"
// Material
import FileDownloadIcon from "@mui/icons-material/FileDownload"
import FileUploadSharpIcon from "@mui/icons-material/FileUploadSharp"
import Box from "@mui/material/Box"
import Button from "@mui/material/Button"
import InputAdornment from "@mui/material/InputAdornment"
import Stack from "@mui/material/Stack"
import Typography from "@mui/material/Typography"
import Grid2 from "@mui/material/Unstable_Grid2/Grid2"

// Componentes
import { bajaPut, bajasDeptPut } from "#api/Api/baja"
import { HasPermission } from "#common/Funciones"
import { UseBajasDebts } from "../../UseBajas"
import { UseDialogBajaContext } from "./DialogBajaContext"
// import { bajasDeptPut } from "#api/Api/baja"

export default function DebtBajas({ hidden = false }) {
	const { baja } = UseDialogBajaContext()
	const [reload, setReload] = useState(false)
	const { debts, loading } = UseBajasDebts(baja.id, hidden, reload)

	if (loading) return <CircularSkeleton />
	return (
		<Box
			sx={{
				display: "flex",
				flexDirection: "column",
				justifyContent: "center",
				alignItems: "center",
			}}
		>
			<NotTools
				herramientas={debts}
				id={baja.id}
				setReload={setReload}
			/>
			<ViewHerramientas herramientas={debts} />
		</Box>
	)
}

const NotTools = ({ herramientas, id, setReload }) => {
	const [disabled, setDisabled] = useState(false)
	const send = () => {
		setDisabled(true)
		const fail = () => {
			setDisabled(false)
		}
		const success = () => {
			setReload((reload) => !reload)
		}
		bajaPut(id, { id }, { params: { create_debts: true }, success, fail })
	}
	if (herramientas.length !== 0) return null

	if (HasPermission("RH"))
		return (
			<Box>
				<Typography textAlign="center">No hay adeudos para este solicitud</Typography>
				<Button
					variant="contained"
					sx={{ marginTop: 2 }}
					onClick={send}
					disabled={disabled}
				>
					Crear Adeudos
				</Button>
			</Box>
		)
	else
		return <Typography textAlign="center">No hay adeudos para este solicitud</Typography>
}

const ViewHerramientas = ({ herramientas, setFiles }) => {
	const [value, setValue] = useState({})
	const [disabledSend, setDisabledSend] = useState(false)
	const { setButtonsDialog, baja, closeDialog } = UseDialogBajaContext()

	const notButtonSend = useMemo(() => {
		const count = herramientas.filter((d) => {
			const valor = parseFloat(d.debt)
			return isNaN(valor)
		})
		return count.length === 0
	}, [herramientas])

	const dialogButton = useMemo(() => {
		return [
			{
				label: "Guardar",
				onClick: () => {
					sendData(baja.id, value, setDisabledSend, closeDialog)
				},
				disabled: disabledSend,
			},
		]
	}, [baja.id, closeDialog, disabledSend, value])

	useEffect(() => {
		if (notButtonSend) setButtonsDialog(null)
		else setButtonsDialog(dialogButton)
	}, [dialogButton, notButtonSend, setButtonsDialog])

	const view = useMemo(
		() =>
			herramientas.map((herramienta) => (
				<DebtComponent
					key={herramienta?.id}
					herramienta={herramienta}
					setValue={setValue}
					value={value}
				/>
			)),
		[herramientas, value],
	)
	return (
		<Stack
			width={"100%"}
			spacing={1}
			alignItems={"center"}
		>
			{view}
		</Stack>
	)
}

const DebtComponent = ({ herramienta, setValue, value }) => {
	const fieldValue = `cantidad_${herramienta.id}`
	const valor = useMemo(() => {
		return value[fieldValue]
	}, [fieldValue, value])
	const fileValue = value[`file_${herramienta.id}`]
	useEffect(() => {
		setValue((old) => ({
			...old,
			[`name_${herramienta.id}`]: herramienta.description,
			[fieldValue]: herramienta.debt ?? "",
			[`file_${herramienta.id}`]: herramienta.url_s3 ?? null,
		}))
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [herramienta])

	const adornment = valor !== ""
	return (
		<Grid2
			container
			spacing={2}
			width={"100%"}
			alignItems={"center"}
		>
			<Grid2 xs={6}>
				<InputNumber
					label={herramienta.description}
					disabled={herramienta?.debt === 0 || herramienta?.debt}
					fullWidth
					startAdornment={
						adornment ? <InputAdornment position="start">$</InputAdornment> : null
					}
					value={value[fieldValue]}
					setValue={(value) => {
						setValue((old) => ({
							...old,
							[fieldValue]: value,
						}))
					}}
				/>
			</Grid2>
			<Grid2 xs={2}>
				<ButtonFile
					accept=".zip, .rar, .7zip"
					label={<FileUploadSharpIcon />}
					getFiles={(value) => {
						setValue((old) => ({
							...old,
							[`file_${herramienta.id}`]: value[0],
						}))
					}}
					hidden={herramienta?.url_s3 || herramienta?.debt || herramienta?.debt === 0}
				/>
				<ButtonDownload
					label="Descargar"
					startIcon={<FileDownloadIcon />}
					hidden={!herramienta?.url_s3}
					url={herramienta?.url_s3}
				/>
			</Grid2>
			<Grid2 xs={4}>
				<Typography
					variant="body2"
					color="text.secondary"
				>
					{fileValue?.name}
				</Typography>
			</Grid2>
		</Grid2>
	)
}

const sendData = async (idBaja, value, setDisabledSend, closeDialog) => {
	let send = true
	const formData = new FormData()
	for (const key in value) {
		if (key.includes("name")) {
			const id = key.split("_")[1]
			if (!validateData(value, id)) send = false
			else addData(formData, value, id)
		}
	}

	if (!send) return

	const success = () => {
		closeDialog()
	}

	const fail = () => {
		setDisabledSend(false)
	}

	setDisabledSend(true)
	bajasDeptPut(idBaja, formData, { success, fail })
}

function addData(formData, value, id) {
	const fileKey = `file_${id}`
	const cantidadKey = `cantidad_${id}`
	formData.append(cantidadKey, value[cantidadKey])
	if (value.hasOwnProperty(fileKey) && value[fileKey] !== "") {
		formData.append(fileKey, value[fileKey])
	}
}

function validateData(value, id) {
	const file = value[`file_${id}`]
	const cantidad = parseFloat(value[`cantidad_${id}`])
	const name = value[`name_${id}`]
	if (isNaN(cantidad)) {
		toast.error(`Debe colocar una cantidad ${name}`, {
			toastId: name + "cantidad",
		})
		return false
	} else if (!file && cantidad !== 0) {
		toast.error(`Debe subir un archivo para ${name}`, {
			toastId: name + "file",
		})
		return false
	}
	return true
}
