import { LoadViewTable } from "#common/LoadingView"
import { Box } from "@mui/material"
import MUIDataTable from "mui-datatables"

export default function PromotionTable({ promotions, loading, onClick }) {
	let tableColumns = [
		{
			label: "# Id",
			name: "id",
			display: true,
			options: {
				filter: false,
			},
		},
		{
			label: "Status",
			name: "status",
			display: true,
			options: {
				filter: false,
			},
		},
		{ label: "Solicitante", name: "requestor" },
		{ label: "Fecha Solicitud", name: "request_date" },
	]

	const onRowClick = (rowData, { dataIndex }) => {
		const index = dataIndex
		const promotion = promotions[index]
		if (promotion?.id) onClick(promotion?.id)
	}
	const options = {
		selectableRowsHideCheckboxes: true,
		print: false,
		viewColumns: false,
		textLabels: { body: { noMatch: "No hay Promociones" } },
		onRowClick,
	}

	if (loading) return <LoadViewTable />

	return (
		<Box>
			<MUIDataTable
				data={promotions}
				columns={tableColumns}
				options={options}
			/>
		</Box>
	)
}
