import { formatMoney } from "#common/Funciones"
import { TextCampoValor } from "#common/text/CampoValor"
import { Box, Divider } from "@mui/material"
import Grid2 from "@mui/material/Unstable_Grid2"
import { UseDetalleColaboradorContext } from "Components/Colaboradores/Solicitudes/SolicitudesContext"

const CompanyDetails = () => {
	const { colaborador } = UseDetalleColaboradorContext()

	const showFields = [
		{ field: "Area", value: "area" },
		{ field: "Jefe Directo", value: "jefe_directo" },
		{ field: "Fecha Alta", value: "fecha_alta" },
		{ divider: "Contacto" },
		{ field: "Correo Empresa", value: "email" },
		{ field: "Teléfono Empresa", value: "telefono_lubtrac" },
		{ field: "Extension", value: "extension_lubtrac" },
	]

	const dataComponent = showFields.map((item) => {
		if (item?.divider)
			return (
				<Grid2
					xs={12}
					key={item.divider + "divider"}
				>
					<Divider textAlign="left">{item.divider}</Divider>
				</Grid2>
			)
		if (!colaborador[item.value]) return null
		return (
			<Grid2
				xs={12}
				md={3}
				key={item.value}
			>
				<TextCampoValor
					field={item.field}
					value={colaborador[item.value]}
					color="text.secondary"
					variant="h1"
				/>
			</Grid2>
		)
	})

	const fondo_fijo = colaborador.fondo_fijo ? (
		<Grid2
			xs={12}
			md={3}
			key={"fondo_fijo"}
		>
			<TextCampoValor
				field={"Fondo Fijo"}
				value={formatMoney(colaborador.fondo_fijo, colaborador.currency_symbol)}
				color="text.secondary"
				variant="h5"
			/>
		</Grid2>
	) : null
	dataComponent.splice(2, 0, fondo_fijo)

	return (
		<Box sx={{ backgroundColor: "#fff", padding: 3, boxShadow: 1 }}>
			<Grid2
				container
				spacing={3}
			>
				{dataComponent}
			</Grid2>
		</Box>
	)
}

export default CompanyDetails
