import PERMISOS from "#constants/perfiles";
import { useSelector } from "react-redux";


const flattenArray = (...MyArray) => {
   const arrayStr=[]
    MyArray.forEach(isString => {
     if (Array.isArray(isString)) {
         arrayStr.push(...flattenArray(...isString));
     }
     else arrayStr.push(isString);
   });
   return arrayStr
 };

const  hasPermissionFn=(perfiles)=>(...permisos)=> {
   const permisosFlat=flattenArray(permisos)
   let strict = false
   if (typeof permisosFlat[permisosFlat.length - 1] === "boolean") {
	   strict = permisosFlat.pop()
   }
   if (!strict) permisosFlat.push(PERMISOS.ADMIN)
   return perfiles.some((perfil) => permisosFlat.includes(perfil))
}
/**
 * Determina el permiso de mayor prioridad que el usuario posee.
 * 
 * @param {string[]} perfiles - Lista de permisos del usuario.
 * @returns {function(...string): (string|false)} - Retorna el primer permiso válido según la prioridad o `false` si no tiene ninguno.
 */
const getTopPermissionFn=(perfiles)=>(prioridad)=> {
   
   prioridad.unshift(PERMISOS.ADMIN)
   const hasPermissionV2=hasPermissionFn(perfiles)

   for (const index in prioridad) {
      let nombre = prioridad[index]
      if (hasPermissionV2( nombre)) {
         return nombre
      }
   }
   return null
}


export default function useHasPermission() {
	const perfiles = useSelector((state) => state.user.profiles)
	const hasPermissionV2=hasPermissionFn(perfiles)
   const getTopPermission=getTopPermissionFn(perfiles)
	return{hasPermissionV2,getTopPermission} 
}
