import jsPDF from 'jspdf';
import Formato from '../Components/PerfilesPuesto/Formato';
import emprint from '../assets/emprint.ttf'
import ReactDOMServer from "react-dom/server";
import FormatoPerfilPuesto from '../Components/PerfilesPuesto/FormatoPerfilPuesto';
// import InventarioRH_PDF from '../Components/Capacitaciones/InventarioRH_PDF';
import InventarioPDF from '../Components/Colaboradores/Detail/InventarioPDF';
import { LOGO } from 'Images/imgRutas';

export const exportPDF = ({ dataPerfil}) => {
    let element = (<Formato dataPerfil={dataPerfil} interacciones={dataPerfil.perfil_interaccion} />);
    const doc = new jsPDF();
    doc.addImage(LOGO, 'png', 12, 12, 35, 11)
    doc.html(ReactDOMServer.renderToString(element), {
        callback: function (doc) {
            doc.addFileToVFS("emprint.ttf", emprint);
            doc.addFont("emprint.ttf", "emprint", "normal");
            doc.setFont("emprint");
            doc.save('perfil_puesto.pdf');
        }
    });
};
export const exportPerfilPuestoPdf = ( dataPerfil) => {
// Crea una instancia de tu componente con la información deseada
const element = <FormatoPerfilPuesto {...dataPerfil} />;

// const element = <DetallePuesto dataPerfil={dataPerfil}/>;

// Renderiza el componente y obtén su HTML
const componentHtml = ReactDOMServer.renderToString(element);

// Crea un nuevo documento jsPDF
const doc = new jsPDF('A4', 'mm', [1200, 1600]);

// Agrega el HTML renderizado al documento
doc.html(componentHtml, {
    x: 5, y: 5,
    callback: function (doc) {
        doc.addFileToVFS("emprint.ttf", emprint);
        //doc.addFont("emprint.ttf", "emprint", "normal");
        doc.setFont("emprint");
        doc.addImage(LOGO, 'png', 17, 17, 35 + 200, 11 + 70)
        doc.save('perfil_puesto.pdf');
    }
});
};

export const exportColaboradorPDF = (elemento ) => {
// Crea una instancia de tu componente con la información deseada



const element = <InventarioPDF {...elemento}/>;

// Renderiza el componente y obtén su HTML
const componentHtml = ReactDOMServer.renderToString(element);

// Crea un nuevo documento jsPDF
const doc = new jsPDF(
{
    orientation: 'p',
    unit: 'px',
    format: 'a4',
    // putOnlyUsedFonts:true,
    floatPrecision:16 // or "smart", default is 16
   },{ hotfixes: ["px_scaling"] }
   );

doc.html(componentHtml, {
    margin:[15,10,10,10],
    autoPaging:'slice',
    // x: 5,
    // y: 5,
    html2canvas: {
        scale: .355,
    },
    callback: function (doc) {

        doc.save('Inventario_RH.pdf');
    }
});
};
