import { FormControl, Typography } from "@mui/material"
import React, { useEffect } from "react"
import { useForm, FormProvider } from "react-hook-form"
import { useSelector } from "react-redux"
import { toast } from "react-toastify"

// import { InputDateFormGrid } from "#common/Forms"
import Button from "@mui/material/Button"
import Divider from "@mui/material/Divider"
import { styled } from "@mui/material/styles"
import Grid2 from "@mui/material/Unstable_Grid2/Grid2"
import { useDispatch } from "react-redux"
import { startActualizacion } from "../../../app/actualizar"
import { OPTIONS_ESTADO_CIVIL, OPTIONS_SEXO } from "../../../assets/OptionsSelects"
import { apiPutColaboradorGenerales } from "../../../Services/ApiColaborador"
import { InputFormGrid2, InputSelectFormGrid } from "../../Common/Inputs2"
import { EstadosRegionSelectForm } from "./FormDataIAddress"
import IsGuatemala from "./IsGuatemala"
import { InputDateFormGrid } from "#common/formulario/InputsDate"

export default function FormDataPersonal({ dataColaborador, click, setClick }) {
	const { have } = IsGuatemala()
	const token = useSelector((state) => state.user.token)
	const [licencia, setLicencia] = React.useState(false)
	const [file, setFile] = React.useState(false)
	const dispatch = useDispatch()
	const formMethods = useForm()
	const {
		control,
		register,
		handleSubmit,
		formState: { errors },
		reset,
	} = formMethods
	const DATA = [
		{
			id: "curp",
			label: have ? "DPI O CUI" : "CURP",
			registerParams: { required: true },
			propsG: {},
			onChange: (event) => {
				alertaCampo(event, "Curp", 18)
			},
		},
		{
			id: "rfc",
			label: have ? "NIT" : "RFC",
			registerParams: { required: true },
			propsG: {},
			onChange: (event) => {
				alertaCampo(event, "RFC", 13)
			},
		},
		{
			id: "clave_elector",
			label: have ? "Código Único De Identificación (Cui)" : "Clave Elector",
			registerParams: { required: true },
			propsG: {},
		},
	]
	if (!have)
		DATA.push({
			id: "seccion",
			label: "Sección INE/IFE",
			registerParams: { required: false },
			propsG: {},
			type: "number",
			onChange: (event) => {
				alertaCampo(event, "Sección", 10)
			},
		})
	DATA.push(
		{
			id: "correo_personal",
			label: "Correo Personal ",
			registerParams: { required: true },
			propsG: {},
			onChange: (event) => {},
		},
		{
			id: "telefono",
			label: "Teléfono",
			registerParams: { required: true },
			propsG: {},
			type: "number",
		},
	)

	DATA.push({
		id: "no_licencia",
		label: "N° Licencia",
		registerParams: { required: false },
		propsG: {},
		onChange: (event) => {
			if (event.target.value.length >= 1) setLicencia(true)
			else setLicencia(false)
		},
	})

	useEffect(() => {
		if (click) {
			handleSubmit(onSubmit)()
			setClick(false)
		}
	}, [click, handleSubmit, setClick])

	useEffect(() => {
		let dataReset = { id: dataColaborador["id"] }
		setLicencia(!!dataColaborador["no_licencia"])
		for (let campo in DATA) {
			dataReset[DATA[campo].id] = dataColaborador[DATA[campo].id]
		}
		for (let campo in DATA_SELECT)
			dataReset[DATA_SELECT[campo].id] = dataColaborador[DATA_SELECT[campo].id]
		for (let campo in DATA_DATE) {
			dataReset[DATA_DATE[campo].id] = dataColaborador[DATA_DATE[campo].id]
		}
		let dividir = dataColaborador["lugar_nacimiento"]?.split(", ")
		if (dividir?.length === 2) {
			dataReset["poblacion"] = dividir[1]
			dataReset["estado"] = dividir[0]
		}
		setFile({})
		reset(dataReset)
	}, [reset, dataColaborador])

	const onSubmit = async (data) => {
		setClick(true)
		//se controla la data de fecha

		let dateEdit
		dateEdit = dataFormSelect(data["fecha_nacimiento"])
		if (!dateEdit) dateEdit = dateFormat(data["fecha_nacimiento"])
		data["fecha_nacimiento"] = dateEdit

		if (!!data["vencimiento"]) dateEdit = dataFormSelect(data["vencimiento"])
		else dateEdit = false
		if (!dateEdit) dateEdit = dateFormat(data["vencimiento"])
		data["vencimiento"] = dateEdit

		if (data["no_licencia"] === null || data["no_licencia"] === "")
			data["vencimiento"] = ""

		data["lugar_nacimiento"] = data["estado"] + ", " + data["poblacion"]
		let datos = new FormData()

		for (let d in data) {
			datos.append(d, data[d] === null ? "" : data[d])
		}
		if (file) datos.append("licencia", file)

		let response = await apiPutColaboradorGenerales(token, datos, {
			datosGenerales: "",
		})
		if (response["success"]) dispatch(startActualizacion())
		setClick(false)
	}
	const Root = styled("div")(({ theme }) => ({
		width: "100%",
		...theme.typography.body2,
		"& > :not(style) ~ :not(style)": {
			marginTop: theme.spacing(2),
		},
	}))

	return (
		<FormControl>
			<FormProvider {...formMethods}>
				<Grid2
					container
					spacing={2}
					columns={12}
					// alignItems={}
				>
					{DATA_SELECT.map((datos, index) => (
						<InputSelectFormGrid
							key={index}
							{...datos}
							dataID="value"
							control={control}
							error={errors}
							propsG={{ xs: 6, md: 3.5 }}
						/>
					))}
					<InputDateFormGrid
						id="fecha_nacimiento"
						label="Fecha Nacimiento"
						control={control}
						error={errors}
						grid={{ xs: 12, md: 5 }}
					/>

					<Root>
						<Divider>Lugar nacimiento</Divider>
					</Root>
					<EstadosRegionSelectForm
						control={control}
						error={errors}
						estado={dataColaborador["lugar_nacimiento"]?.split(", ")[0]}
					/>
					{DATA.map((dato, index) => {
						return (
							<InputFormGrid2
								key={index}
								{...dato}
								propsG={{ xs: 12 }}
								register={register}
								error={errors}
							/>
						)
					})}
					{!!licencia && (
						<Grid2 xs={12}>
							<div>
								<Grid2
									container
									spacing={2}
									alignItems={"center"}
								>
									<InputDateFormGrid
										id="vencimiento"
										label="Vencimiento"
										control={control}
										error={errors}
										grid={{ xs: 12, md: 5 }}
									/>
									<Grid2>
										<Button
											onChange={(e) => policyUpload(e, setFile)}
											component="label"
											variant="contained"
										>
											Subir PDF licencia de conducir
											<VisuallyHiddenInput
												type="file"
												accept=".pdf"
											/>
										</Button>
										<Typography>{file["name"]}</Typography>
									</Grid2>
								</Grid2>
							</div>
						</Grid2>
					)}
				</Grid2>
			</FormProvider>
		</FormControl>
	)
}

const DATA_SELECT = [
	{
		id: "sexo",
		label: "Sexo",
		data: OPTIONS_SEXO,
	},
	{
		id: "estado_civil",
		label: "Estado Civil",
		data: OPTIONS_ESTADO_CIVIL,
	},
]
const DATA_DATE = {
	nacimiento: { id: "fecha_nacimiento", labeL: "fecha_nacimiento" },
	vencimiento: {
		id: "vencimiento",
		label: "Vencimiento",
		registerParams: { required: true },
		propsG: {},
	},
}

const policyUpload = (event, setFile) => {
	const file = event.target.files[0]
	setFile(file)
}

const VisuallyHiddenInput = styled("input")({
	clip: "rect(0 0 0 0)",
	clipPath: "inset(50%)",
	height: 1,
	overflow: "hidden",
	position: "absolute",
	bottom: 0,
	left: 0,
	whiteSpace: "nowrap",
	width: 1,
})
function dateFormat(date) {
	let fecha = ""
	if (!!date) {
		let componentesFecha = date.split("/")
		fecha = componentesFecha[2] + "-" + componentesFecha[1] + "-" + componentesFecha[0]
	}
	return fecha
}
function dataFormSelect(date) {
	if (!!date["$d"]) {
		let dia = date["$D"]
		let mes = date["$M"] + 1
		let year = date["$y"]
		return `${year}-${mes}-${dia}`
	}
	return false
}
function alertaCampo(event, nombre, size) {
	let campo = event.target.value
	if (campo.length > size) {
		toast.error(`${nombre} no puede ser mayor a ${size}`)
		event.target.value = campo.slice(0, -1)
	}
}
